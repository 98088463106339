import { Box } from '@chakra-ui/react'

import Tabs from './Tabs'

interface PageWrapperProps {
  tabs: {
    label: string
    path: string
  }[]
  children: React.ReactNode
}

export const PageWrapper = ({ tabs = [], children }: PageWrapperProps) => {
  return (
    <Box className="m-4 h-[calc(100vh_-_148px)]">
      <Tabs list={tabs} />
      <Box className="rounded-b-md border bg-white">{children}</Box>
    </Box>
  )
}
