import { useTranslation } from 'react-i18next'

import { BasicModal } from './BaseModal'

export interface DeleteModalProps {
  type: string
  name: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const DeleteModal = ({
  name,
  type,
  isOpen,
  onClose,
  onConfirm,
}: DeleteModalProps) => {
  const { t } = useTranslation()

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('DeleteThe', { what: type })}
      onConfirm={onConfirm}
    >
      <p>{t('DeleteConfirm', { type, name })}</p>
    </BasicModal>
  )
}
