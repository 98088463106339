import React, { forwardRef, useImperativeHandle } from 'react'
import Select, {
  ClearIndicatorProps,
  components,
  ControlProps,
  createFilter,
  GroupBase,
  InputProps,
  MenuListProps,
  OptionProps,
  Props,
} from 'react-select'
import { useVirtualizer } from '@tanstack/react-virtual'

interface BaseSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Props<Option, IsMulti, Group> {
  placeholder?: string
  menuAlign?: 'left' | 'right'
  controlTextColor?: string
  controlBg?: string
  noOptionsText?: string
  isFormSelect?: boolean
}

interface WithForwardRefType<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends React.FC<BaseSelectProps<Option, IsMulti, Group>> {
  <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: BaseSelectProps<Option, IsMulti, Group>,
  ): ReturnType<React.FC<BaseSelectProps<Option, IsMulti, Group>>>
}

export const BaseSelect: WithForwardRefType<Option> = forwardRef<
  InputSelectRefImperativeHandleProps,
  BaseSelectProps<Option>
>(
  (
    {
      options,
      menuAlign = 'left',
      placeholder,
      controlTextColor,
      controlBg,
      onChange,
      noOptionsText,
      isFormSelect,
      ...rest
    },
    ref,
  ) => {
    const Control = (props: ControlProps<Option, false, GroupBase<Option>>) => {
      return (
        <components.Control
          {...props}
          data-testid="input-container"
          className="border-none outline-none"
        />
      )
    }
    const menuContainerAlign =
      menuAlign === 'right' ? { right: 0 } : { left: 0 }

    const Input = (props: InputProps<Option, false, GroupBase<Option>>) => {
      return <components.Input {...props} data-testid="input-value" />
    }
    const ClearIndicator = (
      props: ClearIndicatorProps<Option, false, GroupBase<Option>>,
    ) => {
      return (
        <div data-testid="clear-value">
          <components.ClearIndicator {...props} />
        </div>
      )
    }

    const Option = (props: OptionProps<Option, false, GroupBase<Option>>) => {
      const { onMouseMove, ...rest } = props.innerProps
      const newProps = { ...props, innerProps: rest }
      const data = props.data as Option
      return (
        <components.Option {...newProps}>
          <div className="flex items-center justify-between whitespace-nowrap ">
            {props.children}
            {data.isDisabled && <p>已棄用</p>}
          </div>
        </components.Option>
      )
    }

    return (
      <>
        <Select
          classNamePrefix="custom-select"
          filterOption={createFilter({ ignoreAccents: false })}
          data-testid={'select'}
          placeholder={placeholder}
          noOptionsMessage={() =>
            noOptionsText ? noOptionsText : 'No more options'
          }
          styles={{
            menu: (baseStyles) => ({
              ...baseStyles,
              ...menuContainerAlign,
              'height': 'auto',
              'maxHeight': '60vh',
              'overflowY': 'auto',
              'minWidth': '10rem',
              '& .custom-select__option--is-selected': {
                backgroundColor: '#4050b5',
                color: 'white',
              },
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: controlBg || '#4050b5',
              borderRadius: 4,
              border: 'none',
              paddingLeft: 5,
              paddingRight: 5,
              color: controlTextColor || 'white',
              boxShadow: 'none',
            }),
            singleValue: (baseStyles, state) => ({
              ...baseStyles,
              color: controlTextColor || 'white',
            }),
            dropdownIndicator: (baseStyles, state) => ({
              ...baseStyles,
              'color': controlTextColor || 'white',
              '&:hover': {
                color: 'white',
              },
            }),
            valueContainer: (baseStyles, state) => ({
              ...baseStyles,
              padding: isFormSelect ? 0 : baseStyles.padding,
            }),
          }}
          components={{
            Control,
            Input,
            ClearIndicator,
            Option,
            MenuList: (props) => <CustomMenuList {...props} ref={ref} />,
            IndicatorSeparator: () => null,
          }}
          options={options}
          onChange={onChange}
          {...rest}
        />
      </>
    )
  },
)

export const CustomMenuList = forwardRef<
  InputSelectRefImperativeHandleProps,
  MenuListProps<Option, false, GroupBase<Option>>
>(({ options, children, maxHeight, getValue }, ref) => {
  const [value] = getValue()
  const childrenOptions = React.Children.toArray(children)
  const parentRef = React.useRef<HTMLDivElement>(null)
  const count = childrenOptions.length
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
  })
  const items = virtualizer.getVirtualItems()

  const scrollTop = () => {
    virtualizer.scrollToIndex(0)
  }
  const scrollMiddle = () => {
    virtualizer.scrollToIndex(count / 2)
  }
  const scrollBottom = () => {
    virtualizer.scrollToIndex(count - 1)
  }
  useImperativeHandle(ref, () => {
    return {
      scrollTop,
      scrollMiddle,
      scrollBottom,
    }
  })
  return (
    <div className="react-virtualized-list-wrapper">
      <div
        ref={parentRef}
        className="List"
        style={{
          minHeight: virtualizer.getTotalSize(),
          width: '100%',
          overflowY: 'auto',
          contain: 'strict',
        }}
      >
        <div
          style={{
            height: virtualizer.getTotalSize(),
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${items?.[0]?.start}px)`,
            }}
          >
            {items.map((virtualRow) => (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
                className={
                  virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'
                }
              >
                <div>
                  {Array.isArray(children) ? (
                    <li key={virtualRow.index}>{children[virtualRow.index]}</li>
                  ) : (
                    <li
                      key={virtualRow.index}
                      className="react-virtualized-menu-placeholder"
                    >
                      {children}
                    </li>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
})

CustomMenuList.displayName = 'CustomMenuList'
BaseSelect.displayName = 'BaseSelect'
