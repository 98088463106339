import { MutableRefObject, useEffect, useState } from 'react'

type DimensionInfo = {
  width: number
  height: number
}

export const useContainerDimensions = <ELEMENT extends HTMLElement>(
  ref: MutableRefObject<ELEMENT | null>,
) => {
  const [dimensions, setDimensions] = useState<DimensionInfo>({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    const getDimensions: () => DimensionInfo = () => ({
      width: ref.current?.offsetWidth ?? 0,
      height: ref.current?.offsetHeight ?? 0,
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }
    if (ref.current) {
      setDimensions(getDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  return dimensions
}
