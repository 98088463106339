import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { PATHS } from '@/constants/path'

import { useSnackbar } from './useSnackbar'
import { useToken } from './useToken'

type RequestMethods = 'get' | 'post' | 'put' | 'delete' | 'patch'

export const useAPI = () => {
  const navigate = useNavigate()
  const tokenStore = useToken()
  const snackBar = useSnackbar()
  const { t } = useTranslation()
  const client = axios.create({
    baseURL: tokenStore.baseurl,
    headers: {
      'Authorization': tokenStore.token,
      'ngrok-skip-browser-warning': 69420,
    },
  })
  const forceLogout = () => {
    tokenStore.clear()
    navigate(PATHS.login)
    snackBar.error({
      title: t('Login_error'),
    })
  }
  const responseError = async (error: AxiosError): Promise<AxiosError> => {
    const isStatus401 = error?.response?.status === 401
    const isStatus500 = error?.response?.status === 500
    if (isStatus500) {
      snackBar.error({
        title: '系統異常，請稍後再試！',
      })
    }
    if (isStatus401) {
      forceLogout()
    }
    return Promise.reject(error)
  }
  client.interceptors.response.use((response) => response, responseError)
  client.interceptors.request.use(
    (config) => config,
    (error) => error,
  )

  // maybe we can specify RequestData/ RequestParams
  interface RequestConfig<RequestData, RequestParams> {
    data?: RequestData
    params?: RequestParams
  }
  type AllRequestMethods = 'post' | 'put' | 'patch'
  const request = <
    RequestResponse = unknown,
    RequestData = object,
    RequestParams = object,
  >(
    method: RequestMethods,
    url: string,
    { data, params }: RequestConfig<RequestData, RequestParams> = {},
  ) => {
    const allowMethods: RequestMethods[] = ['post', 'put', 'patch']
    const isRequestMethod = (
      method: RequestMethods,
    ): method is AllRequestMethods => {
      return allowMethods.includes(method)
    }

    if (isRequestMethod(method)) {
      return client[method]<
        RequestResponse,
        AxiosResponse<RequestResponse>,
        RequestData
      >(url, data, { params })
    } else {
      return client[method]<
        RequestResponse,
        AxiosResponse<RequestResponse>,
        RequestData
      >(url, { data, params })
    }
  }

  return {
    /** @description use this when 'request' can't fit */
    client,
    request,
  }
}
