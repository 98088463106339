import dayjs from 'dayjs'

import i18n from '@/locale/i18n'

export const formatDateWithTime = (date: string, formate?: string) => {
  return dayjs(date).format(formate ? formate : 'YYYY/MM/DD HH:mm')
}
export const formatDate = (date: string, formate?: string) => {
  return dayjs(date).format(formate ? formate : 'YYYY/MM/DD')
}

export const transformTime = (time: number) => {
  const hr = Math.floor(time / 3600)
  const min = Math.floor((time % 3600) / 60)
  const second = Math.floor(time % 60)
  let timeString = ''
  if (hr > 0) {
    timeString += hr + i18n.t('Hour')
  }
  if (min > 0) {
    timeString += min + i18n.t('Minute')
  }
  if (second > 0) {
    timeString += second + i18n.t('Second')
  }
  return timeString
}

export const SECOND_IN_MS = 1000
export const MINUTE_IN_MS = 60000
export const HOUR_IN_MS = 3600000

export const secondsToMilliseconds = (second: number) => second * SECOND_IN_MS
export const minutesToMilliseconds = (min: number) => min * MINUTE_IN_MS
export const hoursToMilliseconds = (hour: number) => hour * HOUR_IN_MS
