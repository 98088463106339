import { useMemo } from 'react'
import {
  GetUserRequestParams,
  GetUserResponse,
  PostUserCreateRequestBody,
  PostUserCreateResponse,
} from '@brilltek42/template-types'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
} from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { UserLoginFormDataType } from '@/components/organisms'

import { API } from '@/utils/API'

import { useAPI } from './useAPI'
import { useNavigation } from './useNavigation'
import { useToken } from './useToken'

export const useAuth = ({
  fallbackTo,
  redirectTo,
}: {
  /** @description  Redirect to the route if authenticated. */
  fallbackTo?: string
  /** @description  Fallback to the route if **NOT** authenticated. */
  redirectTo?: string
} = {}) => {
  const { request } = useAPI()

  const navigation = useNavigation()
  const tokenStore = useToken()
  /**
   * @description API `POST` request _(with credentials)_ to obtain the **JWT token**.
   */
  const getToken: MutationFunction<
    any,
    Omit<UserLoginFormDataType, 'system'>
  > = async (credentials) => {
    const res = await request('post', API.routes.user.login, {
      data: credentials,
    })
    return res.data
  }

  const loginMutate = useMutation<
    any,
    AxiosError,
    Omit<UserLoginFormDataType, 'system'>
  >({
    mutationFn: getToken,
    // useErrorBoundary: (error) => error.response?.status !== 400,
    // onError: (error) => {
    //   throw error.response?.data?.errors
    // },
  })
  const login = async (credentials: Omit<UserLoginFormDataType, 'system'>) => {
    const tokenRes = await loginMutate.mutateAsync(credentials)

    if (loginMutate.isError) {
      if (axios.isAxiosError(loginMutate.error)) {
        throw loginMutate.error.response?.data.errors
      } else {
        throw loginMutate.error
      }
    } else {
      // tokenStore.updateToken(tokenRes.token)
      // tokenStore.updateUserId(tokenRes.id)

      return tokenRes.token
    }
  }

  const logout = () => {
    tokenStore.clear()
  }

  const postUserCreate: MutationFunction<
    PostUserCreateResponse,
    PostUserCreateRequestBody
  > = async (newUserData) => {
    const { data } = await request<PostUserCreateResponse>(
      'post',
      API.routes.user.register,
      {
        data: newUserData,
      },
    )
    return data
  }

  const register = useMutation({ mutationFn: postUserCreate })

  const isAuthenticated = useMemo(
    () => Boolean(tokenStore.token),
    [tokenStore.token],
  )

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     if (redirectTo) {
  //       navigation.goTo(redirectTo)
  //     }
  //   } else {
  //     if (fallbackTo) {
  //       navigation.goTo(fallbackTo)
  //     }
  //   }
  // }, [fallbackTo, redirectTo, isAuthenticated, navigation])

  const getUserData: QueryFunction<GetUserResponse> = async () => {
    const { data } = await request<
      GetUserResponse,
      never,
      GetUserRequestParams
    >('get', API.routes.user.data(tokenStore.userId), {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      params: { id: tokenStore.userId } as GetUserRequestParams,
    })

    return data
  }
  return {
    login,
    logout,
    register,
    isAuthenticated,
  }
}
