import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import { Box, Button, Icon } from '@chakra-ui/react'

import {
  useAutoStatus,
  useNavigation,
  useProduction,
  useSelectProduct,
  useSnackbar,
} from '@/hooks'

import { deepEqual } from '@/helpers/object'
import { ProductFormSchema } from '@/helpers/validate/Product'

import { useProductionDataColumns } from '@/utils/tableColumn'

import { PATHS } from '@/constants/path'

import { SearchInput } from '../atoms/interactive/SearchInput'
import { BaseTable } from '../organisms'
import { AutoStatusSettingModal } from '../templates'
import { DeleteModal } from '../templates/modal/DeleteModal'
import { ProductUpdateModal } from '../templates/modal/ProductUpdateModal'

export const ProductionRoutePage = () => {
  const navigation = useNavigation()
  const { t } = useTranslation()
  const snackBar = useSnackbar()
  const { setSelectedProduct: setSelectedProductStore } = useSelectProduct()
  const [isOpen, setIsOpen] = useState<ProductPageModalType>('')
  const [selectedProduct, setSelectedProduct] = useState<ProductionData>()
  const [filter, setFilter] = useState('')

  const productionResponse = useProduction({ attachImage: true })
  const autoStatus = useAutoStatus({ productId: selectedProduct?.id! })

  const checkColumn: (keyof ProductionData)[] = ['code', 'name']
  const displayData = useMemo(
    () =>
      productionResponse.query.data.filter((item) => {
        return checkColumn.some((k) =>
          item[k]
            .toString()
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()),
        )
      }),
    [productionResponse.query.data, filter],
  )

  const closeModal = () => {
    setIsOpen('')
  }
  const handleAction = (type: ProductPageModalType, data?: ProductionData) => {
    if (data) {
      setSelectedProduct(data)
    }
    setIsOpen(type)
  }
  const handleDeleteProduct = async () => {
    if (!selectedProduct) return
    await productionResponse.delete.mutateAsync({
      productId: selectedProduct?.id,
    })
    closeModal()
  }
  const ProductionDataColumns = useProductionDataColumns({
    handleAction,
  })

  const updateModalTitle = useMemo(
    () =>
      isOpen === 'add'
        ? t('New', { what: t('Product') })
        : t('Edit', { what: t('Product') }),
    [isOpen],
  )
  const handleSubmit = async (formData: ProductFormSchema) => {
    if (isOpen === 'add') {
      await productionResponse.create.mutateAsync({ data: formData })
    } else {
      if (!selectedProduct) return
      await productionResponse.update.mutateAsync({
        data: formData,
        productId: selectedProduct?.id,
      })
    }
  }

  const handleAutoStatusSubmit = async (
    data: AutoStatusSettingModalDataProps,
  ) => {
    try {
      const { newList = [], oldList = [] } = data
      const createList = !!newList.length
        ? newList.filter(
            (item) => !oldList.some((oldItem) => deepEqual(item, oldItem)),
          )
        : []
      const deleteList = !!oldList.length
        ? oldList.filter(
            (item) => !newList.some((newItem) => deepEqual(item, newItem)),
          )
        : []

      if (!createList.length && !deleteList.length) {
        closeModal()
        return
      }

      await Promise.all([
        autoStatus.create.mutateAsync(createList),
        deleteList.map(async (item) => {
          await autoStatus.delete.mutateAsync(item.startProductStationId)
        }),
      ])

      setTimeout(() => {
        snackBar.success({
          title: t('Succeed', { do: t('AutoStatusSetting') }),
        })
        autoStatus.query.refetch()
      }, 500)
      closeModal()
    } catch (err) {
      console.log(err)
    }
  }

  const onRowClick = (row) => {
    if (document.getSelection()?.type === 'Range') return

    navigation.goTo(
      PATHS.productDetail.replace(':productID', row.original.code),
    )
    setSelectedProduct(row.original)
    setSelectedProductStore(row.original)
  }

  return (
    <Box className="m-4 h-[calc(100vh_-_90px)] rounded-[0.375rem] border bg-white p-4">
      <div className="mb-5 flex  flex-wrap items-stretch justify-start gap-[0.5rem] md:justify-end md:gap-[2rem]">
        <SearchInput
          value={filter}
          onChange={(value) => setFilter(value)}
          placeholder={`${t('input.SearchThe', {
            name:
              t('Product_', { _: t('Name') }) +
              '/' +
              t('Product_', { _: t('Number') }),
            interpolation: {
              escapeValue: false,
            },
          })}`}
          className="min-w-[17.25rem] border-none outline-none placeholder:text-gray-500"
        />
        <Button
          colorScheme="blue"
          h="38px"
          leftIcon={<Icon as={MdAdd} fontSize={20} />}
          onClick={() => handleAction('add')}
        >
          {t('New', { what: '' })}
        </Button>
      </div>
      <Box p="4">
        {!!displayData.length && (
          <BaseTable
            onRowClick={onRowClick}
            columns={ProductionDataColumns}
            data={displayData}
            columnClass="align-top"
            tableLayout="auto"
          />
        )}
      </Box>
      {selectedProduct && (
        <DeleteModal
          isOpen={isOpen === 'delete'}
          onClose={closeModal}
          onConfirm={handleDeleteProduct}
          name={selectedProduct?.code}
          type={t('Product')}
        />
      )}
      <ProductUpdateModal
        isOpen={['add', 'edit'].includes(isOpen)}
        onClose={closeModal}
        selectedProduct={selectedProduct}
        formId={isOpen + 'product'}
        type={isOpen}
        title={updateModalTitle}
        onConfirm={handleSubmit}
      />
      <AutoStatusSettingModal
        isOpen={isOpen === 'autoStatus'}
        onClose={closeModal}
        selectedProduct={selectedProduct}
        formId="autoStatus"
        title={t('AutoStatusSetting')}
        onConfirm={handleAutoStatusSubmit}
      />
    </Box>
  )
}
