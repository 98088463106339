import { createTrackedSelector } from 'react-tracked'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export interface DashboardState {
  originColumns: Record<string, TaskStatusType>
  sortColumn: (
    sourceType: string,
    sourceColumn: TaskStatusType,
    sourceItems: TaskType[],
  ) => void
  changeColumn: (
    sourceType: string,
    destinationType: string,
    sourceColumn: TaskStatusType,
    sourceItems: TaskType[],
    destColumn: TaskStatusType,
    destItems: TaskType[],
  ) => void
  addColumn: (status: string) => void
  addFilter: ({ name, value }: { name: string; value: string }) => void
  addColumnData: (status: string, data: TaskType[]) => void
  updateAssignColumnCheckTypeData: (
    key: 'checkInType' | 'checkOutType',
    value: CheckInType | CheckOutType | (string & {}),
    id: number,
    code: string,
  ) => void
  reset: () => void
}

export const taskStatus: Record<string, TaskStatusType> = {
  unAssign: {
    name: 'unAssign',
    items: [],
    filter: '',
  },
  assign: {
    name: 'assign',
    items: [],
    filter: '',
  },
  workStation: {
    name: 'workStation',
    items: [],
    filter: '',
  },
}

const DashboardStore = create<DashboardState>()(
  devtools(
    persist(
      (set, get) => ({
        originColumns: taskStatus,
        sortColumn: (sourceType, sourceColumn, sourceItems) => {
          set((pro) => ({
            originColumns: {
              ...pro.originColumns,
              [sourceType]: {
                ...sourceColumn,
                items: sourceItems,
              },
            },
          }))
        },
        changeColumn: (
          sourceType,
          destinationType,
          sourceColumn,
          sourceItems,
          destColumn,
          destItems,
        ) => {
          set((props) => ({
            originColumns: {
              ...props.originColumns,
              [sourceType]: {
                ...props.originColumns[sourceType],
                ...sourceColumn,
                items: sourceItems,
              },
              [destinationType]: {
                ...props.originColumns[destinationType],
                ...destColumn,
                items: destItems,
              },
            },
          }))
        },
        addFilter: ({ name, value }) => {
          set((props) => {
            return {
              originColumns: {
                ...props.originColumns,
                [name]: {
                  ...props.originColumns[name],
                  filter: value,
                },
              },
            }
          })
        },
        addColumn: (status) => {
          set((props) => {
            const defaultColumn = {
              [status]: {
                name: status,
                items: [],
                filter: '',
              },
            } as typeof props.originColumns
            return {
              originColumns: {
                ...props.originColumns,
                ...defaultColumn,
              },
            }
          })
        },
        addColumnData: (status, data) => {
          set((props) => {
            return {
              originColumns: {
                ...props.originColumns,
                [status]: {
                  ...props.originColumns[status],
                  items: data,
                },
              },
            }
          })
        },
        updateAssignColumnCheckTypeData: (key, value, id, code) => {
          set(({ originColumns }) => ({
            originColumns: {
              ...originColumns,
              assign: {
                ...originColumns.assign,
                items: originColumns.assign.items.map((item) => {
                  const data = item.data as ProductStationResponse
                  if (data.id !== id || data.code !== code) return item
                  return {
                    ...item,
                    data: {
                      ...data,
                      [key]: value,
                    },
                  }
                }),
              },
            },
          }))
        },
        reset: () => {
          set({
            originColumns: taskStatus,
          })
        },
      }),
      {
        name: 'Dashboard-storage',
      },
    ),
    { name: 'DashboardStore store', serialize: { options: true } },
  ),
)

/**
 * @description React custom hook which uses Zustand to manage the Left Drawer component.
 */
export const useDraggableDashboard = createTrackedSelector(DashboardStore)
