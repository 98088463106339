import { FC, useEffect, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { useDraggableDashboard } from '@/hooks'

import { unproxify } from '@/utils/unProxy'

import { AssignCard } from './CardItem'

export const DraggableAssignedColumn: FC<{
  containHeight: number
}> = ({ containHeight }) => {
  const { originColumns } = useDraggableDashboard()
  // unproxify
  const [columns, setColumns] = useState<Record<string, TaskStatusType>>(
    unproxify(originColumns),
  )

  const { t } = useTranslation()

  const column = columns['assign']

  useEffect(() => {
    setColumns(unproxify(originColumns))
  }, [originColumns])

  return (
    <div
      style={{
        height: containHeight,
      }}
      className={`rounded-[0.375rem] bg-white  shadow-md`}
      key={'assign'}
    >
      <Droppable droppableId={'assign'} key={'assign'}>
        {(provided) => {
          const isEmpty = column.items.length === 0
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`
              relative h-full  overflow-scroll rounded-[0.375rem] bg-[#F7FAFC] p-[0.875rem]`}
            >
              <div
                className={`sticky top-0 z-[9999] mb-[1rem] border-b border-gray-300 bg-inherit pb-[1rem]`}
                style={{
                  boxShadow: `0 -20px 0 0px '#F7FAFC`,
                }}
              >
                <div className="flex flex-wrap items-center justify-between gap-[1rem]">
                  <p className="text-[1.125rem] font-[600]">
                    {t('_Station', {
                      _: t('Current'),
                    })}
                  </p>
                </div>
              </div>
              <div
                className={`flex h-full flex-col gap-[1rem] overflow-y-scroll rounded-[0.25rem]`}
              >
                {isEmpty ? (
                  <p className="text-center text-gray-400">
                    {t('NoData', { type: t('Station') })}
                  </p>
                ) : (
                  <>
                    {column.items.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <AssignCard
                                index={index}
                                id={item.id}
                                provided={provided}
                                snapshot={snapshot}
                                data={item.data as StationSectionData}
                                columnItem={item}
                              />
                            )
                          }}
                        </Draggable>
                      )
                    })}
                  </>
                )}
                {provided.placeholder}
              </div>
            </div>
          )
        }}
      </Droppable>
    </div>
  )
}
