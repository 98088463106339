import { useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Box, Icon, Select, UseModalProps } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'

import {
  useAutoStatus,
  useAutoStatusStations,
  useProductStation,
} from '@/hooks'

import {
  ProductStationAutoStatusFormSchema,
  productStationAutoStatusFormSchema,
} from '@/helpers/validate/ProductStationAutoStatus'

import { BasicModal } from './BaseModal'

export interface AutoStatusSettingModalProps extends UseModalProps {
  title: string
  formId: string
  selectedProduct?: ProductionData
  onConfirm: (data: AutoStatusSettingModalDataProps) => void
}

const DEFAULT_FORM = {
  startProductStationId: undefined,
  endProductStationId: undefined,
  list: [],
}

const checkOverlap = (
  start: number,
  end: number,
  selectedStart: number,
  selectedEnd: number,
) => {
  return (
    (selectedStart >= start && selectedStart <= end) ||
    (selectedEnd >= start && selectedEnd <= end)
  )
}

export const AutoStatusSettingModal = ({
  isOpen,
  title,
  formId,
  selectedProduct,
  onClose,
  onConfirm,
  ...restProps
}: AutoStatusSettingModalProps) => {
  const { t, i18n } = useTranslation()
  const { control, formState, register, watch, handleSubmit, reset, setValue } =
    useForm<ProductStationAutoStatusFormSchema>({
      resolver: zodResolver(productStationAutoStatusFormSchema),
      mode: 'onChange',
      defaultValues: DEFAULT_FORM,
    })
  const { fields, append, remove } = useFieldArray({ control, name: 'list' })
  const startProductStationId = watch('startProductStationId')
  const endProductStationId = watch('endProductStationId')

  const { query: autoStatusList } = useAutoStatus({
    productId: selectedProduct?.id!,
  })
  const { queryById: stations } = useProductStation({
    selectedProduct: selectedProduct!,
  })
  const { options: endStationOptions } = useAutoStatusStations({
    productStationId: Number(startProductStationId)!,
  })

  useEffect(() => {
    if (!isOpen) return
    reset({
      startProductStationId: undefined,
      endProductStationId: undefined,
      list: autoStatusList.data ?? [],
    })
  }, [isOpen, autoStatusList.data, reset])

  const startStationOptions = useMemo(() => {
    if (!stations.data) return []
    return stations.data
      .filter((station) => ['origin', 'batch'].includes(station.checkInType))
      .map((station) => ({
        value: station.id,
        label: i18n.language === 'vi' ? station.nameVi : station.nameZhTW,
      }))
  }, [stations.data, i18n.language])

  const getStationName = (id: number) => {
    const station = stations.data?.find((station) => station.id == id)
    return i18n.language === 'vi' ? station?.nameVi : station?.nameZhTW
  }

  const getStationSequence = (stationId: number) => {
    return stations.data?.find((station) => station.id === Number(stationId))
      ?.sequence
  }

  useEffect(() => {
    if (!startProductStationId || !endProductStationId) return
    onAdd()
  }, [endProductStationId])

  const onCheck = () => {
    return new Promise<boolean>((resolve) => {
      if (!startProductStationId || !endProductStationId) {
        resolve(false)
        return
      }
      if (!fields.length) {
        resolve(true)
        return
      }
      fields.forEach((field) => {
        const selectedStartSequence = getStationSequence(
          Number(startProductStationId),
        )
        const selectedEndSequence = getStationSequence(
          Number(endProductStationId),
        )

        const startSequence = getStationSequence(field.startProductStationId)
        const endSequence = getStationSequence(field.endProductStationId)

        // Check if the new station is overlapping with the existing station
        const isOverlap = checkOverlap(
          startSequence!,
          endSequence!,
          selectedStartSequence!,
          selectedEndSequence!,
        )

        if (isOverlap) {
          alert(t('error.ProductStationOverlap'))
          resolve(false)
          return
        }
      })
      resolve(true)
    })
  }

  const onAdd = async () => {
    if (!(await onCheck())) {
      setValue('endProductStationId', undefined)
      return
    }

    append({
      startProductStationId: Number(startProductStationId),
      endProductStationId: Number(endProductStationId),
    })
    setValue('startProductStationId', undefined)
    setValue('endProductStationId', undefined)
  }

  const onSubmit = (data: ProductStationAutoStatusFormSchema) => {
    onConfirm({ oldList: autoStatusList.data, newList: data.list! })
  }

  return (
    <BasicModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      {...restProps}
      formId={formId}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit, console.log)}
        className="flex flex-col gap-3"
      >
        <p style={{ color: 'red' }}>*{t('AutoStatusWarning')}</p>
        {fields.map((field, index) => (
          <Box
            key={field.id}
            display="flex"
            justifyContent="space-between"
            gap={2}
          >
            <span style={{ flex: 1 }}>
              {getStationName(field.startProductStationId)}
            </span>
            <span style={{ flex: 1 }}>
              {getStationName(field.endProductStationId)}
            </span>
            <Icon
              as={DeleteIcon}
              fontSize={18}
              cursor="pointer"
              color="red.500"
              onClick={() => remove(index)}
            />
          </Box>
        ))}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Select
            {...register('startProductStationId')}
            placeholder={t('StartProductStation')}
            disabled={formState.isSubmitting}
          >
            {startStationOptions?.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
          <Select
            {...register('endProductStationId')}
            placeholder={t('EndProductStation')}
            disabled={formState.isSubmitting}
          >
            {endStationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <Icon
            as={AddIcon}
            fontSize={18}
            cursor="pointer"
            color="blue.500"
            onClick={onAdd}
          />
        </Box>
      </form>
    </BasicModal>
  )
}
