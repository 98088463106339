import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

const customInfo = definePartsStyle({
  container: {
    color: '#0369A1',
    background: '#BAE6FD',
  },
  title: {
    color: '#171717',
    fontWeight: '400',
    fontSize: '1rem',
  },
})

// 👇 Defining styles for the second custom variant
const customError = definePartsStyle({
  container: {
    background: '#FECACA',
    color: '#DC2626',
  },
  title: {
    color: '#171717',
    fontWeight: '400',
    fontSize: '1rem',
  },
})
const alertTheme = defineMultiStyleConfig({
  variants: { customInfo, customError },
})
const theme = extendTheme({
  colors: {
    slate: {
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a',
    },
    blue: {
      500: '#3f51b5', // main color
    },
  },
  components: {
    Alert: alertTheme,
  },
})

export default theme
