import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms'

interface ConfirmAndCancelButtonGroupProps {
  onClose: () => void
  onConfirm?: () => void
  testId?: {
    close: string
    confirm: string
  }
  formId?: string
  isSpaceBetween?: boolean
}
export const ConfirmAndCancelButtonGroup = ({
  onClose,
  onConfirm,
  testId,
  formId,
  isSpaceBetween = false,
}: ConfirmAndCancelButtonGroupProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={`flex w-full gap-[1.5rem] ${
        isSpaceBetween ? 'justify-between' : 'justify-end'
      }`}
    >
      <Button
        data-testid={testId?.close}
        onClick={onClose}
        className={`rounded-[0.375rem] border border-[#C53030] px-[1rem] py-[0.5rem] text-[1rem] font-[600] text-[#C53030] hover:bg-red-200 ${
          isSpaceBetween && 'flex-1'
        }`}
      >
        {t('Cancel', { what: '' })}
      </Button>
      <Button
        data-testid={testId?.confirm}
        form={formId}
        onClick={onConfirm}
        className={`rounded-[0.375rem] bg-[#3F51B5]  px-[1rem]  py-[0.5rem] text-[1rem] font-[600] text-white transition-all hover:bg-blue-600 ${
          isSpaceBetween && 'flex-1'
        }`}
      >
        {t('Confirm', { what: '' })}
      </Button>
    </div>
  )
}
