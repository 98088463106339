import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, useMediaQuery } from '@chakra-ui/react'

import { useInventory, useSnackbar } from '@/hooks'

import { useInventoryColumns } from '@/utils/tableColumn/inventory'

import { SearchInput } from '../atoms/interactive/SearchInput'
import { BaseTable } from '../organisms'
import { DeleteModal } from '../templates/modal/DeleteModal'

export const InventoryRoutePage = () => {
  const [isOpen, setIsopen] = useState<ModelOpenType>('')
  const [selectInventoryData, setSelectInventoryData] =
    useState<InventoryData>()
  const [isLargeThan1280] = useMediaQuery('(min-width: 1920px)')
  const inventoryResponse = useInventory()

  const handleAction = (modalType: ModelOpenType, data?: InventoryData) => {
    setIsopen(modalType)
    setSelectInventoryData(['delete'].includes(modalType) ? data : undefined)
  }
  const snackBar = useSnackbar()
  const { t, i18n } = useTranslation()
  const onClose = () => {
    setIsopen('')
  }
  const handleSearch = (value: string) => {
    inventoryResponse.setFilter(value)
  }

  const handleDeleteInventory = async () => {
    if (!selectInventoryData?.id) return
    await inventoryResponse.delete.mutateAsync({
      id: String(selectInventoryData?.id),
    })
    onClose()
  }

  const InventoryColumns = useInventoryColumns({
    handleAction,
    language: i18n.language,
  })
  return (
    <Box className="m-4 h-[calc(100vh_-_90px)] rounded-[0.375rem] border bg-white p-4">
      <div className="mb-[2rem] flex justify-end">
        <SearchInput
          value={''}
          onChange={handleSearch}
          placeholder={`${t('input.SearchThe', {
            name:
              t('Name') +
              '/' +
              t('WorkOrderNumber') +
              '/' +
              t('WorkerState') +
              '/' +
              t('Kanban_', { _: t('Code') }),
            interpolation: {
              escapeValue: false,
            },
          })}`}
          className="min-w-[17.25rem] border-none outline-none placeholder:text-gray-500"
        />
      </div>
      {inventoryResponse.query.isSuccess && (
        <BaseTable
          columns={InventoryColumns}
          data={inventoryResponse.query.data}
          tableLayout="auto"
          pageSize={isLargeThan1280 ? 10 : 8}
        />
      )}
      {selectInventoryData && (
        <DeleteModal
          isOpen={isOpen === 'delete'}
          onClose={onClose}
          onConfirm={handleDeleteInventory}
          name={''}
          type={t('Inventory')}
        />
      )}
    </Box>
  )
}
