import {
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useMemo,
} from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'
import { Icon } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import { Input } from '@/components/atoms'
import { BaseSelect } from '@/components/atoms/select'

import { useCastingManagementQuery, useSnackbar } from '@/hooks'

import {
  CastingFormSchema,
  castingFormSchema,
} from '@/helpers/validate/InAndExitModal'

import { BasicModal } from './BaseModal'

interface InAndExitModalProps {
  isOpen: boolean
  formId: string
  onClose: () => void
  onConfirm: (formData: CastingFormSchema) => void
  selectData: KanbanCastingGroupRecord
}

export const InAndExitModal = forwardRef<
  FormRefImperativeHandleProps,
  InAndExitModalProps
>(({ isOpen, onClose, onConfirm, formId, selectData }, ref) => {
  const { t } = useTranslation()
  const snackBar = useSnackbar()
  const defaultValues = useMemo<CastingFormSchema>(
    () => ({
      casting_group_name: selectData.castingGroupName,
      group_name: selectData.groupName,
      furnace_man: selectData.furnaceManName,
      released_man: Object.keys(selectData)
        .filter((k) => k.toLowerCase().includes('ReleaseManName'.toLowerCase()))
        .map((k) => ({ value: selectData[k] }))
        .filter(({ value }) => value !== ''),
      covered_man: selectData.coverManName,
    }),
    [selectData],
  )
  const { data: castingManagementNames } = useCastingManagementQuery((data) =>
    data.map((item) => item.name),
  )
  const castingManagementOptions = castingManagementNames?.map((name) => ({
    label: name,
    value: name,
  })) as Option[]

  const defaultGroupNameValue =
    (castingManagementOptions &&
      castingManagementOptions.find(
        (option) => option.value === selectData.groupName,
      )) ??
    []

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<CastingFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(castingFormSchema),
    defaultValues,
  })

  /**
   * @remarks
   * 叉工欄位
   */
  const {
    fields: releasedManFields,
    append: releasedManAppend,
    remove: releasedManRemove,
  } = useFieldArray({
    control,
    name: 'released_man',
  })

  const onSave = (data: CastingFormSchema) => {
    if (!castingManagementNames?.length) {
      const errorText =
        t('NoData', { type: t('user.GroupName') }) +
        ' ' +
        t('PleaseAddThe', { what: t('CastingInfo') })
      snackBar.error({ title: errorText })
      return
    }
    onConfirm(data)
  }

  const onSubmit = handleSubmit(onSave)
  useImperativeHandle(ref, () => ({
    submit() {
      onSubmit()
    },
  }))

  const handleChangeSelect = (option: SingleValue<Option>) => {
    setValue('group_name', option?.value as string)
  }
  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('CastingInfo')}
      formId={formId}
    >
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-[1rem]"
        id={formId}
      >
        <Input
          {...register('casting_group_name')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('casting_group_name') + '*'}
          label={t('casting_group_name')}
          isUnderline
          required
          error={errors.casting_group_name}
        />
        <label className="text-[1rem] font-[600] text-gray-500">
          {t('user.GroupName')}
          <span className="text-red-600">*</span>
        </label>
        <BaseSelect
          menuAlign="right"
          isSearchable={false}
          onChange={handleChangeSelect}
          controlTextColor="black"
          isFormSelect
          controlBg="white"
          noOptionsText={t('NoData', { type: t('user.GroupName') })}
          defaultValue={defaultGroupNameValue}
          options={castingManagementOptions}
          placeholder={t('PleasePickThe', { what: t('user.GroupName') })}
        />
        <Input
          {...register('furnace_man')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('furnace_man') + '*'}
          label={t('furnace_man')}
          isUnderline
          required
          error={errors.casting_group_name}
        />
        <div className="flex flex-col gap-[1rem]">
          <FormFieldsAdd
            label={t('released_man')}
            onClick={() => releasedManAppend({ value: '' })}
          >
            {releasedManFields.map((field, index) => (
              <div className="box relative" key={field.id}>
                <Input
                  type="text"
                  placeholder={t('released_man')}
                  {...register(`released_man.${index}.value`)}
                  isUnderline
                  error={errors.released_man?.[index]?.value}
                />
                <div
                  className="absolute right-0 top-[50%] translate-y-[-50%]"
                  onClick={() => releasedManRemove(index)}
                >
                  <Icon
                    as={RemoveIcon}
                    fontSize={22}
                    cursor="pointer"
                    opacity={0.54}
                    color="#C53030"
                  />
                </div>
              </div>
            ))}
          </FormFieldsAdd>
        </div>
        <Input
          {...register('covered_man')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('covered_man')}
          label={t('covered_man')}
          isUnderline
        />
      </form>
    </BasicModal>
  )
})

interface FormFieldsAddProps extends PropsWithChildren {
  label: string
  onClick: () => void
}
const FormFieldsAdd = ({ label, onClick, children }: FormFieldsAddProps) => {
  return (
    <>
      <FormFieldsAddLabel label={label} required onClick={onClick} />
      {children}
    </>
  )
}

interface FormAddFieldsLabelProps {
  label: string
  required?: boolean
  onClick: () => void
}
const FormFieldsAddLabel = ({
  label,
  required,
  onClick,
}: FormAddFieldsLabelProps) => {
  return (
    <div className="flex items-center justify-between">
      <label className="text-start text-[1rem] font-bold">
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <Icon
        as={AddIcon}
        fontSize={18}
        cursor="pointer"
        onClick={onClick}
        color="#3F51B5"
      />
    </div>
  )
}
