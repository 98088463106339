import React, { PropsWithChildren, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd, MdDelete, MdModeEdit } from 'react-icons/md'
import { Box, Button, Icon } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { twMerge } from 'tailwind-merge'

import { useAPI, useCastingManagement, useSnackbar, useUsers } from '@/hooks'

import { transformCastingToListData } from '@/helpers/CastingList'
import { getSpecificObjKeyValueToArray } from '@/helpers/object'
import { CastingGroupFormSchema } from '@/helpers/validate/InAndExitModal'
import { isOdd } from '@/helpers/validate/math'

import { API } from '@/utils/API'

import { TranslationKeys } from '@/_types_/i18next'
import i18n from '@/locale/i18n'

import { NoDataWarning } from '../atoms'
import { SearchInput } from '../atoms/interactive/SearchInput'
import { CastingUpdateModal } from '../templates/modal/CastingUpdateModal'
import { DeleteModal } from '../templates/modal/DeleteModal'

export const CastingInfoManagementPage = () => {
  const [isOpen, setIsOpen] = useState<ModalOpenType>('')
  const [selectedCastingInfo, setSelectedCastingInfo] = useState<
    CastingGroupFormSchema & { id: number }
  >()

  const { request } = useAPI()
  const apiRoute = API.routes.user
  const castingManagementResponse = useCastingManagement()
  const usersResponse = useUsers()
  const castingManagementData = useMemo(
    () =>
      castingManagementResponse.query.data?.map((casting) => ({
        id: casting.id,
        group_name: casting.name,
        furnace_man: casting.furnaceManName,
        furnace_man_id: casting.furnaceManId,
        released_man: getSpecificObjKeyValueToArray(casting, 'ReleaseManName'),
        released_man_id: getSpecificObjKeyValueToArray(casting, 'ReleaseManId'),
        covered_man: casting.coverManName,
        covered_man_id: casting.coverManId,
        remark: casting.remark,
      })),
    [castingManagementResponse.query.data],
  )

  const { t } = useTranslation()
  const snackBar = useSnackbar()

  const modalTile = useMemo(() => {
    return isOpen === 'create'
      ? t('New', { what: t('Group') })
      : t('Edit', { what: t('Group') })
  }, [isOpen])

  const handleSearch = (value: string) => {
    castingManagementResponse.setFilterText(value)
  }
  const closeModal = () => {
    setIsOpen('')
  }

  const handleSubmit = async (formData: CastingGroupFormSchema) => {
    try {
      const { furnaceManInfo, releasedMansInfo, coveredManInfo } =
        checkUserExist(formData, usersResponse.query.data as UserResData[])
      const manIds = transFormListToPostRequestBody({
        furnaceManInfo,
        releasedMansInfo,
        coveredManInfo: coveredManInfo,
      })
      if (isOpen === 'create') {
        await castingManagementResponse.create.mutateAsync({
          data: {
            name: formData.group_name,
            remark: formData.remark ?? '',
            ...manIds,
          },
        })
      }
      if (isOpen === 'update') {
        if (!selectedCastingInfo?.id) return
        await castingManagementResponse.update.mutateAsync({
          data: {
            name: formData.group_name,
            remark: formData.remark ?? '',

            ...manIds,
          },
          id: String(selectedCastingInfo.id),
        })
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        const wrongManName = String(e.config?.url).split('/').splice(-1)
        snackBar.error({
          title:
            t(
              `api.errors.${e.response?.data.error[0].message}` as TranslationKeys,
            ) +
            '( ' +
            wrongManName +
            ' )',
        })
        return
      }
      if (e instanceof Error) {
        snackBar.error({
          title: t('api.errors.USER_NOT_FOUND') + '( ' + e.message + ' )',
        })
      }
    }
  }

  const handleAction = (
    type: ModalOpenType,
    data?: CastingGroupFormSchema & { id: number },
  ) => {
    setSelectedCastingInfo(
      ['update', 'delete'].includes(type) ? data : undefined,
    )
    setIsOpen(type)
  }
  const handleDeleteUser = async () => {
    if (!selectedCastingInfo) return
    await castingManagementResponse.delete.mutateAsync({
      id: String(selectedCastingInfo.id),
    })
    closeModal()
  }
  return (
    <Box className="m-4 h-[calc(100vh_-_90px)] overflow-scroll rounded-md bg-white shadow">
      <Box
        className="flex flex-row gap-[0.75rem]"
        p="4"
        justifyContent="flex-end"
        alignItems="center"
      >
        <SearchInput
          value={''}
          onChange={handleSearch}
          placeholder={`${i18n.t('input.SearchThe', {
            name: t('user.Name') + t('Or') + t('user.GroupName'),
          })}`}
          className="border-none outline-none placeholder:text-gray-500"
        />
        <Button
          colorScheme="blue"
          h="38px"
          leftIcon={<Icon as={MdAdd} fontSize={20} />}
          onClick={() => handleAction('create')}
        >
          {t('New', { what: '' })}
        </Button>
      </Box>
      {castingManagementData?.length ? (
        <div className="mt-[2rem] grid grid-cols-1 gap-[2rem] p-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {castingManagementData.map((data, index) => (
            <CastIngCard key={index} data={data} handleAction={handleAction} />
          ))}
        </div>
      ) : (
        <NoDataWarning />
      )}
      {isOpen && (
        <CastingUpdateModal
          isOpen={['create', 'update'].includes(isOpen)}
          modalType={isOpen}
          onClose={closeModal}
          formId={isOpen + 'Casting'}
          onConfirm={handleSubmit}
          title={modalTile}
          selectedData={selectedCastingInfo}
        />
      )}

      {selectedCastingInfo && (
        <DeleteModal
          isOpen={isOpen === 'delete'}
          onClose={closeModal}
          onConfirm={handleDeleteUser}
          name={selectedCastingInfo?.group_name}
          type={t('Group')}
        />
      )}
    </Box>
  )
}

interface CastIngCardProps {
  data: CastingGroupFormSchema & {
    id: number
    furnace_man_id: number
    released_man_id: { value: string }[]
    covered_man_id: number
  }
  handleAction: (
    type: ModalOpenType,
    data?: CastingGroupFormSchema & { id: number },
  ) => void
}
const CastIngCard = ({ data, handleAction }: CastIngCardProps) => {
  const usersResponse = useUsers()
  const { t } = useTranslation()
  const listsData = transformCastingToListData(data, {
    furnaceManText: t('furnace_man'),
    releasedManText: t('released_man'),
    coveredManText: t('covered_man'),
  })
  const userList = usersResponse.query.data || []
  const getUserById = (Id: number) => userList.find((user) => user.id === Id)
  const formData = {
    id: data.id,
    group_name: data.group_name,
    furnace_man: getUserById(data.furnace_man_id)?.account ?? '',
    released_man: data.released_man_id.map((item) => ({
      value: getUserById(+item.value)?.account ?? '',
    })),
    covered_man: getUserById(data.covered_man_id)?.account ?? '',
    remark: data?.remark,
  }

  return (
    <div className="border-black/0.3 rounded-[0.625rem] border p-[0.75rem] shadow-sm">
      <div className="flex items-center justify-between border-b border-gray-300 px-[1.5rem] pb-[0.75rem] pt-[0.5rem]">
        <CastingText>{data.group_name}</CastingText>
        <div className="flex items-center gap-[0.625rem]">
          <Icon
            as={MdModeEdit}
            fontSize={22}
            cursor="pointer"
            onClick={() => handleAction('update', formData)}
          />
          <Icon
            as={MdDelete}
            fontSize={22}
            color={'red.600'}
            cursor="pointer"
            onClick={() => handleAction('delete', formData)}
          />
        </div>
      </div>
      <ul>
        {listsData.map((list, index) => (
          <li
            key={index}
            className="flex items-center border-b border-gray-300"
          >
            <CastingText
              className={`${
                isOdd(index + 1) ? 'bg-blue-50' : 'bg-black/5'
              } w-[100px] px-[1.5rem] py-[1rem] `}
            >
              {list.text}
            </CastingText>
            <CastingText className="flex-1 px-[1.5rem] py-[1rem] font-[400]">
              {list.desc}
            </CastingText>
          </li>
        ))}
      </ul>
    </div>
  )
}

const CastingText = ({
  children,
  className,
}: PropsWithChildren & { className?: string }) => {
  const cn = twMerge('text-gray-700 font-[700] text-[0.875rem]', className)
  return <div className={cn}>{children}</div>
}

const getReleasedManInfoList = (
  lists: { value: string }[],
  usersData: UserResData[],
) => {
  const result: UserResData[] = []
  const errors: { name: string }[] = []
  for (const list of lists) {
    const furnaceManInfo = usersData.find((user) => user.account === list.value)
    if (furnaceManInfo) {
      result.push(furnaceManInfo)
    } else {
      errors.push({ name: list.value })
    }
  }
  return { result, errors }
}
const transFormListToPostRequestBody = (lists: CastingGroupUserInfo) => {
  const { coveredManInfo, furnaceManInfo, releasedMansInfo } = lists
  const ReleaseManIds = releasedMansInfo.map((user) => user.id)
  return {
    furnaceManId: furnaceManInfo.id,
    firstReleaseManId: ReleaseManIds[0],
    secondReleaseManId: ReleaseManIds[1] || null,
    thirdReleaseManId: ReleaseManIds[2] || null,
    coverManId: coveredManInfo?.id || null,
  }
}
export const getUserInfo = (
  formData: CastingGroupFormSchema,
  usersData: UserResData[],
) => {
  const furnaceManInfo = usersData.find(
    (user) => user.account === formData.furnace_man,
  )
  const coveredManInfo = usersData.find(
    (user) => user.account === formData.covered_man,
  )
  const { result: releasedMansInfo, errors: releasedMansErrors } =
    getReleasedManInfoList(formData.released_man, usersData)
  return {
    furnaceManInfo,
    coveredManInfo,
    releasedMansInfo,
    releasedMansErrors,
  }
}
export const checkUserExist = (
  formData: CastingGroupFormSchema,
  usersData: UserResData[],
) => {
  const {
    furnaceManInfo,
    coveredManInfo,
    releasedMansInfo,
    releasedMansErrors,
  } = getUserInfo(formData, usersData)
  if (!furnaceManInfo) {
    throw new Error(formData.furnace_man)
  }
  if (!coveredManInfo && formData.covered_man) {
    if (formData.covered_man) {
      throw new Error(formData.covered_man)
    }
  }
  if (releasedMansErrors.length) {
    throw new Error(releasedMansErrors.map((value) => value.name).join(','))
  }
  return { furnaceManInfo, coveredManInfo, releasedMansInfo }
}
