import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DeleteUserRequestParams,
  DeleteUserResponse,
  PutUserResponse,
} from '@brilltek42/template-types'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { API } from '@/utils/API'
import { jsonToFormData } from '@/utils/Object'

import { usersKeys } from '@/constants/queryKeyFactory'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useUsers = (
  userQuery?: UserListQuery,
  isUserActiveUpdate?: boolean,
) => {
  const [filterValue, setFilter] = useState<string>('')
  const queryClient = useQueryClient()
  const snackBar = useSnackbar()
  const { t } = useTranslation()
  const { request } = useAPI()

  const apiRoute = API.routes.user
  /**
   * @description filter  ProductStation response
   */
  const filterUsersData = useCallback(
    (users: UserResData[]) => {
      if (!filterValue) return users
      const checkColumns: (keyof UserResData)[] = ['name', 'account']
      return users.filter((user) =>
        checkColumns.some((k) =>
          user[k]?.toString().toLowerCase().includes(filterValue.toLowerCase()),
        ),
      )
    },
    [filterValue],
  )
  const getUsersData: QueryFunction<UserResData[]> = async () => {
    const { data } = await request<UserResData[], never, never>(
      'get',
      apiRoute.list(userQuery),
    )
    return data
  }
  const usersDataQuery = useQuery({
    queryKey: usersKeys.lists(),
    queryFn: getUsersData,
    select: filterUsersData,
  })

  const createUserData: MutationFunction<
    {
      id: number
    },
    { data: UserReqData }
  > = async ({ data }) => {
    const { data: response } = await request<UserResData>(
      'post',
      apiRoute.create,
      {
        data: jsonToFormData(data),
      },
    )
    return response
  }
  const createUserQuery = useMutation({
    mutationKey: ['create user'],
    mutationFn: createUserData,
    onSuccess: () => {
      snackBar.success({
        title: t('toast.add.success', {
          type: t('People'),
        }),
      })
    },
    onError: () => {
      snackBar.error({
        title: t('toast.add.error', {
          type: t('People'),
        }),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      })
    },
  })

  const putUserData: MutationFunction<
    PutUserResponse,
    { data: UserReqData; userId: string }
  > = async ({ data, userId }) => {
    const formData = jsonToFormData(data)
    const { data: response } = await request<PutUserResponse>(
      'put',
      apiRoute.update(userId),
      {
        data: formData,
        params: { id: userId },
      },
    )
    return response
  }

  const updateUserQuery = useMutation({
    mutationKey: usersKeys.update(),
    mutationFn: putUserData,
    onSuccess: () => {
      if (isUserActiveUpdate) return
      snackBar.success({
        title: t('toast.edit.success', {
          type: t('People'),
        }),
      })
    },
    onError: () => {
      if (isUserActiveUpdate) return
      snackBar.error({
        title: t('toast.edit.error', {
          type: t('People'),
        }),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: usersKeys.lists(),
      })
    },
  })

  const deleteUser: MutationFunction<
    DeleteUserResponse,
    { userId: DeleteUserRequestParams['id'] }
  > = async ({ userId }) => {
    const { data: response } = await request<DeleteUserResponse>(
      'delete',
      apiRoute.delete(userId),
      {
        params: { id: userId },
      },
    )

    return response
  }

  const deleteUserQuery = useMutation({
    mutationKey: usersKeys.delete(),
    mutationFn: deleteUser,
    onSuccess: () => {
      snackBar.success({
        title: t('toast.delete.success', {
          type: t('People'),
        }),
      })
    },
    onError: () => {
      snackBar.error({
        title: t('toast.delete.error', {
          type: t('People'),
        }),
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(usersKeys.lists())
    },
  })

  return {
    query: usersDataQuery,
    create: createUserQuery,
    update: updateUserQuery,
    delete: deleteUserQuery,
    filterValue,
    setFilter,
  }
}

export const useUserAccount = (account: string) => {
  const { request } = useAPI()

  const apiRoute = API.routes.user

  const getUsersAccountData: QueryFunction<UserAccountResData> = async () => {
    const { data } = await request<UserAccountResData, never, never>(
      'get',
      apiRoute.account(account ?? ''),
    )
    return data
  }

  const usersDataByAccountQuery = useQuery({
    queryKey: usersKeys.account(account || ''),
    queryFn: getUsersAccountData,
  })

  return {
    query: usersDataByAccountQuery,
  }
}
