import React from 'react'
import ReactDOM from 'react-dom/client'

import '@/locale/i18n'

import './index.css'

import App from './App'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />,
)
