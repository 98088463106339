import { Icon, useToast, UseToastOptions } from '@chakra-ui/react'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

const DEFAULT_OPTIONS: UseToastOptions = {
  duration: 2000,
  // isClosable: true,
  position: 'bottom',
}

export const useSnackbar = () => {
  const toast = useToast()

  const success = (config: UseToastOptions) => {
    toast({
      ...DEFAULT_OPTIONS,
      status: 'success',
      ...config,
    })
  }
  const error = (config: UseToastOptions) => {
    toast({
      ...DEFAULT_OPTIONS,
      status: 'error',
      variant: 'customError',
      icon: <Icon as={ReportProblemIcon} boxSize={6} />,
      ...config,
    })
  }
  const warning = (config: UseToastOptions) => {
    toast({
      ...DEFAULT_OPTIONS,
      status: 'warning',
      ...config,
    })
  }
  const info = (config: UseToastOptions) => {
    toast({
      ...DEFAULT_OPTIONS,
      status: 'info',
      variant: 'customInfo',
      ...config,
    })
  }
  const loading = (config: UseToastOptions) => {
    toast({
      ...DEFAULT_OPTIONS,
      status: 'loading',
      duration: null,
      ...config,
    })
  }

  const closeAll = () => {
    toast.closeAll()
  }

  return {
    success,
    error,
    warning,
    info,
    loading,
    closeAll,
  }
}
