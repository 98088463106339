import { ChangeEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdRemoveCircle } from 'react-icons/md'
import {
  Box,
  Flex,
  Icon,
  Image,
  Textarea,
  UseModalProps,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'

import { Input } from '@/components/atoms'
import { ImageUpload } from '@/components/organisms/form/ImageUpload'

import {
  ProductFormSchema,
  productFormSchema,
} from '@/helpers/validate/Product'

import { BasicModal } from './BaseModal'

export interface ProductUpdateModalProps extends UseModalProps {
  type: ProductPageModalType
  title: string
  formId: string
  selectedProduct?: ProductionData
  onConfirm: (data: ProductFormSchema) => void
}

export const ProductUpdateModal = ({
  isOpen,
  type,
  onClose,
  title,
  formId,
  onConfirm,
  selectedProduct,
  ...rest
}: ProductUpdateModalProps) => {
  const { t } = useTranslation()
  const { register, handleSubmit, formState, reset, setValue } =
    useForm<ProductFormSchema>({
      resolver: zodResolver(productFormSchema),
      mode: 'onChange',
    })
  const [avatar, setAvatar] = useState<string>(selectedProduct?.image ?? '')

  useEffect(() => {
    if (!isOpen) return
    if (type === 'edit') {
      reset(selectedProduct)
      handleClearAvatar()
      setAvatar(selectedProduct?.image ?? '')
    } else {
      reset({})
      handleClearAvatar()
    }
  }, [selectedProduct, type, isOpen])

  const onSubmit: SubmitHandler<ProductFormSchema> = (data) => {
    if (data.photo === undefined) delete data.photo
    onConfirm(data)
    onClose()
  }

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    await register('photo').onChange(e)
    const file = e.target.files?.[0]
    if (!file || formState.errors.photo) return
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (!reader.result) return
      setAvatar(reader.result?.toString())
    }
  }
  const handleClearAvatar = () => {
    setValue('photo', undefined)
    setAvatar('')
  }

  const deleteAvatar = () => {
    setValue('photo', null)
    setAvatar('')
  }

  return (
    <BasicModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      {...rest}
      formId={formId}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit, (error) => {
          console.log(error)
        })}
        className="flex flex-col gap-3 "
      >
        <Box display={'flex'} justifyContent={'center'}>
          {avatar ? (
            <Flex position="relative" w={150}>
              <Image
                src={avatar}
                alt="image"
                boxSize={150}
                objectFit="cover"
                className="rounded-md"
              />
              <Icon
                as={MdRemoveCircle}
                position="absolute"
                className="cursor-pointer bg-white"
                borderRadius={50}
                top={0}
                right={0}
                color="red"
                fontSize={26}
                onClick={deleteAvatar}
              />
            </Flex>
          ) : (
            <ImageUpload
              error={formState.errors.photo}
              register={register}
              onChange={handleUploadFile}
            />
          )}
        </Box>
        <Input
          {...register('code')}
          type="text"
          disabled={formState.isSubmitting}
          error={formState.errors.code}
          label={t('Product_', { _: t('ID') })}
          placeholder={t('Product_', { _: t('ID') }) + '*'}
          isUnderline
          required
        />
        <Input
          {...register('name')}
          type="text"
          disabled={formState.isSubmitting}
          error={formState.errors.name}
          label={t('Product_', { _: t('Name') })}
          placeholder={t('Product_', { _: t('Name') }) + '*'}
          isUnderline
          required
        />
        <Input
          {...register('defaultBindQuantity', { valueAsNumber: true })}
          type="number"
          disabled={formState.isSubmitting}
          error={formState.errors.defaultBindQuantity}
          label={t('product.Assemble')}
          placeholder={t('product.Assemble') + '*'}
          isUnderline
          required
        />
        <Input
          {...register('customerName')}
          type="text"
          disabled={formState.isSubmitting}
          error={formState.errors.customerName}
          label={t('Customer_', { _: t('ID') })}
          placeholder={t('Customer_', { _: t('ID') }) + '*'}
          isUnderline
          required
        />
        <Input
          {...register('version')}
          type="text"
          disabled={formState.isSubmitting}
          error={formState.errors.version}
          label={t('product.CP_version')}
          placeholder={t('product.CP_version') + '*'}
          isUnderline
          required
        />
        <Textarea
          {...register('remark')}
          disabled={formState.isSubmitting}
          placeholder={t('Remark')}
        />
      </form>
    </BasicModal>
  )
}
