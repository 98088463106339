import { z } from 'zod'

import i18n from '@/locale/i18n'

export const defectiveTypeFormSchema = z.object({
  code: z
    .string()
    .min(1, { message: i18n.t('error.isRequired', { name: '' }) })
    .max(30)
    .optional(),
  type: z
    .string()
    .min(1, { message: i18n.t('error.isRequired', { name: '' }) })
    .max(30),
})

export type DefectiveTypeFormSchemaType = z.infer<
  typeof defectiveTypeFormSchema
>

export const defectiveFormSchema = z.object({
  type: z.string().optional(),
  reasonCode: z.string().optional(),
  code: z.string().optional(),
  vi: z
    .string()
    .min(1, { message: i18n.t('error.isRequired', { name: '' }) })
    .max(30),
  zhTW: z
    .string()
    .min(1, { message: i18n.t('error.isRequired', { name: '' }) })
    .max(30),
})

export type DefectiveFormSchemaType = z.infer<typeof defectiveFormSchema>
