import { PropsWithChildren, Suspense, useEffect } from 'react'
import {
  createRoutesFromChildren,
  HashRouter,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { AuthLayout, ContentLayout, NoLayout } from '@/components/layouts'

import {
  IS_DEVELOPMENT,
  SENTRY_DSN,
  SENTRY_ENV,
  SENTRY_RELEASE,
} from '@/constants/environment'

import { RedirectRoute } from './components/pages/RedirectRoute'
import { PAGES } from './routes/route'
import theme from './theme'

const a = {}

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  environment: SENTRY_ENV || 'development',
  release: SENTRY_RELEASE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const APP_LAYOUTS = {
  auth: AuthLayout,
  content: ContentLayout,
  none: NoLayout,
} as const
export type AppLayout = keyof typeof APP_LAYOUTS

const queryClient = new QueryClient({
  defaultOptions: {},
})
const QueryProvider = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {IS_DEVELOPMENT && (
        <ReactQueryDevtools
          panelProps={{ style: { textAlign: 'left', fontSize: '1rem' } }}
        />
      )}
    </QueryClientProvider>
  )
}

const LayoutComponent = ({
  layout = 'none',
  children,
}: PropsWithChildren<{ layout?: AppLayout }>) => {
  const APPLayout = APP_LAYOUTS[layout]
  return <APPLayout>{children}</APPLayout>
}
const RouteContent = () => {
  return (
    <HashRouter>
      <SentryRoutes>
        {PAGES.map(({ component, path, layout, ...rest }) => (
          <Route
            key={path}
            path={path}
            element={
              <LayoutComponent layout={layout}>{component}</LayoutComponent>
            }
            {...rest}
          />
        ))}
        {/* default route */}
        <Route path="*" element={<RedirectRoute />} />
      </SentryRoutes>
    </HashRouter>
  )
}

const AppContent = () => {
  return (
    <ChakraProvider theme={theme}>
      <QueryProvider>
        <Suspense fallback={'Loading...'}>
          <RouteContent />
        </Suspense>
      </QueryProvider>
    </ChakraProvider>
  )
}

function App() {
  return (
    <div className="App h-screen w-screen">
      <AppContent />
    </div>
  )
}

export default App
