import { useRef, useState } from 'react'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { chakra, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { twMerge } from 'tailwind-merge'

import { NoDataWarning } from '@/components/atoms'

export interface VirlistTableProps<TData> {
  columns: ColumnDef<TData, any>[]
  data: TData[]
  tableType?: 'simple' | 'striped' | 'unstyled'
  headClass?: string
  columnClass?: string
  getRowStyle?: (row: TData) => React.CSSProperties
  containerHeight?: number
  needOrder?: boolean
}

export const VirlistTable = <TData, _>({
  columns,
  data,
  tableType = 'simple',
  headClass = '',
  columnClass = '',
  getRowStyle,
  containerHeight = 200,
  needOrder = false,
}: VirlistTableProps<TData>) => {
  const [sorting, setSorting] = useState<SortingState>([])

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: needOrder ? getSortedRowModel() : undefined,
    debugTable: true,
  })

  const { rows } = table.getRowModel()
  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 35,
  })

  const items = virtualizer.getVirtualItems()

  const headClasses = twMerge('font-bold text-[0.875rem]', headClass)
  const columnClasses = twMerge('text-[0.875rem]', columnClass)
  return (
    <div
      className={` overflow-auto `}
      style={{
        height: containerHeight + 'px',
      }}
    >
      <div ref={tableContainerRef}>
        <Table
          className="fixTableHead"
          variant={tableType}
          style={{ height: virtualizer.getTotalSize() }}
        >
          <Thead className={headClasses}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width:
                          header.getSize() !== 120 ? header.getSize() : 120,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none text-[0.875rem] font-[700] text-[#2D3748]'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}

                          {needOrder && (
                            <chakra.span pl={2} fontSize="1rem">
                              {header.column.getIsSorted() ? (
                                header.column.getIsSorted() === 'desc' ? (
                                  <ArrowDownIcon data-testid="user_table_arrow_down" />
                                ) : (
                                  <ArrowUpIcon data-testid="user_table_arrow_up" />
                                )
                              ) : null}
                            </chakra.span>
                          )}
                        </div>
                      )}
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {items.length ? (
              items.map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<TData>
                return (
                  <Tr
                    key={row.id}
                    style={getRowStyle ? getRowStyle(row.original) : {}}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id} className={columnClasses}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })
            ) : (
              <Tr>
                <Td colSpan={columns.length}>
                  <NoDataWarning />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>
    </div>
  )
}
