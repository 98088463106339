import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { API } from '@/utils/API'

import { inventoryKey } from '@/constants/queryKeyFactory'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useInventory = (inventoryRecordQuery?: InventoryRecordQuery) => {
  const [filter, setFilter] = useState<string>('')
  const queryClient = useQueryClient()
  const { request } = useAPI()
  const snackBar = useSnackbar()
  const { t } = useTranslation()
  const apiRoute = API.routes.inventory
  const filterInventoryData = (data: InventoryData[]) => {
    const checkColumns: (keyof InventoryData)[] = [
      'keeperName',
      'transactionUserName',
      'workOrderCode',
      'workOrderStationNameVi',
      'workOrderStationNameZhTW',
      'workOrderStationCode',
      'kanbanCode',
    ]
    return data.filter((item) =>
      checkColumns.some((c) =>
        String(item[c]).toLowerCase().includes(filter.toLowerCase()),
      ),
    )
  }
  const getInventoryQueryData: QueryFunction<InventoryData[]> = async () => {
    const { data } = await request<InventoryData[]>(
      'get',
      apiRoute.list(inventoryRecordQuery),
    )
    return data
  }
  const inventoryDataQuery = useQuery({
    queryKey: inventoryKey.lists(),
    queryFn: getInventoryQueryData,
    select: filterInventoryData,
  })
  const deleteInventory: MutationFunction<
    { msg: string },
    { id: string }
  > = async ({ id }) => {
    const { data } = await request<{ msg: string }>(
      'delete',
      apiRoute.delete(id),
    )
    return data
  }
  const deleteInventoryDataQuery = useMutation({
    mutationKey: inventoryKey.delete(),
    mutationFn: deleteInventory,
    onSuccess: () => {
      snackBar.success({
        title: t('toast.delete.success', {
          type: t('Inventory'),
        }),
      })
    },
    onError: (error) => {
      snackBar.error({
        title: t('toast.delete.error', {
          type: t('Inventory'),
        }),
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(inventoryKey.lists())
    },
  })
  return {
    query: inventoryDataQuery,
    delete: deleteInventoryDataQuery,
    setFilter,
  }
}
