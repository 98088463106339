import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, useMediaQuery } from '@chakra-ui/react'

import { BaseTable } from '@/components/organisms'

import { useWorkOrder } from '@/hooks'

import { completeOrderTableColumnHelper } from '@/helpers/tableColumn'

import { formatDate } from '@/utils/Date'

import i18n from '@/locale/i18n'

import { SearchInput } from '../atoms/interactive/SearchInput'

export const CompleteWorkerPage = () => {
  const { t } = useTranslation()
  const workOrderQuery = useWorkOrder(i18n.language, { status: 'stock' })
  const [filter, setFilter] = useState('')

  const filteredData = workOrderQuery.query.data?.filter((item) => {
    const checkColumn: (keyof CompleteOrderTableRes)[] = [
      'code',
      'customerShortName',
      'productName',
      'customerCodeName',
    ]
    return checkColumn.some((k) =>
      item[k]
        .toString()
        .toLocaleLowerCase()
        .includes(filter.toLocaleLowerCase()),
    )
  })

  const [isLargeThan1280] = useMediaQuery('(min-width: 1920px)')
  const columns = [
    completeOrderTableColumnHelper.accessor('code', {
      header: t('CompleteOrder.Order'),
    }),
    completeOrderTableColumnHelper.accessor('orderId', {
      header: t('CompleteOrder.OrderId'),
    }),
    completeOrderTableColumnHelper.accessor('orderSequence', {
      header: t('CompleteOrder.OrderSequence'),
    }),
    completeOrderTableColumnHelper.accessor('customerShortName', {
      header: t('CompleteOrder.UserName'),
    }),
    completeOrderTableColumnHelper.accessor('productName', {
      header: t('CompleteOrder.ProductName'),
    }),
    completeOrderTableColumnHelper.accessor('operationSequence', {
      header: t('CompleteOrder.OrderSort'),
    }),
    completeOrderTableColumnHelper.accessor('customerCodeName', {
      header: t('CompleteOrder.CompanyId'),
    }),
    completeOrderTableColumnHelper.accessor('quantity', {
      header: t('CompleteOrder.OrderNum'),
    }),
    completeOrderTableColumnHelper.accessor('createdAt', {
      header: t('CompleteOrder.CreateTime'),
      cell: ({ getValue }) => {
        const date = getValue()
        return <>{formatDate(date)}</>
      },
    }),
    // completeOrderTableColumnHelper.display({
    //   id: 'enable',
    //   header: t('CompleteOrder.CancelProcess'),
    //   cell: (info) => <EnableBTN />,
    // }),
  ]

  return (
    <Box className="m-4 h-[calc(100vh_-_90px)] rounded border bg-white">
      <Box
        className="flex flex-row "
        p="4"
        alignItems="center"
        justifyContent={'end'}
      >
        <SearchInput
          value={''}
          onChange={(value) => setFilter(value)}
          placeholder={`${t('CompleteOrder.OrderSearch')}`}
        />
      </Box>
      <Box p="4">
        <BaseTable
          columns={columns}
          data={filteredData}
          pageSize={isLargeThan1280 ? 10 : 8}
        />
      </Box>
    </Box>
  )
}

// const EnableBTN = () => {
//   const snackBar = useSnackbar()
//   return (
//     <Icon
//       as={MdOutlineBlock}
//       fontSize={22}
//       cursor="pointer"
//       opacity={0.54}
//       color={'#000'}
//       onClick={() => snackBar.info({ title: '工單已取消入庫' })}
//     />
//   )
// }
