import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { UserLoginForm, UserLoginFormDataType } from '@/components/organisms'

import { useAuth, useNavigation, useSnackbar, useToken } from '@/hooks'

export const LoginPage = () => {
  const navigation = useNavigation()
  const snackBar = useSnackbar()
  const { updateToken } = useToken()
  const { t } = useTranslation()
  const { login, isAuthenticated } = useAuth()

  const handleAuthentication = async (formData: UserLoginFormDataType) => {
    const { password, account } = formData
    try {
      const token = await login({ password, account })
      snackBar.success({ title: t('Success') })
      updateToken(token)
    } catch (e) {
      if (e instanceof AxiosError) {
        snackBar.error({ title: t('Error', { type: e.response?.status }) })
        return
      }
      snackBar.error({ title: t('Error') })
    }
  }

  return (
    <div className="flex h-full flex-col items-center justify-center p-2 ">
      <div className="flex flex-col">
        <div className="mb-10 text-center text-4xl text-slate-800">
          {t('Login')}
        </div>

        <UserLoginForm onSubmit={handleAuthentication} />
      </div>
    </div>
  )
}
