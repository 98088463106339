import { useTranslation } from 'react-i18next'

export const NoDataWarning = () => {
  const { t } = useTranslation()

  return (
    <div className="flex h-[90%] w-full flex-col items-center justify-center">
      <img src="empty.svg" alt="No data" />
      <p className="text-center text-lg text-gray-400">{t('error.NoData')}</p>
    </div>
  )
}
