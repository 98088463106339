import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { API } from '@/utils/API'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useSFTWorkOrder = () => {
  const { t } = useTranslation()
  const { request } = useAPI()
  const snackBar = useSnackbar()
  const apiRoute = API.routes.sft

  const getWorkOrderData = async (query: SFTWorkOrderQuery) => {
    const { data } = await request<SFTWorkOrderResponse>(
      'get',
      apiRoute.workOrder(query),
    )
    return data
  }

  const workOrderMutation = useMutation({
    mutationFn: getWorkOrderData,
    onError: (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.add.error', {
              type: t('Workorder'),
            }) +
            ' ' +
            t(`api.errors.${error.response?.data?.message}`, ''),
        })
        return
      }
      snackBar.error({ title: t('Error') })
    },
  })

  return {
    query: workOrderMutation,
  }
}
