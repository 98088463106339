import { useState } from 'react'
import { Box } from '@chakra-ui/react'

import { useWorkHour } from '@/hooks/useWorkHour'

import { formatDate } from '@/utils/Date'
import {
  WorkerHourInfoColumns,
  WorkerHourKanbanColumns,
  WorkerHourOrderColumns,
} from '@/utils/tableColumn'

import i18n from '@/locale/i18n'

import { DatePicker } from '../atoms/interactive/Date'
import { SearchInput } from '../atoms/interactive/SearchInput'
import { CollapsibleTable } from '../organisms'

export const WorkerHourRoutePage = () => {
  const DATE_FORMAT = 'YYYY-MM-DD'
  const [date, setDate] = useState(
    formatDate(new Date().toString(), DATE_FORMAT),
  )
  const workHourQuery = useWorkHour({ date })

  const [filter, setFilter] = useState('')

  const transformedData = workHourQuery.query.data
    .filter((d) => d.userName.toUpperCase().includes(filter?.toUpperCase()))
    .map((item) => {
      const updatedStations = item.stations.map((station) => {
        const updatedKanbanData = station.kanbanRecordData.map(
          (kanbanData) => ({
            ...kanbanData,
            id: kanbanData.kanbanRecordId,
          }),
        )

        return {
          ...station,
          id: station.workOrderStationId,
          childData: updatedKanbanData,
        }
      })

      return {
        ...item,
        id: item.userId,
        childData: updatedStations,
      }
    })

  const displayData = transformedData.filter((d) =>
    d.userName.toUpperCase().includes(filter?.toUpperCase()),
  )

  return (
    <Box className="m-4 h-[calc(100vh_-_90px)] rounded-[0.375rem] border bg-white p-4">
      <div className="mb-5 flex flex-wrap items-stretch justify-start gap-[0.75rem] md:justify-end">
        <div>
          <DatePicker
            dateChange={(value) => {
              if (value) {
                const d = formatDate(value.toString(), DATE_FORMAT)
                setDate(d)
              }
            }}
            date={new Date()}
          />
        </div>
        <SearchInput
          value={filter}
          onChange={setFilter}
          placeholder={`${i18n.t('input.SearchThe', {
            name: i18n.t('Name'),
          })}`}
          className="border-none outline-none placeholder:text-gray-500 "
        />
      </div>
      <CollapsibleTable
        columns={WorkerHourInfoColumns}
        childColumns={WorkerHourOrderColumns}
        thirdColumns={WorkerHourKanbanColumns}
        columnChildClass="bg-gray-50 border-b-2 border-gray-300"
        headChildClass="bg-gray-200 "
        columnThirdClass="bg-white"
        headThirdClass="bg-[#BEE3F8] border-b-[1px]"
        data={displayData}
        showStatusList={false}
        needOrder={false}
      />
    </Box>
  )
}
