import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdImage, MdRemoveCircle } from 'react-icons/md'
import {
  Box,
  Flex,
  Icon,
  Image,
  ModalFooter,
  Select,
  Text,
  Textarea,
} from '@chakra-ui/react'

import { Input } from '@/components/atoms'

import { useRoleList } from '@/hooks/useRole'

import { ConfirmAndCancelButtonGroup } from './ConfirmAndCancelButtonGroup'

export interface FormDataType {
  photo?: File | null
  account: string
  roleId: number
  name: string
  email?: string
  password?: string
  remark?: string
  active?: 0 | 1
  groupId: number
}

export interface UserUpdateFormProps {
  userData?: UserResData
  onSubmit: (formData: FormDataType) => void
  onClose: () => void
}

export const UserUpdateForm = ({
  userData,
  onSubmit,
  onClose,
}: UserUpdateFormProps) => {
  const [avatar, setAvatar] = useState('')
  const roleList = useRoleList()
  const { t } = useTranslation()
  const { register, formState, handleSubmit, watch, setValue } =
    useForm<FormDataType>({
      defaultValues: userData,
    })

  const watchRole = watch('roleId')

  useEffect(() => {
    if (!userData?.image) return
    setAvatar(userData.image)
  }, [userData?.image])

  const upload = (file?: File) => {
    if (!file) return
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (!reader.result) return
      setValue('photo', file)
      setAvatar(reader.result?.toString())
    }
  }

  const avatarBlock = useMemo(
    () => (
      <Box display="flex" justifyContent="center" mb="3">
        {avatar ? (
          <Box className="mx-2">
            <Flex position="relative" w={150}>
              <Image
                {...register('photo')}
                src={avatar}
                alt="image"
                boxSize={150}
                objectFit="cover"
                className="rounded-md"
              />
              <Icon
                as={MdRemoveCircle}
                position="absolute"
                className="cursor-pointer bg-white"
                borderRadius={50}
                top={0}
                right={0}
                color="red"
                fontSize={26}
                onClick={() => {
                  setAvatar('')
                  setValue('photo', null)
                }}
              />
            </Flex>
          </Box>
        ) : (
          <label style={{ width: 150, cursor: 'pointer' }}>
            <Box
              className="flex flex-col items-center justify-center rounded border-2 border-dashed"
              boxSize={150}
            >
              <MdImage color="#ddd" size={26} />
              <Text fontSize="sm" color="#ddd">
                {t('UploadThe', { what: t('user.Avatar', '') })}
              </Text>
              <input
                {...register('photo')}
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                multiple
                onChange={(e) => {
                  register('photo').onChange(e)
                  upload(e.target.files?.[0])
                }}
              />
            </Box>
          </label>
        )}
      </Box>
    ),
    [setValue, avatar],
  )

  return (
    <div>
      <form className="m-2 flex flex-col">
        {avatarBlock}
        <Input
          {...register('account', { required: t('Required') })}
          disabled={formState.isSubmitting}
          type="text"
          placeholder={`${t('user.Account')}*`}
          outerClassName="mb-3"
          isUnderline
          error={formState.errors.account}
        />
        <Input
          {...register('name', { required: t('Required') })}
          disabled={formState.isSubmitting}
          type="text"
          placeholder={`${t('user.Name')}*`}
          outerClassName="mb-3"
          isUnderline
          error={formState.errors.name}
        />
        <Select
          {...register('roleId', { required: t('Required') })}
          border="none"
          borderBottom="1px"
          borderColor={formState.errors.roleId ? 'red.500' : 'gray.200'}
          placeholder={`${t('user.Role')}*`}
          color={!watchRole ? 'gray.400' : 'black'}
          disabled={formState.isSubmitting}
          rounded="none"
          mb="3"
          fontSize="0.875rem"
          style={{ paddingInlineStart: 0 }}
        >
          {Object.entries(roleList).map(([k, role]) => (
            <option key={k} value={k}>
              {role}
            </option>
          ))}
        </Select>
        {formState.errors.roleId && (
          <Text color="red.500">{formState.errors.roleId?.message}</Text>
        )}
        <Input
          {...register('email')}
          disabled={formState.isSubmitting}
          type="text"
          placeholder={t('user.Mail')}
          outerClassName="mb-3"
          isUnderline
        />
        <Input
          {...register(
            'password',
            userData?.account ? {} : { required: t('Required') },
          )}
          disabled={formState.isSubmitting}
          type="password"
          placeholder={`${t('user.Password')}*`}
          outerClassName="mb-3"
          isUnderline
          error={formState.errors.password}
        />
        <Textarea
          {...register('remark')}
          disabled={formState.isSubmitting}
          placeholder={t('user.Remark')}
        />

        <ModalFooter style={{ paddingInline: 0 }}>
          <ConfirmAndCancelButtonGroup
            testId={{
              close: 'user_update_modal_close',
              confirm: 'user_update_modal_submit',
            }}
            onClose={onClose}
            onConfirm={handleSubmit((formData) => {
              onSubmit(formData)
            })}
          />
        </ModalFooter>
      </form>
    </div>
  )
}
