import { createColumnHelper } from '@tanstack/react-table'

import { MockUserData } from '@/utils/UserData'

export const userTableColumnHelper = createColumnHelper<UserResData>()
export const WorkerOrderTableColumnHelper =
  createColumnHelper<WorkerOrderData>()

export const WorkerHourInfoTableColumnHelper =
  createColumnHelper<WorkerHourInfoData>()
export const WorkerHourOrderTableColumnHelper =
  createColumnHelper<WorkerHourOrderData>()
export const WorkerHourKanbanTableColumnHelper =
  createColumnHelper<WorkerHourKanbanData>()
export const mockBaseTableColumnHelper = createColumnHelper<MockUserData>()
export const mockUserTableColumnHelper = createColumnHelper<MockUserData>()
export const DefectiveTableColumnHelper = createColumnHelper<DefectiveData>()
export const WorkerstateTableColumnHelper =
  createColumnHelper<StationListData>()
export const WorkStationSectionTableColumnHelper =
  createColumnHelper<StationSectionData>()
