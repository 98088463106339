import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import { Box, Button, Icon, useMediaQuery } from '@chakra-ui/react'

import { useWorkStationSection } from '@/hooks/useWorkStationSection'

import { WorkStationSectionFormSchema } from '@/helpers/validate/WorkStationSection'

import {
  WorkStationSectionChildColumns,
  WorkStationSectionColumns,
} from '@/utils/tableColumn'

import { PATHS } from '@/constants/path'

import { SearchInput } from '../atoms/interactive/SearchInput'
import { CollapsibleTable } from '../organisms'
import { PageWrapper } from '../organisms/PageWrapper'
import { DeleteModal } from '../templates/modal/DeleteModal'
import { WorkStationSectionCreateModal } from '../templates/modal/WorkStationSectionCreateModal'
import { WorkStationSectionUpdateModal } from '../templates/modal/WorkStationSectionUpdateModal'

type TransformedStationSectionData = StationSectionData & {
  childData: StationListData[]
}

export const WorkStationSectionPage = () => {
  const { t } = useTranslation()

  const [modal, setModal] = useState<ModalOpenType | ''>('')
  const [select, setSelect] = useState<StationSectionData>()
  const [isLargeThan1280] = useMediaQuery('(min-width: 1920px)')
  const [filter, setFilter] = useState('')

  const WorkStationSectionModel = useWorkStationSection()
  const workStationSectionList = useMemo(() => {
    return WorkStationSectionModel.query.data
  }, [WorkStationSectionModel.query])

  const transformedData = workStationSectionList?.map((item) => ({
    ...item,
    childData: item.stationSectionProductBaseArr,
  })) as TransformedStationSectionData[]

  const closeModal = () => {
    setModal('')
  }
  const handleSearch = (value: string) => {
    setFilter(value)
  }
  const handleCreateConfirm = async (data: WorkStationSectionFormSchema) => {
    await WorkStationSectionModel.create.mutateAsync({ data })
  }
  const handleUpdateConfirm = async (data: WorkStationSectionFormSchema) => {
    if (!select) return
    await WorkStationSectionModel.update.mutateAsync({
      data,
      id: select.stationSectionId,
    })
  }
  const showModal = (data: StationSectionData, modal: ModalOpenType) => {
    setModal(modal)
    setSelect(data)
  }
  const handleDelete = async () => {
    if (!select) return
    await WorkStationSectionModel.delete.mutateAsync({
      id: select.stationSectionId,
    })
    closeModal()
  }
  const columns = WorkStationSectionColumns({ showModal })
  const childColumns = WorkStationSectionChildColumns()
  const displayList = transformedData.filter((item) => {
    return item.stationSectionCode.includes(filter)
  })

  const TABS = [
    {
      label: t('StationManagement'),
      path: PATHS.station,
    },
    {
      label: t('StationSectionManagement'),
      path: PATHS.stationSection,
    },
  ]

  return (
    <PageWrapper tabs={TABS}>
      <Box
        className="flex flex-row gap-[0.75rem]"
        p="4"
        justifyContent="flex-end"
        alignItems="center"
      >
        <SearchInput
          value={''}
          onChange={handleSearch}
          placeholder={`${t('input.SearchThe', {
            name: t('WorkStationSection'),
          })}`}
          className="border-none outline-none placeholder:text-gray-500"
        />
        <Button
          colorScheme="blue"
          h="38px"
          leftIcon={<Icon as={MdAdd} fontSize={20} />}
          onClick={() => setModal('create')}
        >
          {t('New', { what: '' })}
        </Button>
      </Box>
      {displayList && (
        <Box p="4">
          <CollapsibleTable
            columns={columns}
            childColumns={childColumns}
            data={displayList}
            headClass="[&_th:last-of-type_.flex]:justify-end"
            pageSize={isLargeThan1280 ? 10 : 8}
            contextHeight="60vh"
            showStatusList={false}
          />
        </Box>
      )}

      {/* create modal */}
      <WorkStationSectionCreateModal
        formId="station-session-create"
        onClose={closeModal}
        isOpen={modal === 'create'}
        onConfirm={handleCreateConfirm}
      />
      {/* update modal */}
      {select && (
        <WorkStationSectionUpdateModal
          formId="station-update"
          onClose={closeModal}
          isOpen={modal === 'update'}
          onConfirm={handleUpdateConfirm}
          selectData={select}
        />
      )}
      {/* delete modal */}
      {select && (
        <DeleteModal
          name={t('WorkStationSection')}
          onClose={closeModal}
          isOpen={modal === 'delete'}
          type={select.stationSectionCode}
          onConfirm={handleDelete}
        />
      )}
    </PageWrapper>
  )
}
