import { z } from 'zod'

import i18n from '@/locale/i18n'

const checkInType = ['origin', 'batch', 'mount'] as const
const checkOutType = ['origin', 'batch', 'change'] as const

export const workStationFormSchema = z.object({
  code: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('WorkerState') }),
  }),
  nameZhTW: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('name.zh_TW') }),
  }),
  nameVi: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('name.vi') }),
  }),
  checkInType: z.enum(checkInType),
  checkOutType: z.enum(checkOutType),
})
export type WorkStationFormSchema = z.infer<typeof workStationFormSchema>
