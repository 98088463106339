import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { WorkStationSectionFormSchema } from '@/helpers/validate/WorkStationSection'

import { API } from '@/utils/API'

import { workStationSectionKeys } from '@/constants/queryKeyFactory'

import { TranslationKeys } from '@/_types_/i18next'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useWorkStationSection = () => {
  const { request } = useAPI()
  const { t } = useTranslation()
  const snackBar = useSnackbar()
  const queryClient = useQueryClient()
  const apiRoute = API.routes.workStationSection

  const getWorkStationSectionList: QueryFunction<
    StationSectionData[]
  > = async () => {
    const { data } = await request<StationSectionData[], never, never>(
      'get',
      apiRoute.list,
    )
    return data
  }
  const workStationSectionListQuery = useQuery({
    queryKey: workStationSectionKeys.list(),
    queryFn: getWorkStationSectionList,
    initialData: [],
  })

  const createWorkStationSection: MutationFunction<
    void,
    { data: WorkStationSectionFormSchema }
  > = async ({ data }) => {
    await request('post', apiRoute.create, { data })
  }

  const createProductStationsMutation = useMutation({
    mutationFn: createWorkStationSection,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: workStationSectionKeys.create(),
      })
      snackBar.success({
        title: t('toast.add.success', {
          type: t('WorkStationSection'),
        }),
      })
    },
    onError: (error: AxiosError<ErrorInfo>) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title: t(
            `api.errors.${error.response?.data.error[0].message}` as TranslationKeys,
          ),
        })
        return
      }
      snackBar.error({
        title: t('toast.add.error', {
          type: t('WorkStationSection'),
        }),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: workStationSectionKeys.list(),
      })
    },
  })
  const updateWorkStationSection: MutationFunction<
    void,
    { data: WorkStationSectionFormSchema; id: number }
  > = async ({ data, id }) => {
    await request('patch', apiRoute.update(id), { data })
  }

  const updateProductStationsMutation = useMutation({
    mutationFn: updateWorkStationSection,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: workStationSectionKeys.create(),
      })
      snackBar.success({
        title: t('toast.edit.success', {
          type: t('WorkStationSection'),
        }),
      })
    },
    onError: (error: AxiosError<ErrorInfo>) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title: t(
            `api.errors.${error.response?.data.error[0].message}` as TranslationKeys,
          ),
        })
        return
      }
      snackBar.error({
        title: t('toast.edit.error', {
          type: t('WorkStationSection'),
        }),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: workStationSectionKeys.list(),
      })
    },
  })

  const setWorkStationSection: MutationFunction<
    void,
    { id: number; data: SetStationSectionReq[] }
  > = async ({ id, data }) => {
    await request('post', apiRoute.setStations(id), { data })
  }

  const setWorkStationSectionMutation = useMutation({
    mutationFn: setWorkStationSection,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: workStationSectionKeys.setStations(),
      })
      snackBar.success({
        title: t('toast.edit.success', {
          type: t('WorkStationSection'),
        }),
      })
    },
    onError: (error: AxiosError<ErrorInfo>) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title: t(
            `api.errors.${error.response?.data.error[0].message}` as TranslationKeys,
          ),
        })
        return
      }
      snackBar.error({
        title: t('toast.edit.error', {
          type: t('WorkStationSection'),
        }),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: workStationSectionKeys.setStations(),
      })
    },
  })

  const deleteWorkStationSection: MutationFunction<
    void,
    { id: number }
  > = async ({ id }) => {
    await request('delete', apiRoute.delete(id))
  }

  const deleteProductStationsMutation = useMutation({
    mutationFn: deleteWorkStationSection,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: workStationSectionKeys.create(),
      })
      snackBar.success({
        title: t('toast.delete.success', {
          type: t('WorkStationSection'),
        }),
      })
    },
    onError: (error: AxiosError<ErrorInfo>) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title: t(
            `api.errors.${error.response?.data.error[0].message}` as TranslationKeys,
          ),
        })
        return
      }
      snackBar.error({
        title: t('toast.delete.error', {
          type: t('WorkStationSection'),
        }),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: workStationSectionKeys.list(),
      })
    },
  })

  return {
    query: workStationSectionListQuery,
    create: createProductStationsMutation,
    update: updateProductStationsMutation,
    setStations: setWorkStationSectionMutation,
    delete: deleteProductStationsMutation,
  }
}
