import { MdDelete } from 'react-icons/md'
import { Icon } from '@chakra-ui/react'

import { I18nText } from '@/components/atoms/textual/I18nText'

import { getWorkOrderStationName } from '@/helpers/language'
import { InventoryTableColumnHelper } from '@/helpers/tableColumn'

import i18n from '@/locale/i18n'

import { formatDateWithTime } from '../Date'
import { InventoryTableColumns } from '../UserData'

interface useInventoryColumnsprops {
  handleAction: (modalType: ModelOpenType, data?: InventoryData) => void
  language: string
}
export const useInventoryColumns = ({
  handleAction,
  language,
}: useInventoryColumnsprops) =>
  [
    InventoryTableColumnHelper.accessor('keeperName', {
      header: () => <I18nText id={'HandoverUser'} />,
      cell: ({ row }) => {
        const { keeperAccount, keeperName } = row.original
        return keeperAccount + ' - ' + keeperName
      },
    }),
    InventoryTableColumnHelper.accessor('transactionUserName', {
      header: () => <I18nText id={'User'} />,
      cell: ({ row }) => {
        const { transactionUserAccount, transactionUserName } = row.original
        return transactionUserAccount + ' - ' + transactionUserName
      },
    }),
    InventoryTableColumnHelper.accessor('workOrderCode', {
      header: () => <I18nText id={'WorkOrder'} />,
    }),
    InventoryTableColumnHelper.accessor('productCode', {
      header: () => (
        <I18nText id={'Product_'} extraKey={{ _: i18n.t('Number') }} />
      ),
    }),
    InventoryTableColumnHelper.accessor('workOrderStationCode', {
      header: () => <I18nText id={'WorkerState'} />,
      cell: ({ row }) => {
        const { workOrderStationCode } = row.original
        return (
          workOrderStationCode +
          ' - ' +
          getWorkOrderStationName(row.original, language)
        )
      },
    }),
    InventoryTableColumnHelper.accessor('kanbanCode', {
      header: () => (
        <I18nText id={'Kanban_'} extraKey={{ _: i18n.t('Code') }} />
      ),
    }),
    InventoryTableColumnHelper.accessor('quantity', {
      header: () => <I18nText id={'Quantity'} />,
    }),
    InventoryTableColumnHelper.accessor('operation', {
      header: () => <I18nText id={'Action'} />,
      cell: ({ getValue }) => (
        <I18nText id={getValue() === 'in' ? 'InventoryIn' : 'InventoryOut'} />
      ),
    }),
    InventoryTableColumnHelper.accessor('createdAt', {
      header: () => <I18nText id={'_Time'} extraKey={{ _: '' }} />,
      cell: ({ getValue }) => {
        const data = getValue()
        return formatDateWithTime(data)
      },
    }),
    InventoryTableColumnHelper.display({
      id: 'edit',
      header: () => <I18nText id={'Delete'} extraKey={{ what: '' }} />,
      cell: ({ row }) => {
        return (
          <Icon
            as={MdDelete}
            fontSize={22}
            color={'red.600'}
            cursor="pointer"
            onClick={() => handleAction('delete', row.original)}
          />
        )
      },
    }),
  ] as InventoryTableColumns
