import { FC, useState } from 'react'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { MdFiberSmartRecord, MdRemoveCircle } from 'react-icons/md'
import { Icon } from '@chakra-ui/react'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { useQueryClient } from '@tanstack/react-query'
import { twMerge } from 'tailwind-merge'

import { useDraggableDashboard, useSelectProduct } from '@/hooks'

import { unproxify } from '@/utils/unProxy'

import { productionStationsKeys } from '@/constants/queryKeyFactory'

interface CardItemProps<Data> {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  index: number
  id: string
  data: Data
  columnItem: TaskType
  className?: string
}
export const UnAssignCard: FC<CardItemProps<StationSectionProductBase>> = ({
  provided,
  columnItem,
  className,
}) => {
  const cn = twMerge(
    `flex min-h-[50px] justify-between items-center p-[0.75rem] shadow-card border rounded-[0.375rem]`,
    className,
  )
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={cn}
      style={{
        userSelect: 'none',
        ...provided.draggableProps.style,
      }}
    >
      <p className="text-[0.875rem]">{columnItem.content}</p>
      <Icon as={DragHandleIcon} fontSize={22} cursor="pointer" opacity={0.54} />
    </div>
  )
}
const StationCard: FC<{
  data: StationSectionProductBase
  removeStation: (station: StationSectionProductBase) => void
  index: number
  provided: DraggableProvided
}> = ({ data, removeStation, index, provided }) => {
  const { i18n } = useTranslation()
  const { sequence, stationSectionId, nameZhTW, nameVi } = data
  const { setSelectedProductDetail, selectedProductDetail } = useSelectProduct()
  const { originColumns } = useDraggableDashboard()
  const assignedColumn = originColumns.assign.items
  const assignedStations =
    unproxify(assignedColumn)
      .map(
        (item) =>
          (item.data as StationSectionData).stationSectionProductBaseArr,
      )
      .flat() ?? []
  const isSelectedCard =
    selectedProductDetail.sequence === sequence &&
    selectedProductDetail.stationSectionId === stationSectionId
  const cn = twMerge(
    `flex justify-between items-center p-[0.75rem] shadow-card  rounded-[0.375rem] cursor-pointer bg-white
         ${isSelectedCard ? 'border-[#3F51B5] border-[1.5px]' : ''}`,
  )
  const handleClick = (e) => {
    e.stopPropagation()
    const isTriggerBySwitch = [...e.target.classList].some((c) =>
      c.includes('switch'),
    )
    if (isTriggerBySwitch) return

    const index = assignedStations.findIndex(
      (station) =>
        station.sequence === sequence &&
        station.stationSectionId === stationSectionId,
    )
    setSelectedProductDetail(data, index)
  }

  return (
    <div
      className={cn}
      style={{
        userSelect: 'none',
        ...provided.draggableProps.style,
      }}
      onClick={handleClick}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
    >
      <div className="flex items-center gap-[1rem] text-[0.875rem] ">
        {['first', 'pass'].includes(data.autoStatus) && (
          <Icon as={MdFiberSmartRecord} color="gray.500" fontSize={16} />
        )}
        <p className="font-[400] text-gray-600">#{index + 1}</p>
        <p className="font-[600] text-gray-800">
          {i18n.language === 'vi' ? nameVi : nameZhTW}
        </p>
      </div>
      <div className="flex items-center gap-4">
        <MdRemoveCircle
          onClick={() => removeStation(data)}
          style={{ fontSize: '1.25rem', color: '#DC2626' }}
        ></MdRemoveCircle>
      </div>
    </div>
  )
}

export const AssignCard: FC<CardItemProps<StationSectionData>> = ({
  provided,
  snapshot,
  data,
  className,
  id,
}) => {
  const queryClient = useQueryClient()
  const { originColumns } = useDraggableDashboard()
  const assignedColumn = unproxify(originColumns.assign.items)
  const cn = twMerge(
    `${snapshot.isDragging ? 'bg-gray-300 ' : ''}
      `,
    className,
  )
  const [isOpen, setIsOpen] = useState(false)
  const removeStation = (station: StationSectionProductBase) => {
    const filteredStations = data.stationSectionProductBaseArr
      .filter((item) => {
        return !(item.id === station.id && item.sequence === station.sequence)
      })
      .map((item, i) => ({ ...item, sequence: i + 1 }))
    const currentProductStation = assignedColumn
      .map((item) => {
        return item.id === id
          ? filteredStations
          : (item.data as StationSectionData).stationSectionProductBaseArr
      })
      .flat()
    queryClient.setQueriesData(
      productionStationsKeys.lists(),
      currentProductStation,
    )
  }

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={cn}
      style={{
        userSelect: 'none',
        ...provided.draggableProps.style,
      }}
    >
      <div className="flex items-center justify-between gap-[1rem] bg-gray-100 px-3 py-4 text-[0.875rem] shadow-card">
        <p className="font-[600] text-gray-800">{data.stationSectionCode}</p>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="flex cursor-pointer items-center p-2"
        >
          <span
            style={{
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: '5px 5px 0 5px',
              borderColor: '#000000 transparent transparent transparent',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          ></span>
        </div>
      </div>
      {isOpen && !!data.stationSectionProductBaseArr?.length && (
        <div className="bg-gray-200 p-4">
          <Droppable
            droppableId={id}
            key={'workStation-inner'}
            type="workStation"
            isDropDisabled={false}
          >
            {(provided) => {
              return (
                <div
                  className="grid gap-4"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <>
                    {data.stationSectionProductBaseArr.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={'station-card-' + item.id}
                          index={index}
                        >
                          {(provided) => {
                            return (
                              <StationCard
                                key={item.sequence}
                                data={item}
                                index={index}
                                provided={provided}
                                removeStation={removeStation}
                              ></StationCard>
                            )
                          }}
                        </Draggable>
                      )
                    })}
                  </>
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
        </div>
      )}
    </div>
  )
}
