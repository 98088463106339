export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0
}

export const getSpecificObjKeyValueToArray = (
  obj: Record<string, string | number>,
  target: string,
) => {
  const result: { value: string }[] = []
  for (const [k, v] of Object.entries(obj)) {
    if (k.toLowerCase().includes(target.toLowerCase())) {
      result.push({
        value: v !== null ? String(v) : '',
      })
    }
  }
  return result
}

export const deepEqual = (obj1: object, obj2: object) => {
  if (obj1 === obj2) return true
  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  )
    return false

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) return false

  return keys1.every((key) => deepEqual(obj1[key], obj2[key]))
}
