import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UseModalProps } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'

import { Input } from '@/components/atoms'

import {
  workOrderCreateSchema,
  WorkOrderCreateSchemaType,
} from '@/helpers/validate/WorkOrder'

import { BasicModal } from './BaseModal'

export interface WorkorderCreateModalProps extends UseModalProps {
  formId: string
  onConfirm: (data: WorkOrderCreateSchemaType) => void
}

export const WorkorderCreateModal = ({
  isOpen,
  formId,
  onConfirm,
  onClose,
  ...rest
}: WorkorderCreateModalProps) => {
  const { t } = useTranslation()
  const { register, handleSubmit, formState, reset } =
    useForm<WorkOrderCreateSchemaType>({
      resolver: zodResolver(workOrderCreateSchema),
      mode: 'onChange',
    })

  useEffect(() => {
    if (!isOpen) return
    reset()
  }, [isOpen])

  const onSubmit: SubmitHandler<WorkOrderCreateSchemaType> = (data) => {
    onConfirm(data)
  }

  return (
    <BasicModal
      isOpen={isOpen}
      title={t('New', { what: t('Workorder') })}
      onClose={onClose}
      {...rest}
      formId={formId}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-3"
      >
        <Input
          {...register('code')}
          isUnderline
          required
          type="text"
          label={t('workOrder.kanbans.WorkOrderId')}
          placeholder={t('workOrder.kanbans.WorkOrderId') + '*'}
          disabled={formState.isSubmitting}
          error={formState.errors.code}
        />
      </form>
    </BasicModal>
  )
}
