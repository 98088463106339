export const todoKeys = {
  all: ['todos'] as const,
  lists: () => [...todoKeys.all, 'list'] as const,
  list: (filters: string) => [...todoKeys.lists(), { filters }] as const,
  details: () => [...todoKeys.all, 'detail'] as const,
  detail: (id: number) => [...todoKeys.details(), id] as const,
}
export const workOrderKeys = {
  lists: () => ['workOrder'] as const,
  list: (filters: WorkerOrderStatus) =>
    [...workOrderKeys.lists(), filters] as const,
  create: () => ['create workorder'] as const,
  delete: () => ['delete workorder'] as const,
  stationInfo: (workOrderId: number | null) =>
    [...workOrderKeys.lists(), 'stationInfo', workOrderId] as const,
}
export const workOrderStationKeys = {
  lists: () => ['workOrderStation'] as const,
  list: (id: string) => [...workOrderStationKeys.lists(), id] as const,
  inOut: (id: string | undefined) =>
    [...workOrderStationKeys.lists(), 'inOut', id] as const,
}
export const usersKeys = {
  lists: () => ['users'] as const,
  list: (filters: WorkerOrderStatus) =>
    [...usersKeys.lists(), filters] as const,
  update: () => ['update user'] as const,
  delete: () => ['delete user'] as const,
  account: (account: string) =>
    [...usersKeys.lists(), 'account', account] as const,
}

export const productionsKeys = {
  lists: () => ['productions'] as const,
  list: (filters: WorkerOrderStatus) =>
    [...productionsKeys.lists(), filters] as const,
  create: () => ['create product'] as const,
  update: () => ['update product'] as const,
  delete: () => ['delete product'] as const,
}

export const productionStationsKeys = {
  lists: () => ['productStations'] as const,
  listById: (id?: number) => ['productStations', id] as const,
  create: () => ['create productionStation'] as const,
  update: () => ['update productionStation'] as const,
  delete: () => ['delete productionStation'] as const,
}

export const autoStatusKeys = {
  stations: (params: GetProductStationAutoStatusParams) =>
    ['productStationsAutoStatus stations', params] as const,
  list: (productId?: number) =>
    ['productStationsAutoStatus list', productId] as const,
  create: () => ['create productStationsAutoStatus'] as const,
  delete: () => ['delete productStationsAutoStatus'] as const,
}

export const productionStationsBaseKeys = {
  lists: () => ['productStationsBase'] as const,
}

export const castingManagementKeys = {
  lists: (name?: string) => ['castingManagement', name ?? 'all'] as const,
  create: () => ['create castingManagement'] as const,
  update: () => ['update castingManagement'] as const,
  delete: () => ['delete castingManagement'] as const,
}
export const inventoryKey = {
  lists: () => ['inventory'] as const,
  create: () => ['create inventory'] as const,
  update: () => ['update inventory'] as const,
  delete: () => ['delete inventory'] as const,
}
export const kanbanRecordKeys = {
  update: (id: string) => ['kanbanRecord', id] as const,
}
export const kanbanCastingGroupRecordKeys = {
  update: (id: string) => ['kanbanCastingGroupRecord', id] as const,
}

export const workerHourListKeys = {
  list: (date: string) => ['workerHourList', date] as const,
}

export const kanbanDefectiveRecordKeys = {
  list: (date: string) => ['kanbanDefectiveRecordKeys', date] as const,
}

export const workStationSectionKeys = {
  list: () => ['workStationSection'] as const,
  create: () => ['create workStationSection'] as const,
  setStations: () => ['setStations workStationSection'] as const,
}

export const defectTypeKeys = {
  list: ['defectType'],
  create: ['create defectType'],
  update: ['update defectType'],
  delete: ['delete defectType'],
}

export const defectReasonKeys = {
  list: ['defectReason'],
  create: ['create defectReason'],
  update: ['update defectReason'],
  delete: ['delete defectReason'],
}
