import { I18nText } from '@/components/atoms/textual/I18nText'

import {
  WorkerHourInfoTableColumnHelper,
  WorkerHourKanbanTableColumnHelper,
  WorkerHourOrderTableColumnHelper,
} from '@/helpers/tableColumn'

import i18n from '@/locale/i18n'

import { formatDateWithTime, transformTime } from '../Date'
import {
  WorkerHourInfoTableColumns,
  WorkerHourKanbanTableColumns,
  WorkerHourOrderTableColumns,
} from '../UserData'

export const WorkerHourInfoColumns: WorkerHourInfoTableColumns = [
  WorkerHourInfoTableColumnHelper.accessor('userName', {
    header: () => <I18nText id={'Name'} />,
  }),
  WorkerHourInfoTableColumnHelper.accessor('userTotalWorkTime', {
    header: () => <I18nText id={'TotalWorkTime'} />,
    cell: ({ getValue }) => transformTime(getValue()),
  }),
]

export const WorkerHourOrderColumns: WorkerHourOrderTableColumns = [
  WorkerHourOrderTableColumnHelper.accessor('workOrderStationNameZhTW', {
    header: () => <I18nText id={'Station'} />,
    cell: ({ row }) => {
      const lang = i18n.language
      const localeToKeyMap = {
        'zh-TW': row.original.workOrderStationNameZhTW,
        'en': row.original.workOrderStationNameVi,
        'vi': row.original.workOrderStationNameVi,
      }

      return localeToKeyMap[lang]
    },
  }),
  WorkerHourOrderTableColumnHelper.accessor('checkInTime', {
    header: () => <I18nText id={'workOrder.station.CheckinTime'} />,
    cell: ({ getValue }) => formatDateWithTime(getValue()),
  }),
  WorkerHourOrderTableColumnHelper.accessor('checkOutTime', {
    header: () => <I18nText id={'workOrder.station.CheckoutTime'} />,
    cell: ({ getValue }) => (getValue() ? formatDateWithTime(getValue()) : '-'),
  }),
  WorkerHourOrderTableColumnHelper.accessor('stationTotalWorkTime', {
    header: () => <I18nText id={'TotalWorkTime'} />,
    cell: ({ getValue }) => transformTime(getValue()),
  }),
]

export const WorkerHourKanbanColumns: WorkerHourKanbanTableColumns = [
  WorkerHourKanbanTableColumnHelper.accessor('kanbanBatchNumber', {
    header: () => <I18nText id={'KanbanBatch'} />,
  }),
  WorkerHourKanbanTableColumnHelper.accessor('workOrderCode', {
    header: () => <I18nText id={'WorkOrderNumber'} />,
  }),
  WorkerHourKanbanTableColumnHelper.accessor('kanbanCode', {
    header: () => <I18nText id={'KanbanNumber'} />,
    cell: ({ getValue, row: { original } }) => {
      const { isRework = false } = original
      return `${getValue()}${isRework ? `(${i18n.t('rework')})` : ''}`
    },
  }),
  WorkerHourKanbanTableColumnHelper.accessor('checkInTime', {
    header: () => <I18nText id={'workOrder.station.CheckinTime'} />,
    cell: ({ getValue }) => formatDateWithTime(getValue()),
  }),
  WorkerHourKanbanTableColumnHelper.accessor('checkOutTime', {
    header: () => <I18nText id={'workOrder.station.CheckoutTime'} />,
    cell: ({ getValue }) => (getValue() ? formatDateWithTime(getValue()) : '-'),
  }),
  WorkerHourKanbanTableColumnHelper.accessor('totalPauseTime', {
    header: () => <I18nText id={'TotalPauseTime'} />,
    cell: ({ getValue }) => transformTime(getValue()),
  }),
  WorkerHourKanbanTableColumnHelper.accessor('workOrderQuantity', {
    header: () => <I18nText id={'workOrder.kanbans.Quantity'} />,
  }),
  WorkerHourKanbanTableColumnHelper.accessor('distributeWorkTime', {
    header: () => <I18nText id={'AvgWorkTime'} />,
    cell: ({ getValue }) => transformTime(getValue()),
  }),
]
