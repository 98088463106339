import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { WorkStationFormSchema } from '@/helpers/validate/WorkerState'

import { API } from '@/utils/API'

import { productionStationsBaseKeys } from '@/constants/queryKeyFactory'

import { TranslationKeys } from '@/_types_/i18next'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useProductStationBase = (select?: StationListData | undefined) => {
  const { request } = useAPI()
  const { t } = useTranslation()
  const snackBar = useSnackbar()
  const queryClient = useQueryClient()
  const apiRoute = API.routes.productStationBase
  const [filterValue, setFilter] = useState<string | null>(null)

  /**
   * @description filter  ProductStation response
   */
  const filterProductStationsData = useCallback(
    (stations: StationListData[]) => {
      if (!filterValue) return stations
      const checkColumn: (keyof StationListData)[] = [
        'code',
        'nameZhTW',
        'nameVi',
      ]
      return stations.filter((station) =>
        checkColumn.some((k) => {
          return station[k]
            .toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        }),
      )
    },
    [filterValue],
  )
  /**
   * @description   API `GET` request for get ProductStation data from the list.
   */
  const getProductStations: QueryFunction<StationListData[]> = async () => {
    const { data } = await request<StationListData[], never, never>(
      'get',
      apiRoute.list,
    )

    return data
  }
  const productStationsQuery = useQuery({
    queryKey: productionStationsBaseKeys.lists(),
    queryFn: getProductStations,
    select: filterProductStationsData,
  })

  const productStationsIdsResponse = useQuery({
    queryKey: productionStationsBaseKeys.lists(),
    queryFn: getProductStations,
    select: (data) => data.map((item) => item.id),
  })

  /**
   * @description API `DELETE` request for deleting product stations data from the list.
   */
  const deleteProductStationsData: MutationFunction<
    void,
    StationListData['id']
  > = async (stationId) => {
    await request('delete', apiRoute.delete(stationId))
  }
  const deleteProductStationsQuery = useMutation({
    mutationFn: deleteProductStationsData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: productionStationsBaseKeys.lists(),
      })
      snackBar.success({
        title: t('toast.delete.success', {
          type: t('WorkerState'),
        }),
      })
    },
    onError: () => {
      snackBar.error({
        title: t('toast.delete.error', {
          type: t('WorkerState'),
        }),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: productionStationsBaseKeys.lists(),
      })
    },
  })

  /**
   * @description API `PATCH` request for update product stations data from the list.
   */
  const updateProductStationsData: MutationFunction<
    void,
    { data: WorkStationFormSchema; stationsId: StationListData['id'] }
  > = async ({ data, stationsId }) => {
    await request('patch', apiRoute.update(stationsId), { data })
  }
  const updateProductStationsQuery = useMutation({
    mutationFn: updateProductStationsData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: productionStationsBaseKeys.lists(),
      })
      snackBar.success({
        title: t('toast.edit.success', {
          type: t('WorkerState'),
        }),
      })
    },
    onError: () => {
      snackBar.error({
        title: t('toast.edit.error', {
          type: t('WorkerState'),
        }),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: productionStationsBaseKeys.lists(),
      })
    },
  })

  /**
   * @description API `PATCH` request for update product stations data from the list.
   */
  const createProductStationsData: MutationFunction<
    void,
    { data: WorkStationFormSchema }
  > = async ({ data }) => {
    await request('post', apiRoute.create, { data })
  }
  const createProductStationsQuery = useMutation({
    mutationFn: createProductStationsData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: productionStationsBaseKeys.lists(),
      })
      snackBar.success({
        title: t('toast.add.success', {
          type: t('WorkerState'),
        }),
      })
    },
    onError: (error: AxiosError<ErrorInfo>) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title: t(
            `api.errors.${error.response?.data.error[0].message}` as TranslationKeys,
          ),
        })
        return
      }
      snackBar.error({
        title: t('toast.add.error', {
          type: t('WorkerState'),
        }),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: productionStationsBaseKeys.lists(),
      })
    },
  })

  return {
    query: productStationsQuery,
    delete: deleteProductStationsQuery,
    create: createProductStationsQuery,
    update: updateProductStationsQuery,
    productStationsIds: productStationsIdsResponse.data,
    setFilter,
  }
}
