import { FC, useEffect, useState } from 'react'
import {
  DragDropContext,
  DraggableLocation,
  DropResult,
} from 'react-beautiful-dnd'
import { QueryClient, useQueryClient } from '@tanstack/react-query'

import { useDraggableDashboard, useSelectProduct } from '@/hooks'
import { useProductStation } from '@/hooks/useProductStation'

import { unproxify } from '@/utils/unProxy'

import { productionStationsKeys } from '@/constants/queryKeyFactory'

import { DraggableAssignedColumn } from './DraggableAssignedColumn'
import { DraggableDashboardSideBar } from './DraggableDashboardSideBar'
import { DraggableWorkSectionColumn } from './DraggableWorkSectionColumn'
import { DraggableWorkStationColumn } from './DraggableWorkStationColumn'

export interface DraggableDashboardProps {
  containHeight: number
  productStations: ProductStationResponse[] | undefined
}

const swapElements = (array, index1, index2) => {
  ;[array[index1], array[index2]] = [array[index2], array[index1]]
}

const hasDestColumn = (
  result: Record<string, any>,
): result is MoveItemReturnType & {
  destColumn: TaskStatusType
  destItems: TaskType[]
} => {
  return result.destColumn !== undefined && result.destItems !== undefined
}
const moveItem = (
  columns: Record<string, TaskStatusType>,
  source: DraggableLocation,
  destination: DraggableLocation,
) => {
  const isSameColumn = source.droppableId === destination.droppableId
  const sourceColumn = columns[source.droppableId]
  const sourceItems = [...sourceColumn.items]
  const destColumn = columns[destination.droppableId]
  const destItems = [...destColumn.items]
  const [removed] = sourceItems.splice(source.index, 1)
  if (isSameColumn) {
    sourceItems.splice(destination.index, 0, removed)

    return {
      sourceColumn,
      sourceItems,
    }
  } else {
    const clone = JSON.parse(JSON.stringify(removed))
    clone.data.id = Date.now() // 讓 id 不重複，避免編輯進出站方式時選項同時被修改
    destItems.splice(destination.index, 0, clone)
    return {
      sourceColumn,
      sourceItems,
      destColumn,
      destItems,
    }
  }
}

const onDragEnd = async (
  result: DropResult,
  columns: Record<string, TaskStatusType>,
  sortColumn: (
    sourceType: string,
    sourceColumn: TaskStatusType,
    sourceItems: TaskType[],
  ) => void,
  changeColumn: (
    sourceType: string,
    destinationType: string,
    sourceColumn: TaskStatusType,
    sourceItems: TaskType[],
    destColumn: TaskStatusType,
    destItems: TaskType[],
  ) => void,
  productId: number,
  productStations: ProductStationResponse[] | undefined,
  stationResponse: ReturnType<typeof useProductStation>,
  queryClient: QueryClient,
) => {
  if (!result.destination) return
  const { type, source, destination } = result

  const handleDragAndDropWorkSection = () => {
    if (
      source.droppableId === 'unAssign' &&
      destination.droppableId === 'unAssign'
    ) {
      return // do not sort unAssign column
    }
    //moveItem is to change column card destination before add to store
    const results = moveItem(columns, source, destination)
    let currentProductStation: ProductStationResponse[] = []
    if (!hasDestColumn(results)) {
      currentProductStation = results.sourceItems
        .map(
          (item) =>
            (item.data as StationSectionData).stationSectionProductBaseArr,
        )
        .flat()
      queryClient.setQueriesData(
        productionStationsKeys.lists(),
        currentProductStation,
      )
      // switch station order
      sortColumn(source.droppableId, results.sourceColumn, results.sourceItems)
    } else {
      //delete station
      if (source.droppableId === 'assign') {
        currentProductStation = results.sourceItems
          .map(
            (item) =>
              (item.data as StationSectionData).stationSectionProductBaseArr,
          )
          .flat()
        queryClient.setQueriesData(
          productionStationsKeys.lists(),
          currentProductStation,
        )
      }

      // add station with product
      if (source.droppableId === 'unAssign' && result.destination) {
        const unAssignColumn = columns.unAssign
        const displaySourceItem = unAssignColumn.items.filter((item) =>
          item.content.includes(unAssignColumn.filter),
        )
        const assignedColumnItems = [...columns.assign.items]
        const sourceItem = displaySourceItem[result.source.index]
        const hasSameSection = assignedColumnItems.some(
          (item) =>
            (item.data as StationSectionData).stationSectionCode ===
            (sourceItem.data as StationSectionData).stationSectionCode,
        )
        if (hasSameSection) {
          // 不能有重複的工段
          return
        }

        assignedColumnItems.splice(result.destination.index, 0, sourceItem)
        currentProductStation = assignedColumnItems
          .map(
            (item) =>
              (item.data as StationSectionData).stationSectionProductBaseArr,
          )
          .flat()
          .map((item, index) => ({ ...item, sequence: index + 1 }))
        queryClient.setQueriesData(
          productionStationsKeys.lists(),
          currentProductStation,
        )
      }
    }
  }

  const addStationToSection = () => {
    const assignedColumn = [...columns.assign.items]
    const targetWorkSection = assignedColumn.find(
      (item) => item.id === destination.droppableId,
    ) as { data: StationSectionData } & TaskType

    const workStationColumn = columns.workStation
    const displaySourceItem = workStationColumn.items.filter((item) =>
      item.content.includes(workStationColumn.filter),
    )
    const sourceItem = displaySourceItem[result.source.index]
      .data as StationListData
    const data = {
      ...sourceItem,
      stationSectionCode: targetWorkSection.data.stationSectionCode,
      stationSectionId: targetWorkSection.data.stationSectionId,
      sequence:
        targetWorkSection.data.stationSectionProductBaseArr.at(-1).sequence + 1,
      enabled: 1,
    } as ProductStationResponse

    const currentProductStation = assignedColumn
      .map(
        (item) =>
          (item.data as StationSectionData).stationSectionProductBaseArr,
      )
      .flat()
    const hasSameStation = currentProductStation.some(
      (item) => item.code === data.code,
    )
    if (hasSameStation) {
      // 不能有重複的工序
      return
    }

    const currentStationSectionProductBaseArr =
      targetWorkSection.data.stationSectionProductBaseArr
    const insertedAry = [
      ...currentStationSectionProductBaseArr.slice(0, destination.index),
      data,
      ...currentStationSectionProductBaseArr.slice(destination.index),
    ]

    targetWorkSection.data.stationSectionProductBaseArr = insertedAry.map(
      (item, index) => ({ ...item, sequence: index + 1 }),
    )

    const resultProductStation = assignedColumn
      .map(
        (item) =>
          (item.data as StationSectionData).stationSectionProductBaseArr,
      )
      .flat()

    queryClient.setQueriesData(
      productionStationsKeys.lists(),
      resultProductStation,
    )
  }

  const sortStationToSection = () => {
    const assignedColumn = [...columns.assign.items]
    const targetWorkSection = assignedColumn.find(
      (item) => item.id === destination.droppableId,
    ) as { data: StationSectionData } & TaskType

    const currentStationSectionProductBaseArr =
      targetWorkSection.data.stationSectionProductBaseArr

    swapElements(
      currentStationSectionProductBaseArr,
      source.index,
      destination.index,
    )
    targetWorkSection.data.stationSectionProductBaseArr =
      currentStationSectionProductBaseArr.map((item, index) => ({
        ...item,
        sequence: index + 1,
      }))

    const currentProductStation = assignedColumn
      .map(
        (item) =>
          (item.data as StationSectionData).stationSectionProductBaseArr,
      )
      .flat()

    queryClient.setQueriesData(
      productionStationsKeys.lists(),
      currentProductStation,
    )
  }

  if (type === 'workStation') {
    const strategies = {
      add: {
        condition:
          source.droppableId === 'workStation' &&
          destination.droppableId.startsWith('as-'),

        callback: addStationToSection,
      },
      sort: {
        condition:
          source.droppableId.startsWith('as-') &&
          destination.droppableId.startsWith('as-'),
        callback: sortStationToSection,
      },
    }
    Object.values(strategies).some((strategy) => {
      if (strategy.condition) {
        strategy.callback()
        return true
      }
    })
  } else {
    handleDragAndDropWorkSection()
  }
}

export const DraggableDashboard: FC<DraggableDashboardProps> = ({
  containHeight,
  productStations,
}) => {
  const { originColumns, sortColumn, changeColumn } = useDraggableDashboard()
  // unproxify
  const [columns, setColumns] = useState<Record<string, TaskStatusType>>(
    unproxify(originColumns),
  )
  const queryClient = useQueryClient()
  const { selectedProduct } = useSelectProduct()
  const stationResponse = useProductStation({ selectedProduct })

  useEffect(() => {
    setColumns(unproxify(originColumns))
  }, [originColumns])

  return (
    <div
      style={{
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}
      className={`relative grid w-full gap-[1.5rem] `}
    >
      <DragDropContext
        onDragEnd={(result) =>
          onDragEnd(
            result,
            columns,
            sortColumn,
            changeColumn,
            unproxify(selectedProduct.id),
            productStations,
            stationResponse,
            queryClient,
          )
        }
      >
        <div style={{ height: containHeight }}>
          <DraggableWorkSectionColumn />
        </div>
        <div style={{ height: containHeight }}>
          <DraggableWorkStationColumn />
        </div>
        <DraggableAssignedColumn containHeight={containHeight} />
        <DraggableDashboardSideBar />
      </DragDropContext>
    </div>
  )
}
