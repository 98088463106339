import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Tab, TabList, Tabs as ChakraTabs } from '@chakra-ui/react'

import { useNavigation } from '@/hooks'

interface TabsProps {
  list: {
    label: string
    path: string
  }[]
}

export default function Tabs({ list }: TabsProps) {
  const { t } = useTranslation()
  const location = useLocation()
  const navigation = useNavigation()

  const selected = list.findIndex(({ path }) => path === location.pathname)

  const handleChange = (index: number) => {
    navigation.goTo(list[index].path)
  }

  const selectedStyle = {
    border: '1px solid',
    borderBottom: 'none',
    borderColor: 'gray.200',
    background: 'white',
    borderRadius: '0.375rem 0.375rem 0 0',
    color: 'black',
  }

  return (
    <ChakraTabs defaultIndex={selected} onChange={handleChange}>
      <TabList color={'gray.500'} borderBottom="none">
        {list.map((item) => (
          <Tab px={5} py={4} key={item.label} _selected={selectedStyle}>
            {item.label}
          </Tab>
        ))}
      </TabList>
    </ChakraTabs>
  )
}
