export const PATHS = {
  login: '/login',
  completeWorkerOrder: '/complete_worker_order',
  castingInfoManagement: '/casting_info_management',
  station: '/station_list',
  stationSection: '/station_session',
  stationSectionDetail: '/station_session/:stationSectionID',
  user: '/user_management',
  product: '/product_management',
  productDetail: '/product_management/:productID',
  workOrder: '/workorder',
  workTime: '/worktime_management',
  defective: '/defective_management',
  defectiveList: '/defective_list',
  defectiveType: '/defective_type',
  inAndExit: '/in_and_exit',
  inventory: '/inventory',
}
