import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react'

import { ConfirmAndCancelButtonGroup } from '@/components/organisms/form/ConfirmAndCancelButtonGroup'

interface BasicModalProps extends PropsWithChildren, UseModalProps {
  isOpen: boolean
  title: string
  onClose: () => void
  onConfirm?: () => void
  formId?: string
}
export const BasicModal = ({
  isOpen,
  onClose,
  onConfirm,
  children,
  title,
  formId,
  ...rest
}: BasicModalProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} {...rest}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>

          <ModalFooter>
            <ConfirmAndCancelButtonGroup
              testId={{
                close: 'user_update_modal_close',
                confirm: 'user_update_modal_submit',
              }}
              onClose={onClose}
              onConfirm={onConfirm}
              formId={formId}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
