import { MdDelete, MdModeEdit } from 'react-icons/md'
import { Avatar, Icon } from '@chakra-ui/react'
import { t } from 'i18next'

import { userTableColumnHelper } from '@/helpers/user'

import { UserTableColumns } from '../UserData'

interface userTableColumnsProps {
  handleAction: (type: ModalOpenType, data?: UserResData) => void
  handleToggleUserActive: (data: UserResData) => void
  roleList: Record<string, RoleType>
}
export const userTableColumns = ({
  handleAction,
  handleToggleUserActive,
  roleList,
}: userTableColumnsProps) =>
  [
    userTableColumnHelper.accessor('image', {
      header: t('user.Avatar', ''),
      cell: (info) => (
        <Avatar
          name={info.row.original.name}
          src={info.getValue() ?? ''}
          size="sm"
          bg="gray.300"
          color="black"
        />
      ),
    }),
    userTableColumnHelper.accessor('name', {
      header: t('user.Name'),
    }),
    userTableColumnHelper.accessor('account', {
      header: t('user.Account'),
    }),
    userTableColumnHelper.accessor('roleId', {
      header: t('user.Role'),
      cell: ({ getValue }) => {
        const roleName = roleList ? roleList[getValue()] : ''
        return roleName
      },
    }),
    userTableColumnHelper.accessor('email', {
      header: t('user.Mail'),
    }),
    userTableColumnHelper.accessor('remark', {
      header: t('user.Remark'),
    }),
    userTableColumnHelper.display({
      id: 'edit',
      header: t('Edit', { what: '' }),
      cell: (info) => (
        <>
          <Icon
            as={MdModeEdit}
            fontSize={22}
            cursor="pointer"
            onClick={() => handleAction('update', info.row.original)}
          />
          <Icon
            as={MdDelete}
            fontSize={22}
            color={'red.600'}
            cursor="pointer"
            onClick={() => handleAction('delete', info.row.original)}
          />
        </>
      ),
    }),
  ] as UserTableColumns
