import { FC, JSXElementConstructor, ReactElement } from 'react'
import {
  Collapse as CollapseBase,
  CollapseProps as CollapseBaseProps,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'

export interface CollapseProps extends CollapseBaseProps {
  color?: string
  icon?: ReactElement<any, string | JSXElementConstructor<any>>
}
export const Collapse: FC<CollapseProps> = ({
  children,
  icon,
  color = 'blue',
  ...rest
}) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <>
      {icon && (
        <IconButton
          background={'gray.200'}
          _active={{
            background: 'gray.400',
          }}
          _hover={{
            background: 'gray.400',
          }}
          onClick={onToggle}
          colorScheme={color}
          aria-label="Search database"
          icon={icon}
        />
      )}
      <CollapseBase
        in={isOpen}
        animateOpacity
        {...rest}
        data-testid="collapse_container"
      >
        {children}
      </CollapseBase>
    </>
  )
}
