import { forwardRef, InputHTMLAttributes, ReactNode, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

type InputType = 'number' | 'text' | 'password'

interface InputSharedProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  outerClassName?: string
  IconCompnent?: ReactNode | undefined
  label?: string
  required?: boolean | undefined
  defaultValue?: string | undefined
  disabled?: boolean | undefined
  error?: FieldError | undefined
  placeholder?: string
  isUnderline?: boolean
  prefixComponent?: ReactNode | undefined
}

interface InputTextProps {
  defaultValue?: string | undefined
  type: Exclude<InputType, 'number' | 'tel'>
  value?: string | undefined
}

interface InputNumberProps {
  defaultValue?: number | string | undefined
  min?: number | string | undefined
  max?: number | string | undefined
  type: 'number'
  value?: number | string | undefined
}
export type InputProps = InputSharedProps & (InputTextProps | InputNumberProps)

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      outerClassName = '',
      defaultValue,
      disabled,
      error,
      prefixComponent,
      IconCompnent,
      label,
      name,
      required,
      type,
      value,
      placeholder,
      isUnderline = false,
      ...rest
    },
    outerRef,
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [focus, setFocus] = useState<boolean>(false)
    return (
      <div className={`flex flex-col justify-start ${outerClassName} `}>
        <label className="text-[1rem] font-[600] text-gray-500" htmlFor={name}>
          {label}
          {required && <span className="text-red-600">*</span>}
        </label>
        <div
          className={`
          flex flex-row items-center gap-1 rounded-md  border-gray-300 bg-white  
        ${isUnderline ? 'border-b' : 'rounded-md border px-2'}
        `}
        >
          {prefixComponent && <div>{prefixComponent}</div>}
          {IconCompnent && <div>{IconCompnent}</div>}

          <input
            {...rest}
            className="h-full flex-1 rounded-sm bg-white  py-[0.5rem]  text-[0.875rem] outline-none"
            ref={outerRef}
            defaultValue={defaultValue}
            value={value}
            disabled={disabled}
            id={name}
            name={name}
            data-testid={name}
            type={setInputType(type, isPasswordVisible)}
            onFocus={() => {
              setFocus(true)
            }}
            onBlur={() => {
              setFocus(false)
            }}
            placeholder={placeholder}
          />

          {type === 'password' && (
            <PasswordToggeler
              isPasswordVisible={isPasswordVisible}
              onClick={() => {
                setIsPasswordVisible((prev) => !prev)
              }}
            />
          )}
        </div>
        {error && (
          <div role="alert" className="text-red-500">
            {error.message}
          </div>
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

const PasswordToggeler = ({
  onClick,
  isPasswordVisible,
}: {
  onClick: () => void
  isPasswordVisible: boolean
}) => (
  <div className="cursor-pointer" onClick={onClick}>
    {isPasswordVisible ? <ViewIcon /> : <ViewOffIcon />}
  </div>
)

const setInputType = (
  type: InputType,
  isPasswordVisible: boolean,
): InputType => {
  if (type !== 'password') {
    return type
  } else {
    return isPasswordVisible ? 'text' : 'password'
  }
}
