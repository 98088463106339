export const IsEmptyObject = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0
}

export const jsonToFormData = <T extends Record<string, any>>(
  form: T,
): FormData => {
  const formData = new FormData()
  Object.keys(form).forEach((key) => {
    formData.append(key, form[key])
  })
  return formData
}
