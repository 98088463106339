import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'

import { Input } from '@/components/atoms'

import {
  WorkStationSectionFormSchema,
  workStationSectionFormSchema,
} from '@/helpers/validate/WorkStationSection'

import { BasicModal } from './BaseModal'

interface WorkStationSectionCreateModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: (data: WorkStationSectionFormSchema) => void
  formId?: string
}

export const WorkStationSectionCreateModal = forwardRef<
  FormRefImperativeHandleProps,
  WorkStationSectionCreateModalProps
>(({ isOpen, onClose, onConfirm, formId }, ref) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<WorkStationSectionFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(workStationSectionFormSchema),
  })

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen])

  const onSave = (data: WorkStationSectionFormSchema) => {
    onConfirm(data)
    onClose()
  }

  const onSubmit = handleSubmit(onSave)
  useImperativeHandle(ref, () => ({
    submit: onSubmit,
  }))
  return (
    <BasicModal
      isOpen={isOpen}
      formId={formId}
      onClose={onClose}
      title={t('New', { what: t('WorkStationSection') })}
    >
      <form onSubmit={onSubmit} id={formId}>
        <Input
          {...register('code')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('WorkOrder') + '*'}
          outerClassName="mb-3"
          label={t('WorkOrder')}
          isUnderline
          required
          error={errors.code}
        />
      </form>
    </BasicModal>
  )
})
