import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { API } from '@/utils/API'
import { jsonToFormData } from '@/utils/Object'

import { productionsKeys } from '@/constants/queryKeyFactory'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useProduction = (productionsListQuery: ProductionsListQuery) => {
  const snackBar = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { request } = useAPI()
  const apiRoute = API.routes.productions
  const getProductionsData: QueryFunction<ProductionData[]> = async () => {
    const { data } = await request<ProductionData[], never, never>(
      'get',
      apiRoute.list(productionsListQuery),
    )

    return data
  }

  const productionDataQuery = useQuery({
    queryKey: productionsKeys.lists(),
    queryFn: getProductionsData,
    cacheTime: API.settings.cacheTime.productions,
    initialData: [],
  })

  const createProduct: MutationFunction<
    CreateProductResponse,
    { data: CreateProductRequest }
  > = async ({ data }) => {
    const formData = jsonToFormData(data)
    const { data: response } = await request<CreateProductResponse>(
      'post',
      apiRoute.create,
      { data: formData },
    )
    return response
  }

  const createProductionQuery = useMutation({
    mutationKey: productionsKeys.create(),
    mutationFn: createProduct,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.add.success', {
          type: t('Product'),
        }),
      })
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.add.error', {
              type: t('Product'),
            }) +
            ' ' +
            error.response?.data?.error[0].message,
        })
        return
      }
      snackBar.error({ title: t('Error') })
    },
    onSettled: () => {
      queryClient.invalidateQueries(productionsKeys.lists())
    },
  })

  const updateProduct: MutationFunction<
    UpdateProductResponse,
    { data: UpdateProductRequest; productId: number }
  > = async ({ data, productId }) => {
    const formData = jsonToFormData(data)

    const { data: response } = await request<UpdateProductResponse>(
      'patch',
      apiRoute.update(productId),
      { data: formData },
    )
    return response
  }

  const updateProductionQuery = useMutation({
    mutationKey: productionsKeys.update(),
    mutationFn: updateProduct,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.edit.success', {
          type: t('Product'),
        }),
      })
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.add.error', {
              type: t('Product'),
            }) +
            ' ' +
            error.response?.data?.error[0].message,
        })
        return
      }
      snackBar.error({ title: t('Error') })
    },
    onSettled: () => {
      queryClient.invalidateQueries(productionsKeys.lists())
    },
  })

  const deleteProduct: MutationFunction<
    DeleteProductResponse,
    { productId: number }
  > = async ({ productId }) => {
    const { data: response } = await request<DeleteProductResponse>(
      'delete',
      apiRoute.delete(productId),
    )

    return response
  }

  const deleteProductionQuery = useMutation({
    mutationKey: productionsKeys.delete(),
    mutationFn: deleteProduct,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.delete.success', {
          type: t('Product'),
        }),
      })
    },
    onError: () => {
      snackBar.error({
        title: t('toast.delete.error', {
          type: t('Product'),
        }),
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(productionsKeys.lists())
    },
  })

  return {
    query: productionDataQuery,
    create: createProductionQuery,
    update: updateProductionQuery,
    delete: deleteProductionQuery,
  }
}
