import { CastingGroupFormSchema } from './validate/InAndExitModal'

type I18nText = {
  furnaceManText: string
  releasedManText: string
  coveredManText: string
}
export const transformCastingToListData = (
  data: CastingGroupFormSchema,
  i18nText: I18nText,
) => {
  const { furnaceManText, releasedManText, coveredManText } = i18nText
  const result: { text: string; desc: string }[] = []
  const furnaceManValue = data.furnace_man
  let releasedManIndex = 1
  const releasedManValues = data.released_man
  const coveredManValue = data.covered_man

  result.push({ text: furnaceManText, desc: furnaceManValue })
  for (const releasedManValue of releasedManValues) {
    result.push({
      text: releasedManText + releasedManIndex,
      desc: releasedManValue.value,
    })
    releasedManIndex++
  }
  result.push({ text: coveredManText, desc: coveredManValue! })
  return result
}
