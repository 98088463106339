import { PropsWithChildren, useEffect } from 'react'

import { useAuth, useNavigation } from '@/hooks'

import { PATHS } from '@/constants/path'

export const AuthLayout = ({ children }: PropsWithChildren) => {
  const navigation = useNavigation()
  const { isAuthenticated } = useAuth()
  useEffect(() => {
    if (!isAuthenticated) {
      navigation.goTo(PATHS.login)
    } else {
      navigation.goTo(PATHS.workOrder)
    }
  }, [isAuthenticated])

  return (
    <div className="flex h-screen w-screen flex-col bg-white">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7">
        <DefaultAside />
        <DefaultBody>{children}</DefaultBody>
      </div>
    </div>
  )
}

const DefaultBody = ({ children }: PropsWithChildren) => {
  return (
    <div className="h-screen rounded-l-md bg-blue-100/50 lg:col-span-3">
      {children}
    </div>
  )
}

const DefaultAside = () => {
  return (
    <div className="relative  hidden h-screen  items-center justify-center rounded-r-md bg-blue-100/50 md:flex lg:col-span-4">
      <div className="z-[9999] w-[20rem]">
        <img src="/LOGO.png" alt="" className="m-0" loading="lazy" />
      </div>
      <img
        src="/BG.png"
        alt=""
        className="absolute m-0 h-full w-full object-cover"
        loading="lazy"
      />
    </div>
  )
}
