import { z } from 'zod'

export const productStationAutoStatusFormSchema = z.object({
  startProductStationId: z.string().optional(),
  endProductStationId: z.string().optional(),
  list: z
    .array(
      z.object({
        startProductStationId: z.number(),
        endProductStationId: z.number(),
      }),
    )
    .optional(),
})

export type ProductStationAutoStatusFormSchema = z.infer<
  typeof productStationAutoStatusFormSchema
>
