import i18n from '@/locale/i18n'

export const getSelectInOptions = (lng) => [
  {
    value: 'origin',
    label: i18n.t('Origin', { lng }),
  },
  {
    value: 'batch',
    label: i18n.t('Batch', { lng }),
  },
  {
    value: 'mount',
    label: i18n.t('Mount', { lng }),
  },
]
export const getSelectOutOptions = (lng) => [
  {
    value: 'origin',
    label: i18n.t('Origin', { lng }),
  },
  {
    value: 'batch',
    label: i18n.t('Batch', { lng }),
  },
  {
    value: 'change',
    label: i18n.t('Change', { lng }),
  },
]
