import { PropsWithChildren } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Icon, Textarea, UseModalProps } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import { Input } from '@/components/atoms'

import {
  CastingGroupFormSchema,
  castingGroupFormSchema,
} from '@/helpers/validate/InAndExitModal'

import { BasicModal } from './BaseModal'

interface CastingUpdateModalProps extends UseModalProps {
  isOpen: boolean
  title: string
  modalType: ModalOpenType
  onClose: () => void
  onConfirm: (data: CastingGroupFormSchema) => void
  selectedData?: CastingGroupFormSchema
  formId?: string
}
export const CastingUpdateModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  formId,
  modalType,
  selectedData,
  ...rest
}: CastingUpdateModalProps) => {
  const { t } = useTranslation()
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<CastingGroupFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(castingGroupFormSchema),
    defaultValues:
      modalType === 'update'
        ? {
            ...selectedData,
            released_man: selectedData?.released_man.filter(
              (man) => man.value !== '',
            ),
          }
        : { released_man: [{ value: '' }] },
  })
  /**
   * @remarks
   * 叉工欄位
   */
  const {
    fields: releasedManFields,
    append: releasedManAppend,
    remove: releasedManRemove,
  } = useFieldArray({
    control,
    name: 'released_man',
  })

  const onSubmit = (data: CastingGroupFormSchema) => {
    onConfirm(data)
    onClose()
  }
  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      formId={formId}
      {...rest}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-[1rem]"
      >
        <Input
          {...register('group_name')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('user.GroupName') + '*'}
          label={t('user.GroupName')}
          isUnderline
          required
          error={errors.group_name}
        />
        <Input
          {...register('furnace_man')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('furnace_man') + '*'}
          label={t('furnace_man')}
          isUnderline
          required
          error={errors.furnace_man}
        />
        <div className="flex flex-col gap-[1rem]">
          <FormFieldsAdd
            label={t('released_man')}
            onClick={() => releasedManAppend({ value: '' })}
          >
            {releasedManFields.map((field, index) => (
              <div className="box relative" key={field.id}>
                <Input
                  type="text"
                  placeholder={t('released_man') + (index + 1)}
                  {...register(`released_man.${index}.value`)}
                  isUnderline
                  error={errors.released_man?.[index]?.value}
                />

                <div
                  className="absolute right-0 top-[50%] translate-y-[-50%]"
                  onClick={() => releasedManRemove(index)}
                >
                  <Icon
                    as={RemoveIcon}
                    fontSize={22}
                    cursor="pointer"
                    opacity={0.54}
                    color="#C53030"
                  />
                </div>
              </div>
            ))}
          </FormFieldsAdd>
        </div>
        <Input
          {...register('covered_man')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('covered_man')}
          label={t('covered_man')}
          isUnderline
        />
        <Textarea
          {...register('remark')}
          placeholder={t('user.Remark')}
          disabled={isSubmitting}
        />
      </form>
    </BasicModal>
  )
}

interface FormFieldsAddProps extends PropsWithChildren {
  label: string
  onClick: () => void
}
const FormFieldsAdd = ({ label, onClick, children }: FormFieldsAddProps) => {
  return (
    <>
      <FormFieldsAddLabel label={label} required onClick={onClick} />
      {children}
    </>
  )
}

interface FormAddFieldsLabelProps {
  label: string
  required?: boolean
  onClick: () => void
}
const FormFieldsAddLabel = ({
  label,
  required,
  onClick,
}: FormAddFieldsLabelProps) => {
  return (
    <div className="flex items-center justify-between">
      <label className="text-start text-[1rem] font-bold">
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <Icon
        as={AddIcon}
        fontSize={18}
        cursor="pointer"
        onClick={onClick}
        color="#3F51B5"
      />
    </div>
  )
}
