import { useTranslation } from 'react-i18next'
import { QueryFunction, useQuery } from '@tanstack/react-query'

import { API } from '@/utils/API'

import { workOrderStationKeys } from '@/constants/queryKeyFactory'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useWorkOrderStation = (
  workOrderStationQuery?: WorkerOrderStationsListQuery,
) => {
  const snackBar = useSnackbar()
  const { t } = useTranslation()
  const { request } = useAPI()
  const apiRoute = API.routes.workOrderStation
  const getWorkOrderListData: QueryFunction<
    WorkerOrderStationsListResponse[]
  > = async () => {
    const { data } = await request<
      WorkerOrderStationsListResponse[],
      never,
      never
    >('get', apiRoute.list(workOrderStationQuery))
    return data
  }
  const workOrderDataQuery = useQuery({
    queryKey: workOrderStationKeys.list(
      workOrderStationQuery?.workOrderId?.toString() ?? 'all',
    ),
    queryFn: getWorkOrderListData,
    cacheTime: API.settings.cacheTime.workOrderListData,
    staleTime: API.settings.staleTimes.workOrderListData,
  })
  return {
    query: workOrderDataQuery,
  }
}
export const useWorkOrderStationInOut = ({
  workOderStationId,
}: {
  workOderStationId: string | undefined
}) => {
  const snackBar = useSnackbar()
  const { t } = useTranslation()
  const { request } = useAPI()
  const apiRoute = API.routes.workOrderStation
  const getWorkOrderListInOutData: QueryFunction<
    WorkerOrderStationsListInAndOutResponse,
    ReturnType<typeof workOrderStationKeys.inOut>
  > = async ({ queryKey }) => {
    const [category, type, id] = queryKey
    const { data } = await request<
      WorkerOrderStationsListInAndOutResponse,
      never,
      never
    >('get', apiRoute.inOut(id))
    return data
  }
  const workOrderInOutDataQuery = useQuery({
    queryKey: workOrderStationKeys.inOut(workOderStationId),
    queryFn: getWorkOrderListInOutData,
    cacheTime: API.settings.cacheTime.workOrderInAndOutData,
    staleTime: API.settings.staleTimes.workOrderInAndOutData,
    keepPreviousData: true,
  })
  return {
    query: workOrderInOutDataQuery,
  }
}
