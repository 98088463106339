import { ReactElement } from 'react'
import { RouteProps } from 'react-router-dom'

import { WorkStationSectionDetailsPage } from '@/components/pages/WorkStationSectionDetailsPage'

import { AppLayout } from '../App'
import {
  CastingInfoManagementPage,
  CompleteWorkerPage,
  DefectiveListPage,
  DefectiveManagementPage,
  DefectiveTypePage,
  InAndExitRoutePage,
  InventoryRoutePage,
  LoginPage,
  ProductionDetailRoutePage,
  ProductionRoutePage,
  StationManagementPage,
  UsersPage,
  WorkerHourRoutePage,
  WorkerOrderRoutePage,
  WorkStationSectionPage,
} from '../components/pages'
import { PATHS } from '../constants/path'

type PagesProps = {
  path: string
  layout?: AppLayout
  component: ReactElement
  exact?: boolean
} & RouteProps
/**
 * the page base route
 * @interface PagesProps Represents route in page
 * @property {String} path  The path of the page
 * @property {String} component  The component of the page
 * @property {String} layout  The page of layout
 * @property {Boolean} exact What ever route need absolute path
 */
export const PAGES = [
  {
    path: PATHS.castingInfoManagement,
    component: <CastingInfoManagementPage />,
    layout: 'content',
    exact: true,
  },
  {
    path: PATHS.completeWorkerOrder,
    component: <CompleteWorkerPage />,
    layout: 'content',
  },
  {
    path: PATHS.defective,
    component: <DefectiveManagementPage />,
    layout: 'content',
  },
  {
    path: PATHS.defectiveType,
    component: <DefectiveTypePage />,
    layout: 'content',
  },
  {
    path: PATHS.defectiveList,
    component: <DefectiveListPage />,
    layout: 'content',
  },
  {
    path: PATHS.inAndExit,
    component: <InAndExitRoutePage />,
    layout: 'content',
  },
  {
    path: PATHS.inventory,
    component: <InventoryRoutePage />,
    layout: 'content',
  },
  {
    path: PATHS.login,
    component: <LoginPage />,
    layout: 'auth',
  },
  {
    path: PATHS.product,
    component: <ProductionRoutePage />,
    layout: 'content',
  },
  {
    path: PATHS.productDetail,
    component: <ProductionDetailRoutePage />,
    layout: 'content',
  },
  {
    path: PATHS.station,
    component: <StationManagementPage />,
    layout: 'content',
  },
  {
    path: PATHS.stationSection,
    component: <WorkStationSectionPage />,
    layout: 'content',
  },
  {
    path: PATHS.stationSectionDetail,
    component: <WorkStationSectionDetailsPage />,
    layout: 'content',
  },
  {
    path: PATHS.user,
    component: <UsersPage />,
    layout: 'content',
  },
  {
    path: PATHS.workOrder,
    component: <WorkerOrderRoutePage />,
    layout: 'content',
  },
  {
    path: PATHS.workTime,
    component: <WorkerHourRoutePage />,
    layout: 'content',
  },
] as PagesProps[]
