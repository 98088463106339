import { useMemo } from 'react'
import { css as chakraCSS, StyleObjectOrFn } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

export const useDatePickerStyles = () => {
  const theme = useTheme()
  return useMemo(() => {
    const defaultStyles: StyleObjectOrFn = {
      'p': 2,
      'bg': 'white',
      'border': '1px solid',
      'borderColor': 'gray.100',
      'boxShadow': 'sm',
      'zIndex': '9999999999',
      'react-datepicker': {
        paddingBottom: '10rem',
      },
      '.react-datepicker__time-container': {
        width: '100px',
        paddingBottom: '3rem',
      },
      '.react-datepicker-time__header': {
        fontWeight: 700,
        fontSize: '0.875rem',
        paddingTop: '5px',
      },
      '.react-datepicker__time-container ': {
        border: 'none',
      },
      'li.react-datepicker__time-list-item': {
        margin: '12px 0',
      },
      'li.react-datepicker__time-list-item:hover': {
        borderRadius: '0.5rem',
      },
      '.react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
        {
          backgroundColor: '#3F51B5',
          borderRadius: '0.5rem',
        },
      '.react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover':
        {
          backgroundColor: '#3F51B5',
          borderRadius: '0.5rem',
        },
      '& .react-datepicker': {
        '&__header': {
          bg: 'none',
          borderBottom: 'none',
        },
        '&__month': {
          mt: 0,
        },
        '&__day-name': {
          color: 'gray.400',

          w: 7,
        },
        '&__day': {
          lineHeight: '28px',
          color: 'gray.700',
          w: 7,
          h: 7,
          borderRadius: '0.5rem',
        },
        '&__day:not(.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected):hover':
          {
            bg: 'gray.100',
          },
        '&__day--today': {
          bg: 'gray.100',
          fontWeight: '400',
        },
        '&__day--selected, &__day--keyboard-selected': {
          bg: '#3F51B5',
          color: 'white',
        },
      },
    }
    return chakraCSS(defaultStyles)(theme)
  }, [theme])
}
