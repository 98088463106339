import { createColumnHelper } from '@tanstack/react-table'

import { MockUserData, User } from '@/utils/UserData'

export const userTableColumnHelper = createColumnHelper<User>()
export const WorkerOrderTableColumnHelper =
  createColumnHelper<WorkOrderStationsInfoQuerySelectResponseData>()
export const WorkerOrderStationTableColumnHelper =
  createColumnHelper<WorkOrderStationInfo>()

export const WorkerHourInfoTableColumnHelper =
  createColumnHelper<WorkerHourInfoData>()
export const WorkerHourOrderTableColumnHelper =
  createColumnHelper<WorkerHourOrderData>()
export const WorkerHourKanbanTableColumnHelper =
  createColumnHelper<WorkerHourKanbanData>()
export const mockBaseTableColumnHelper = createColumnHelper<MockUserData>()
export const DefectiveTableColumnHelper = createColumnHelper<DefectiveData>()
export const DefectiveTypeTableColumnHelper =
  createColumnHelper<GetDefectTypeResponse>()
export const DefectiveListTableColumnHelper =
  createColumnHelper<GetDefectReasonResponse>()
export const completeOrderTableColumnHelper =
  createColumnHelper<CompleteOrderTableRes>()
export const ProductionTableColumnHelpers = createColumnHelper<ProductionData>()
export const InAndExitKanbanListTableColumnHelper =
  createColumnHelper<WorkerOrderKanbanListResponse>()
export const InAndExitDefectListTableColumnHelper =
  createColumnHelper<WorkerOrderDefectiveListResponse>()
export const InventoryTableColumnHelper = createColumnHelper<InventoryData>()
