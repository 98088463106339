import { forwardRef, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box, Icon } from '@chakra-ui/react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import {
  useContainerDimensions,
  useDraggableDashboard,
  useNavigation,
  useProductStationBase,
  useSelectProduct,
} from '@/hooks'
import { useProductStation } from '@/hooks/useProductStation'
import { useWorkStationSection } from '@/hooks/useWorkStationSection'

import { unproxify } from '@/utils/unProxy'

import { DraggableDashboard } from '../layouts/DraggableDashboard'
import { ConfirmAndCancelButtonGroup } from '../organisms/form/ConfirmAndCancelButtonGroup'

export const ProductionDetailRoutePage = () => {
  const toolBarRef = useRef<HTMLDivElement>(null)
  const { height } = useContainerDimensions(toolBarRef)
  const { t, i18n } = useTranslation()
  const containHeight = useMemo(
    () => window.innerHeight * 0.86 - height,
    [height],
  )
  const params = useParams() as { id: string }
  const { addColumnData } = useDraggableDashboard()
  const { selectedProduct, setSelectedProductDetail } = useSelectProduct()
  const stationBaseResponse = useProductStationBase()
  const stationResponse = useProductStation({ selectedProduct })
  const WorkStationSectionModel = useWorkStationSection()
  const unAssignTasks = useMemo<
    (TaskType & { data: StationSectionData })[] | undefined
  >(
    () =>
      WorkStationSectionModel.query.data?.map((stationBase) => ({
        id: 'un' + '-' + stationBase.stationSectionId.toString(),
        content: stationBase.stationSectionCode,
        data: {
          stationSectionCode: stationBase.stationSectionCode,
          stationSectionId: stationBase.stationSectionId,
          stationSectionProductBaseArr:
            stationBase.stationSectionProductBaseArr.map((item) => ({
              ...item,
              stationSectionCode: stationBase.stationSectionCode,
              stationSectionId: stationBase.stationSectionId,
              enabled: true,
            })),
        },
      })),
    [WorkStationSectionModel.query.data, stationResponse],
  )

  const ProductStationBaseModel = useProductStationBase()
  const productStationBaseList = useMemo(
    () => ProductStationBaseModel.query.data,
    [ProductStationBaseModel.query],
  )
  const workStations =
    productStationBaseList?.map((item) => ({
      id: `workStation-${item.id}`,
      content: i18n.language === 'vi' ? item.nameVi : item.nameZhTW,
      data: item,
    })) ?? []

  useEffect(() => {
    if (stationResponse.query.data?.length) {
      setSelectedProductDetail(stationResponse.query.data[0], 0)
    }
  }, [params])

  const assignTasks = useMemo<
    ({ data: StationSectionData } & TaskType)[] | undefined
  >(() => {
    const groupedWithSection =
      stationResponse.query.data?.reduce((prev, cur) => {
        const target = prev.find((item) => {
          const isSameSection =
            item.stationSectionProductBaseArr.at(-1).sequence + 1 ===
            cur.sequence
          return item.stationSectionId === cur.stationSectionId && isSameSection
        })

        if (!target) {
          prev = [
            ...prev,
            {
              stationSectionId: cur.stationSectionId,
              stationSectionCode: cur.stationSectionCode,
              stationSectionProductBaseArr: [cur],
            },
          ]
        } else {
          target.stationSectionProductBaseArr.push(cur)
        }

        return prev
      }, [] as StationSectionData[]) ?? []

    return groupedWithSection.map((stationSection, index) => ({
      id:
        'as' + '-' + stationSection.stationSectionId?.toString() + '-' + index,
      content: stationSection.stationSectionCode,
      data: stationSection,
    }))
  }, [stationResponse.query.data, stationResponse])

  useLayoutEffect(() => {
    if (unAssignTasks) {
      addColumnData('unAssign', unAssignTasks)
    }
    if (assignTasks) {
      addColumnData('assign', assignTasks)
    }
    if (workStations.length) {
      addColumnData('workStation', workStations)
    }
  }, [
    unAssignTasks,
    assignTasks,
    selectedProduct.id,
    stationBaseResponse,
    workStations,
  ])

  return (
    <Box margin={'1.5rem'} display={'flex'} flexDir={'column'} gap={'1.5rem'}>
      <ToolBar ref={toolBarRef} />
      <DraggableDashboard
        containHeight={containHeight}
        productStations={stationResponse.query.data}
      />
    </Box>
  )
}

const ToolBar = forwardRef<HTMLDivElement>((_, ref) => {
  const params = useParams() as { productID: string }
  const navigation = useNavigation()
  const stationBaseResponse = useProductStationBase()
  const { selectedProduct } = useSelectProduct()
  const stationResponse = useProductStation({ selectedProduct })
  const handleConfirm = async () => {
    const stations = stationResponse.query.data || []
    const data = unproxify(
      stations.map((item, index) => {
        const productStationBaseId = stationBaseResponse.query.data?.find(
          (baseItem) => baseItem.code === item.code,
        )?.id
        return {
          ...item,
          checkInType: item.checkInType || 'origin',
          checkOutType: item.checkOutType || 'origin',
          productId: selectedProduct.id,
          productStationBaseId,
          sequence: index + 1,
          enabled: !!item.enabled,
          checkOutQuantity:
            item.checkOutType === 'change' && !!item.checkOutQuantity
              ? item.checkOutQuantity
              : null,
        }
      }),
    )
    await stationResponse.create.mutateAsync({ data })
  }

  return (
    <Box
      className=" flex  items-center justify-between rounded-[0.375rem] bg-white p-[1rem] shadow-md"
      ref={ref}
    >
      <div className="flex items-center justify-start gap-[1.5rem]">
        <Icon
          as={ChevronLeftIcon}
          cursor="pointer"
          fontSize="2rem"
          onClick={() => navigation.goBack()}
        ></Icon>
        <p className="whitespace-nowrap text-[1.25rem] font-[600]">
          {params.productID}
        </p>
      </div>
      <ConfirmAndCancelButtonGroup
        onClose={() => navigation.goBack()}
        onConfirm={handleConfirm}
      />
    </Box>
  )
})
