import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'

import { Input } from '@/components/atoms'
import { BaseSelect } from '@/components/atoms/select'

import { getSelectInOptions, getSelectOutOptions } from '@/helpers/options'
import {
  WorkStationFormSchema,
  workStationFormSchema,
} from '@/helpers/validate/WorkerState'

import { BasicModal } from './BaseModal'

interface WorkerStateCreateModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: (data: WorkStationFormSchema) => void
  formId?: string
}

export const WorkerStateCreateModal = forwardRef<
  FormRefImperativeHandleProps,
  WorkerStateCreateModalProps
>(({ isOpen, onClose, onConfirm, formId }, ref) => {
  const { t, i18n } = useTranslation()

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<WorkStationFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(workStationFormSchema),
  })

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen])

  const onSave = (data: WorkStationFormSchema) => {
    onConfirm(data)
    onClose()
  }

  const onSubmit = handleSubmit(onSave)
  useImperativeHandle(ref, () => ({
    submit: onSubmit,
  }))

  const selectInOptions = useMemo(
    () => getSelectInOptions(i18n.language),
    [i18n.language],
  )
  const selectOutOptions = useMemo(
    () => getSelectOutOptions(i18n.language),
    [i18n.language],
  )

  return (
    <BasicModal
      isOpen={isOpen}
      formId={formId}
      onClose={onClose}
      title={t('New', { what: t('WorkerState') })}
    >
      <form onSubmit={onSubmit} id={formId}>
        <Input
          {...register('code')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('WorkerState') + '*'}
          outerClassName="mb-3"
          label={t('WorkerState')}
          isUnderline
          required
          error={errors.code}
        />
        <Input
          {...register('nameZhTW')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('name.zh_TW') + '*'}
          outerClassName="mb-3"
          label={t('name.zh_TW')}
          isUnderline
          required
          error={errors.nameZhTW}
        />
        <Input
          {...register('nameVi')}
          disabled={isSubmitting}
          type="text"
          placeholder={t('name.vi') + '*'}
          outerClassName="mb-3"
          label={t('name.vi')}
          isUnderline
          required
          error={errors.nameVi}
        />
        <label className="text-[1rem] font-[600] text-gray-500">
          {t('In')}
          <span className="text-red-600">*</span>
        </label>
        <Controller
          name="checkInType"
          control={control}
          render={({ field: { onChange } }) => (
            <Box
              sx={{
                borderBottom: '1px solid #d1d5db',
                marginBottom: '0.75rem',
                fontSize: '0.875rem',
              }}
            >
              <BaseSelect
                menuAlign="right"
                isSearchable={false}
                onChange={(option) => {
                  onChange(option?.value)
                }}
                controlTextColor="black"
                controlBg="white"
                options={selectInOptions}
                placeholder={t('PleasePickThe', { what: t('In') })}
                isFormSelect
              />
            </Box>
          )}
          rules={{ required: true }}
        />
        <label className="text-[1rem] font-[600] text-gray-500">
          {t('Exit')}
          <span className="text-red-600">*</span>
        </label>
        <Controller
          name="checkOutType"
          control={control}
          render={({ field: { onChange } }) => (
            <Box
              sx={{
                borderBottom: '1px solid #d1d5db',
                marginBottom: '0.75rem',
                fontSize: '0.875rem',
              }}
            >
              <BaseSelect
                menuAlign="right"
                isSearchable={false}
                onChange={(option) => {
                  onChange(option?.value)
                }}
                controlTextColor="black"
                controlBg="white"
                options={selectOutOptions}
                placeholder={t('PleasePickThe', { what: t('Exit') })}
                isFormSelect
              />
            </Box>
          )}
          rules={{ required: true }}
        />
      </form>
    </BasicModal>
  )
})
