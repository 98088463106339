import { QueryFunction, useQuery } from '@tanstack/react-query'

import { API } from '@/utils/API'

import { workerHourListKeys } from '@/constants/queryKeyFactory'

import { useAPI } from './useAPI'

export const useWorkHour = (workerHourListQuery: WorkerHourListQuery) => {
  const { request } = useAPI()

  const apiRoute = API.routes.workHour

  const getWorkerHourListData: QueryFunction<
    WorkerHourListData[]
  > = async () => {
    const { data } = await request<WorkerHourListData[]>(
      'get',
      apiRoute.list(workerHourListQuery),
    )
    return data
  }

  const getWorkerHourListDataQuery = useQuery({
    queryKey: workerHourListKeys.list(workerHourListQuery.date),
    queryFn: getWorkerHourListData,
    initialData: [],
  })

  return {
    query: getWorkerHourListDataQuery,
  }
}
