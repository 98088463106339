import { useId, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, useDisclosure } from '@chakra-ui/react'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import PhotoIcon from '@mui/icons-material/Photo'
import { AxiosError } from 'axios'
import { AnimatePresence, motion } from 'framer-motion'

import { DatePickerWithTime } from '@/components/atoms/interactive/Date/DatePickerWithTime'
import { I18nText } from '@/components/atoms/textual/I18nText'
import { getUserInfo } from '@/components/pages'
import { InAndExitModal } from '@/components/templates/modal/InAndExitModal'

import { useKanbanCastingGroupRecord, useSnackbar, useUsers } from '@/hooks'

import {
  InAndExitDefectListTableColumnHelper,
  InAndExitKanbanListTableColumnHelper,
} from '@/helpers/tableColumn'
import { CastingFormSchema } from '@/helpers/validate/InAndExitModal'

import { TranslationKeys } from '@/_types_/i18next'

import { formatDateWithTime } from '../Date'
import type {
  WorkListDefectTableColumns,
  WorkListKanbanTableColumns,
} from '../UserData'

const getStatusTextKey = (status: KanbanListStatus) => {
  switch (status) {
    case 'checkIn':
      return 'Checkin_'
    case 'checkOut':
      return 'Checkout_'
    case 'mount':
      return 'mounted'
    case 'rework':
      return 'rework'
    case 'pause':
      return 'Pause'
    case 'change':
      return 'Change'
  }
}
export const WorkListKanbanColumns: (
  handleConfirmTime: (date: Date, recordId: string) => void,
  workOderStationId: string,
  language: string,
) => WorkListKanbanTableColumns = (
  handleConfirmTime,
  workOderStationId,
  language,
) => [
  InAndExitKanbanListTableColumnHelper.accessor('kanbanCode', {
    header: () => <I18nText id={'InAndExit.KanbansCode'} />,
  }),
  InAndExitKanbanListTableColumnHelper.accessor('status', {
    header: () => <I18nText id={'Status'} />,
    cell: ({ getValue, row: { original } }) => {
      const status = getValue()
      const statusCode = getStatusTextKey(status)
      return (
        <>
          <I18nText id={statusCode} />
          {status === 'change' && (
            <span className="text-blue-500">{original.checkOutFromCode}</span>
          )}
          {!!original.reworkStation && (
            <span className="text-blue-500">
              {language === 'vi'
                ? original.reworkStation['nameVi']
                : original.reworkStation['nameZhTW']}
            </span>
          )}
        </>
      )
    },
  }),
  InAndExitKanbanListTableColumnHelper.accessor('active', {
    cell: ({ getValue }) => (
      <I18nText id={getValue() === true ? 'Yes' : 'No'} />
    ),
  }),
  InAndExitKanbanListTableColumnHelper.accessor('exceptQuantity.defect', {
    header: () => <I18nText id={'InAndExit.DefectQuantity'} />,
  }),
  InAndExitKanbanListTableColumnHelper.accessor('reworkQuantity', {
    header: () => <I18nText id={'InAndExit.ReworkQuantity'} />,
  }),
  InAndExitKanbanListTableColumnHelper.accessor('totalQuantity', {
    header: () => <I18nText id={'InAndExit.Quantity'} />,
  }),
  InAndExitKanbanListTableColumnHelper.accessor('time', {
    header: () => <I18nText id={'_Time'} extraKey={{ _: '' }} />,
    cell: ({ getValue, row }) => {
      const date = !!getValue() ? formatDateWithTime(getValue()) : undefined
      return !!date ? (
        <EditTime
          date={date}
          handleConfirm={(date) =>
            handleConfirmTime(date, String(row.original.kanbanRecordId))
          }
        />
      ) : (
        <p className="text-center">-</p>
      )
    },
  }),
  InAndExitKanbanListTableColumnHelper.accessor('operator', {
    header: () => <I18nText id={'Name'} />,
  }),
  InAndExitKanbanListTableColumnHelper.accessor('remark', {
    header: () => <I18nText id={'Remark'} />,
  }),
  InAndExitKanbanListTableColumnHelper.display({
    id: 'edit',
    header: () => <I18nText id={'ActionCasting'} className="text-[1rem]" />,
    cell: ({ getValue, row }) => {
      const { kanbanCastingGroupRecord } = row.original
      return (
        kanbanCastingGroupRecord && (
          <EditModal
            data={kanbanCastingGroupRecord}
            workOderStationId={workOderStationId}
          />
        )
      )
    },
  }),
]
export const useWorkListDefectColumns: (
  language: string,
) => WorkListDefectTableColumns = (language) => [
  InAndExitDefectListTableColumnHelper.accessor('kanbanCode', {
    header: () => <I18nText id={'InAndExit.KanbansCode'} />,
  }),
  InAndExitDefectListTableColumnHelper.accessor('type', {
    header: () => <I18nText id={'DefectiveType'} />,
  }),
  InAndExitDefectListTableColumnHelper.accessor(
    language === 'vi' ? 'descriptionVi' : 'descriptionZhTW',
    {
      header: () => <I18nText id={'Description'} />,
    },
  ),
  InAndExitDefectListTableColumnHelper.accessor('quantity', {
    header: () => <I18nText id={'Quantity'} />,
    size: 10,
  }),
  InAndExitDefectListTableColumnHelper.accessor('photos', {
    header: () => <I18nText id={'Photo'} />,
    size: 10,
    cell: ({ getValue }) => {
      const src = getValue()[0] || '/frame.png'
      return <Image src={src} />
    },
  }),
]

interface ImageProps {
  src: string
}

const Image = ({ src }: ImageProps) => {
  const [showImage, setShowImage] = useState<boolean>(false)
  return (
    <>
      {showImage && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed left-0 top-0 z-[99999] flex h-screen w-screen items-center justify-center bg-black/10"
            onClick={() => setShowImage(false)}
          >
            <div className="relative flex h-auto max-h-screen w-[25vw] items-center justify-center overflow-hidden">
              <img
                src={src}
                alt=""
                className="block max-h-full max-w-full object-contain"
              />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
      <Icon
        as={PhotoIcon}
        fontSize={22}
        cursor="pointer"
        opacity={0.54}
        color={'#000'}
        onClick={() => setShowImage(true)}
      />
    </>
  )
}
interface EditTimeProps {
  date: string
  handleConfirm: (date: Date) => void
}
const EditTime = ({ date, handleConfirm }: EditTimeProps) => {
  return (
    <div className="flex items-center gap-[0.875rem]">
      <div className=" w-[200px]">
        <DatePickerWithTime
          date={new Date(date)}
          onConfirm={handleConfirm}
          dateFormat="yyyy-MM-dd HH:mm"
          isTableEdit
          showTimeInput
        />
      </div>
    </div>
  )
}

interface EditModalProps {
  data: KanbanCastingGroupRecord
  workOderStationId: string
}
const EditModal = ({ data, workOderStationId }: EditModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const ref = useRef<FormRefImperativeHandleProps>(null)
  const formId = useId()
  const { t } = useTranslation()
  const snackBar = useSnackbar()

  const kanbanCastingGroupRecordResponse = useKanbanCastingGroupRecord({
    id: String(data.id),
    workOderStationId,
  })
  const usersResponse = useUsers()
  const onConfirm = async (formData: CastingFormSchema) => {
    try {
      checkUserExist(formData, usersResponse.query.data as UserResData[])
      const releasedManNames = formData.released_man.map((man) => man.value)
      await kanbanCastingGroupRecordResponse.update.mutateAsync({
        data: {
          castingGroupName: formData.casting_group_name,
          groupName: formData.group_name,
          furnaceManName: formData.furnace_man,
          firstReleaseManName: releasedManNames[0],
          secondReleaseManName: releasedManNames[1] || '',
          thirdReleaseManName: releasedManNames[2] || '',
          coverManName: formData.covered_man,
        },
      })
      onClose()
    } catch (e) {
      if (e instanceof AxiosError) {
        const wrongManName = String(e.config?.url).split('/').splice(-1)
        snackBar.error({
          title:
            t(
              `api.errors.${e.response?.data.error[0].message}` as TranslationKeys,
            ) +
            '( ' +
            wrongManName +
            ' )',
        })
        return
      }
      if (e instanceof Error) {
        snackBar.error({
          title: t('api.errors.USER_NOT_FOUND') + '( ' + e.message + ' )',
        })
      }
    }
  }

  return (
    <>
      <Icon
        as={DescriptionOutlinedIcon}
        fontSize={22}
        cursor="pointer"
        opacity={0.54}
        onClick={onOpen}
      />
      {data && (
        <InAndExitModal
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={onConfirm}
          ref={ref}
          formId={formId}
          selectData={data}
        />
      )}
    </>
  )
}

const checkUserExist = (
  formData: CastingFormSchema,
  usersData: UserResData[],
) => {
  const {
    furnaceManInfo,
    coveredManInfo,
    releasedMansInfo,
    releasedMansErrors,
  } = getUserInfo(formData, usersData)
  if (!furnaceManInfo) {
    throw new Error(formData.furnace_man)
  }
  if (!coveredManInfo && formData.covered_man) {
    if (formData.covered_man) {
      throw new Error(formData.covered_man)
    }
  }
  if (releasedMansErrors.length) {
    throw new Error(releasedMansErrors.map((value) => value.name).join(' , '))
  }
  return { furnaceManInfo, coveredManInfo, releasedMansInfo }
}
