import { FC, useEffect, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { SearchInput } from '@/components/atoms/interactive/SearchInput'

import { useDraggableDashboard } from '@/hooks'

import { unproxify } from '@/utils/unProxy'

import { UnAssignCard } from './CardItem'

export const DraggableWorkSectionColumn: FC = () => {
  const { originColumns, addFilter } = useDraggableDashboard()
  // unproxify
  const [columns, setColumns] = useState<Record<string, TaskStatusType>>(
    unproxify(originColumns),
  )

  const { t } = useTranslation()

  const handleSearchInput = ({
    value,
    name,
  }: {
    value: string
    name: ColumnType
  }) => {
    addFilter({ name, value })
  }

  const column = columns['unAssign']
  const displayColumnItems = column.items.filter((item) =>
    item.content.includes(column.filter),
  )

  useEffect(() => {
    setColumns(unproxify(originColumns))
  }, [originColumns])

  return (
    <div
      className={`h-full rounded-[0.375rem] bg-white  shadow-md`}
      key={'unAssign'}
    >
      <Droppable droppableId={'unAssign'} key={'unAssign'}>
        {(provided) => {
          const isEmpty = column.items.length === 0
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`
                  relative h-full  overflow-scroll rounded-[0.375rem] bg-white p-[0.875rem]`}
            >
              <div
                className={`sticky top-0 z-[9999] mb-[1rem] border-b border-gray-300 bg-inherit pb-[1rem]`}
                style={{
                  boxShadow: `0 -20px 0 0px white`,
                }}
              >
                <div className="flex flex-wrap items-center justify-between gap-[1rem]">
                  <p className="text-[1.125rem] font-[600]">
                    {t('Total') + t('WorkStationSection')}
                  </p>

                  <SearchInput
                    value={''}
                    onChange={(value) =>
                      handleSearchInput({
                        value,
                        name: column.name as ColumnType,
                      })
                    }
                    placeholder={`${t('input.SearchThe', {
                      name: '',
                    })}`}
                    className="border-none outline-none"
                  />
                </div>
              </div>
              <div
                className={`flex flex-col gap-[1rem] rounded-[0.25rem] border p-[1rem]`}
              >
                {isEmpty ? (
                  <p className="text-center text-gray-400">
                    {t('NoData', { type: t('Station') })}
                  </p>
                ) : (
                  <>
                    {displayColumnItems.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <UnAssignCard
                                index={index}
                                id={item.id}
                                provided={provided}
                                snapshot={snapshot}
                                data={item.data as StationSectionData}
                                columnItem={item}
                              />
                            )
                          }}
                        </Draggable>
                      )
                    })}
                  </>
                )}
                {provided.placeholder}
              </div>
            </div>
          )
        }}
      </Droppable>
    </div>
  )
}
