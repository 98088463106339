import qs from 'qs'

import { secondsToMilliseconds } from './Date'

const API_BASE_URL = import.meta.env.VITE_API_URL
export const API = {
  baseURL: API_BASE_URL,

  routes: {
    user: {
      data: (param?: string | number) => `/user/${param ?? ''}`,
      account: (account: string) => `/user/account/${account}`,
      create: '/user',
      update: (param?: string | number) => `/user/${param ?? ''}`,
      delete: (param?: string | number) => `/user/${param ?? ''}`,
      list: (userQuery?: UserListQuery) => {
        const query = qs.stringify(userQuery)
        return `/users?${query ?? ''}`
      },
      login: '/login',
      register: '/users',
    },
    workOrder: {
      create: '/workOrder',
      delete: (param?: string | number) => `/workOrder/${param ?? ''}`,
      list: (userQuery?: WorkerOrderListQuery) => {
        const query = qs.stringify(userQuery)
        return `/workOrders?${query ?? ''}`
      },
      stationInfo: (stationInfoQuery?: WorkerOrderStationInfoQuery) => {
        const query = qs.stringify(stationInfoQuery)
        return `/workOrder/stations/info?${query ?? ''}`
      },
    },
    workOrderStation: {
      list: (stationQuery?: WorkerOrderStationsListQuery) => {
        const query = qs.stringify(stationQuery)
        return `/workOrders/stations?${query ?? ''}`
      },
      inOut: (id?: string | number) =>
        `/workOrders/station/inOutInfo/${id ?? ''}`,
    },
    productions: {
      list: (productionsQuery?: ProductionsListQuery) => {
        const query = qs.stringify(productionsQuery)
        return `/products?${query ?? ''}`
      },
      create: '/product',
      update: (param?: string | number) => `/product/${param ?? ''}`,
      delete: (param?: string | number) => `/product/${param ?? ''}`,
    },
    productStationBase: {
      list: '/products/stations/base',
      create: '/products/station/base',
      update: (params: string | number) =>
        `/products/station/base/${params ?? ''}`,
      delete: (params: string | number) =>
        `/products/station/base/${params ?? ''}`,
    },
    productStation: {
      list: (productStationQuery?: ProductStationQuery) => {
        const query = qs.stringify(productStationQuery)
        return `/products/stations?${query ?? ''}`
      },
      create: '/products/station/',
      update: (params: string | number) => `/products/station/${params ?? ''}`,
      delete: (params: string | number) => `/products/station/${params ?? ''}`,
    },
    productStationAutoStatus: {
      stations: '/products/stations/autoStatus',
      list: (params: number) => `/products/stations/autoStatus/${params ?? ''}`,
      create: '/products/stations/autoStatus',
      delete: (params: string | number) =>
        `/products/stations/autoStatus/${params ?? ''}`,
    },
    kanbanRecord: {
      update: (params: string | number) => `/kanbanRecord/${params ?? ''}`,
    },
    kanbanCastingGroupRecord: {
      update: (params: string | number) =>
        `/kanban/castingGroupRecord/${params}`,
    },
    kanbanDefectiveRecord: {
      list: (defectiveRecordQuery?: DefectiveRecordQuery) => {
        const query = qs.stringify(defectiveRecordQuery)
        return `/kanban/defectiveRecord?${query ?? ''}`
      },
    },
    role: {
      list: '/roles',
    },
    image: {
      update: (param: string | number, path: string) =>
        `/image/${path}/${param ?? ''}`,
    },
    castingGroup: {
      list: (castingGroupQuery?: CastingGroupQuery) => {
        const query = qs.stringify(castingGroupQuery)
        return `/castingGroup?${query ?? ''}`
      },
      create: '/castingGroup',
      update: (params: string | number) => `/castingGroup/${params}`,
      delete: (params: string | number) => `/castingGroup/${params}`,
    },
    inventory: {
      list: (inventoryRecordQuery?: InventoryRecordQuery) => {
        const query = qs.stringify(inventoryRecordQuery)
        return `/inventoryRecord?${query}`
      },
      delete: (params: string | number) => `/inventoryRecord/${params}`,
    },
    workHour: {
      list: (workerHourListQuery?: WorkerHourListQuery) => {
        const query = qs.stringify(workerHourListQuery)
        return `/workTime?${query ?? ''}`
      },
    },
    sft: {
      workOrder: (workOrdersQuery?: SFTWorkOrderQuery) => {
        const query = qs.stringify(workOrdersQuery)
        return `/sft/workOrders/?${query ?? ''}`
      },
    },
    workStationSection: {
      list: '/stationSection/productBase',
      create: '/stationSection',
      update: (id: number) => `/stationSection/${id}`,
      setStations: (id: number) => `/stationSection/${id}/productBase`,
      delete: (id) => `/stationSection/${id}`,
    },
    defective: {
      list: '/defectiveCategory',
    },
    defectType: {
      list: '/defectiveCategory/defect/type',
      create: '/defectiveCategory/defect/type',
      update: (code: string) => `/defectiveCategory/defect/type/${code}`,
      disable: (code: string) =>
        `/defectiveCategory/defect/type/${code}/disable`,
    },
    defectReason: {
      list: '/defectiveCategory/defect/reason',
      create: '/defectiveCategory/defect/reason',
      update: (code: string) => `/defectiveCategory/defect/reason/${code}`,
      disable: (code: string) =>
        `/defectiveCategory/defect/reason/${code}/disable`,
    },
  },
  settings: {
    intervals: {},
    requestTimeout: {},
    retries: {},
    // between ??s  data alway fresh after ??s data will put in the react query cache
    staleTimes: {
      workOrderData: secondsToMilliseconds(10),
      workOrderListData: secondsToMilliseconds(10),
      workOrderInAndOutData: secondsToMilliseconds(10),
      workOrderStationInfoData: secondsToMilliseconds(10),
      productions: secondsToMilliseconds(10),
    },
    // after ??s remove data in react query cache
    cacheTime: {
      workOrderData: secondsToMilliseconds(30),
      workOrderListData: secondsToMilliseconds(30),
      workOrderInAndOutData: secondsToMilliseconds(30),
      workOrderStationInfoData: secondsToMilliseconds(30),
      productions: secondsToMilliseconds(30),
    },
  },
  systems: [
    {
      name: 'SFT',
      value: import.meta.env.VITE_APP_API_SFT,
    },
    {
      name: 'Bonus',
      value: import.meta.env.VITE_APP_API_BONUS,
    },
  ],
}
