import { ElementRef, forwardRef, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Icon } from '@chakra-ui/react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { useContainerDimensions, useNavigation } from '@/hooks'
import { useWorkStationSection } from '@/hooks/useWorkStationSection'

import { ConfirmAndCancelButtonGroup } from '../organisms/form/ConfirmAndCancelButtonGroup'
import { DraggableDashboard } from '../organisms/workStationSection/DraggableDashboard'

export const WorkStationSectionDetailsPage = () => {
  const { stationSectionID } = useParams()
  const DraggableDashboardRef =
    useRef<ElementRef<typeof DraggableDashboard>>(null)

  const toolBarRef = useRef<HTMLDivElement>(null)
  const { height } = useContainerDimensions(toolBarRef)
  const containHeight = useMemo(
    () => window.innerHeight * 0.86 - height,
    [height],
  )

  const WorkStationSectionModel = useWorkStationSection()
  const workStationSectionList = useMemo(() => {
    return WorkStationSectionModel.query.data
  }, [WorkStationSectionModel.query])
  const currentStationSection = (stationSectionID &&
    workStationSectionList.find(
      ({ stationSectionId }) => stationSectionId === +stationSectionID,
    )) || {
    stationSectionProductBaseArr: undefined,
  }
  const handleConfirm = () => {
    const columns = DraggableDashboardRef.current?.getColumns()
    const data =
      columns?.map((item, index) => ({
        productStationBaseId: item.data.id,
        sequence: index + 1,
      })) ?? []
    if (stationSectionID) {
      WorkStationSectionModel.setStations.mutateAsync({
        id: +stationSectionID,
        data,
      })
    }
  }

  return (
    <Box margin={'1.5rem'} display={'flex'} flexDir={'column'} gap={'1.5rem'}>
      <ToolBar
        currentStationSection={currentStationSection}
        ref={toolBarRef}
        handleConfirm={handleConfirm}
      />
      <DraggableDashboard
        ref={DraggableDashboardRef}
        containHeight={containHeight}
        productStations={currentStationSection.stationSectionProductBaseArr}
      ></DraggableDashboard>
    </Box>
  )
}

const ToolBar = forwardRef<
  HTMLDivElement,
  {
    currentStationSection: Partial<StationSectionData>
    handleConfirm: () => void
  }
>(({ handleConfirm, currentStationSection }, ref) => {
  const navigation = useNavigation()

  return (
    <Box
      className=" flex  items-center justify-between rounded-[0.375rem] bg-white p-[1rem] shadow-md"
      ref={ref}
    >
      <div className="flex items-center justify-start gap-[1.5rem]">
        <Icon
          as={ChevronLeftIcon}
          cursor="pointer"
          fontSize="2rem"
          onClick={() => navigation.goBack()}
        ></Icon>
        <p className="whitespace-nowrap text-[1.25rem] font-[600]">
          {currentStationSection.stationSectionCode}
        </p>
      </div>
      <ConfirmAndCancelButtonGroup
        onClose={() => navigation.goBack()}
        onConfirm={handleConfirm}
      />
    </Box>
  )
})
