import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { As, Icon, IconProps, Text, VStack } from '@chakra-ui/react'

import { Collapse } from '@/components/layouts/Collapse/Collapse'

import { useLeftDrawer } from '@/hooks'

import { TranslationKeys } from '@/_types_/i18next'

type SideBarListElement = {
  titleKey: TranslationKeys
  to: string
  iconType: As
} & IconProps

export type SideBarElement = {
  featureIcon: As
  titleKey: TranslationKeys
  open?: boolean
  lists: SideBarListElement[]
}
export interface SideBarProps {
  lists: SideBarElement[]
}

export const SideBar: FC<SideBarProps> = ({ lists }) => {
  const { isHidden } = useLeftDrawer()

  return (
    <div
      className={`
      fixed left-0 top-[3.75rem] z-[99999] h-[calc(100vh_-_60px)] overflow-auto overflow-x-hidden  bg-white shadow-md
      ${!isHidden ? 'w-[18.75rem]' : 'w-14'}
      `}
      style={{ transition: 'all 0.2s ease-in-out' }}
    >
      <div>
        {lists.map(({ open, featureIcon, lists, titleKey }, index) => (
          <SideBarItem
            titleKey={titleKey}
            key={index}
            open={open}
            featureIcon={featureIcon}
            lists={lists}
          />
        ))}
      </div>
    </div>
  )
}

type SideBarItemProps = SideBarElement
const SideBarItem = ({
  featureIcon,
  lists,
  open,
  titleKey,
}: SideBarItemProps) => {
  const { hide } = useLeftDrawer()
  const { t } = useTranslation()
  const [show, setShow] = useState(open)
  const handleToggle = () => setShow(!show)
  const navigate = useNavigate()
  const handleRedirect = (to: string) => {
    navigate(to)
    hide()
  }

  return (
    <div>
      <div
        className="flex w-full cursor-pointer items-center justify-start  gap-[1rem] bg-gray-100 px-5 py-4"
        onClick={handleToggle}
      >
        <Icon as={featureIcon} boxSize={6} color={'gray.500'} size={'lg'} />
        <Text fontSize={'1rem'} whiteSpace="nowrap">
          {t(titleKey)}
        </Text>
      </div>

      <Collapse in={show}>
        <VStack>
          {lists.map((listChild, index) => {
            const { iconType, to, titleKey, ...rest } = listChild
            return (
              <div
                className="flex w-full  cursor-pointer items-center  justify-start gap-[1rem] px-5 py-4 hover:bg-gray-200"
                onClick={() => handleRedirect(to)}
                key={index}
              >
                <Icon
                  as={iconType}
                  boxSize={6}
                  color={'gray.500'}
                  size={'lg'}
                  {...rest}
                />
                <Text className="whitespace-nowrap" fontSize={'1rem'}>
                  {t(titleKey)}
                </Text>
              </div>
            )
          })}
        </VStack>
      </Collapse>
    </div>
  )
}
