import { CircleIcon } from '@/components/atoms/Icon/CircleIcon'
import { I18nText } from '@/components/atoms/textual/I18nText'
import { ActionBTN, PlayBTN } from '@/components/pages'

import {
  WorkerOrderStationTableColumnHelper,
  WorkerOrderTableColumnHelper,
} from '@/helpers/tableColumn'
import { getWorkOrderStatusInfo } from '@/helpers/workOrderStatusInfo'

import { formatDateWithTime, transformTime } from '../Date'
import {
  WorkerOrderStatusTableColumns,
  WorkerOrderTableColumns,
} from '../UserData'

interface WorkOrderColumnsProps {
  handleAction: (
    type: ModelOpenType,
    data?: WorkOrderStationsInfoQuerySelectResponseData,
  ) => void
}

export const useWorkerOrderTableColumns = ({
  handleAction,
}: WorkOrderColumnsProps) =>
  [
    WorkerOrderTableColumnHelper.accessor('status', {
      header: () => <I18nText id={'Status'} />,
      cell: (info) => {
        const StatusCode = info.getValue() as WorkerOrderStatus

        return (
          <CircleIcon
            color={getWorkOrderStatusInfo(StatusCode)?.color ?? 'white'}
            boxSize={7}
          />
        )
      },
    }),
    WorkerOrderTableColumnHelper.accessor('code', {
      header: () => <I18nText id={'workOrder.kanbans.WorkOrderId'} />,
    }),
    WorkerOrderTableColumnHelper.accessor('orderId', {
      header: () => <I18nText id={'workOrder.kanbans.OrderId'} />,
    }),
    WorkerOrderTableColumnHelper.accessor('orderSequence', {
      header: () => <I18nText id={'workOrder.kanbans.OrderSequence'} />,
    }),
    WorkerOrderTableColumnHelper.accessor('customerShortName', {
      header: () => <I18nText id={'workOrder.kanbans.CustomerShortname'} />,
    }),
    WorkerOrderTableColumnHelper.accessor('productName', {
      header: () => <I18nText id={'workOrder.kanbans.ProductName'} />,
    }),
    WorkerOrderTableColumnHelper.accessor('operationSequence', {
      header: () => <I18nText id={'workOrder.kanbans.OperationSequence'} />,
    }),
    WorkerOrderTableColumnHelper.accessor('customerCodeName', {
      header: () => <I18nText id={'workOrder.kanbans.OperationId'} />,
    }),
    WorkerOrderTableColumnHelper.accessor('quantity', {
      header: () => <I18nText id={'workOrder.kanbans.Quantity'} />,
    }),
    WorkerOrderTableColumnHelper.accessor('createdAt', {
      header: () => <I18nText id={'workOrder.kanbans.CreateTime'} />,
      cell: ({ getValue }) => formatDateWithTime(getValue()),
    }),
    // WorkerOrderTableColumnHelper.display({
    //   id: 'storage',
    //   header: () => <I18nText id={'workOrder.kanbans.Storage'} />,
    //   cell: (info) => {
    //     const { id } = info.row
    //     const order = info.row.original

    //     return <StorageBTN stock={1} />
    //   },
    // }),
    WorkerOrderTableColumnHelper.display({
      id: 'pause',
      header: () => <I18nText id={'workOrder.kanbans.Pause'} />,
      cell: (info) => {
        const order = info.row.original

        return <PlayBTN pause={1} />
      },
    }),
    WorkerOrderTableColumnHelper.display({
      id: 'delete',
      header: () => <I18nText id="workOrder.kanbans.Delete" />,
      cell: (info) => {
        const rowData = info.row.original
        const onClick = (type: ModelOpenType) => {
          handleAction(type, rowData)
        }
        return rowData?.status === 'init' && <ActionBTN onClick={onClick} />
      },
    }),
  ] as WorkerOrderTableColumns

const WorkerOrderStationColumnWidth = 120
export const useWorkerOrderStatusTableColumns: (
  language: string,
) => WorkerOrderStatusTableColumns = (language) => [
  WorkerOrderStationTableColumnHelper.accessor(
    language === 'vi' ? 'nameVi' : 'nameZhTW',
    {
      header: () => <I18nText id={'Station'} />,
      size: WorkerOrderStationColumnWidth,
    },
  ),
  WorkerOrderStationTableColumnHelper.accessor('checkInQuantity', {
    header: () => <I18nText id={'workOrder.station.CheckInQuantity'} />,
    size: WorkerOrderStationColumnWidth,
  }),
  WorkerOrderStationTableColumnHelper.accessor('checkInProgressPercentage', {
    header: () => <I18nText id={'workOrder.station.CheckInProgressPercent'} />,
    cell: (info) => info.getValue(),
    size: WorkerOrderStationColumnWidth,
  }),
  WorkerOrderStationTableColumnHelper.accessor('checkOutQuantity', {
    header: () => <I18nText id={'workOrder.station.CheckoutQuantity'} />,
    size: WorkerOrderStationColumnWidth,
  }),
  WorkerOrderStationTableColumnHelper.accessor('checkOutProgressPercentage', {
    header: () => <I18nText id={'workOrder.station.CheckoutProgressPercent'} />,
    cell: (info) => info.getValue(),
    size: WorkerOrderStationColumnWidth,
  }),

  WorkerOrderStationTableColumnHelper.display({
    id: 'totalExceptDefect',
    header: () => <I18nText id={'workOrder.station.ExceptDefect'} />,
    cell: ({ row }) => {
      const { defaultTtlExceptDefect } = row.original
      return defaultTtlExceptDefect ?? '-'
    },
    size: WorkerOrderStationColumnWidth,
  }),

  WorkerOrderStationTableColumnHelper.accessor('totalReworkQuantity', {
    header: (props) => {
      return (
        <p className="text-red-500">
          <I18nText id={'workOrder.station.ReworkQuantity'} />
        </p>
      )
    },
    cell: ({ getValue }) => <div className="text-red-500">{getValue()}</div>,
    size: WorkerOrderStationColumnWidth,
  }),
  WorkerOrderStationTableColumnHelper.accessor('reworkTtlExceptDefect', {
    header: () => (
      <I18nText
        id={'workOrder.station.ReworkExceptDefect'}
        className="text-red-500"
      />
    ),
    size: WorkerOrderStationColumnWidth,
    cell: ({ getValue }) => <div className="text-red-500">{getValue()}</div>,
  }),

  WorkerOrderStationTableColumnHelper.accessor('totalMachineTime', {
    header: () => <I18nText id={'workOrder.station.MachineTime'} />,
    cell: (info) => (info.getValue() ? transformTime(info.getValue()) : '-'),
    size: WorkerOrderStationColumnWidth,
  }),
  WorkerOrderStationTableColumnHelper.accessor('totalManTime', {
    header: () => <I18nText id={'workOrder.station.AllocatedWorkTime'} />,
    size: WorkerOrderStationColumnWidth,
    cell: (info) => (info.getValue() ? transformTime(info.getValue()) : '-'),
  }),
  WorkerOrderStationTableColumnHelper.accessor('firstCheckInTime', {
    header: () => <I18nText id={'workOrder.station.CheckinTime'} />,
    cell: (info) => {
      const value = info.getValue()
      return value ? formatDateWithTime(value) : '-'
    },
  }),
  WorkerOrderStationTableColumnHelper.accessor('lastCheckOutTime', {
    header: () => <I18nText id={'workOrder.station.CheckoutTime'} />,
    cell: (info) => {
      const value = info.getValue()
      return value ? formatDateWithTime(value) : '-'
    },
    size: 200,
  }),
]
