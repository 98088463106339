import { TranslationKeys } from '@/_types_/i18next'

export type WorkerStatusInfo = {
  color: string
  i18key: TranslationKeys
}
export type WorkerStatus = {
  [key in WorkerOrderStatusList]: WorkerStatusInfo
}
export type WorkerStatusList = {
  [key in WorkerOrderStatus]: WorkerStatusInfo
}
export const workerStatus = {
  init: {
    color: '#ccc',
    i18key: 'Init',
  },
  running: {
    color: '#008001',
    i18key: 'Running',
  },
  warehouseStock: {
    color: '#ffa500',
    i18key: 'WarehouseStock',
  },
  pause: {
    color: '#c53030',
    i18key: 'Pause',
  },
  disable: {
    color: '#000000',
    i18key: 'Disable',
  },
} as WorkerStatus

export const workerStatusList = {
  init: {
    color: '#ccc',
    i18key: 'Init',
  },
  checkIn: {
    color: '#008001',
    i18key: 'CheckIn',
  },
  checkOut: {
    color: '#ffa500',
    i18key: 'CheckOut',
  },
  stock: {
    color: '#c53030',
    i18key: 'Stock',
  },
  stockCancel: {
    color: '#000000',
    i18key: 'Failed',
  },
} as WorkerStatusList
