import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { useDebounce } from '@/hooks'

interface DebouncedInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  debounce?: number
  value: string | number
  onChange: (value: string) => void
}
export const DebounceInput = forwardRef<
  InputRefImperativeHandleProps,
  DebouncedInputProps
>(({ debounce, value: initialValue, onChange, ...rest }, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [value, setValue] = useState(initialValue)
  const deferValue = useDebounce({ value, delay: debounce })
  useEffect(() => {
    if (deferValue === undefined) return
    onChange(deferValue as string)
  }, [deferValue])

  const clearInput = () => {
    setValue('')
  }
  const changeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }
  const focusInput = () => {
    inputRef.current?.focus()
  }

  useImperativeHandle(ref, () => {
    return {
      changeInput,
      clearInput,
      focusInput,
      inputValue: value,
    }
  })

  return <input ref={inputRef} value={value} onChange={changeInput} {...rest} />
})

DebounceInput.displayName = 'DebounceInput'
