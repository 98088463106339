import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import { Box, Button, Icon, useMediaQuery } from '@chakra-ui/react'

import { BaseTable, FormDataType } from '@/components/organisms'
import { UserUpdateModal } from '@/components/templates'

import { useSnackbar, useUsers } from '@/hooks'
import { useRoleList } from '@/hooks/useRole'

import {
  toggleActiveErrorI18nKey,
  toggleActiveSuccessI18nKey,
} from '@/utils/i18n'
import { userTableColumns } from '@/utils/tableColumn'

import { SearchInput } from '../atoms/interactive/SearchInput'
import { DeleteModal } from '../templates/modal/DeleteModal'

export const UsersPage = () => {
  const [isOpen, setIsOpen] = useState<ModalOpenType>('')
  const [isUserActiveUpdate, setIsUserActiveUpdate] = useState<boolean>()
  const [selectedUser, setSelectedUser] = useState<UserResData>()
  const [isLargeThan1280] = useMediaQuery('(min-width: 1920px)')
  const roleList = useRoleList()
  const usersResponse = useUsers({ attachImage: true }, isUserActiveUpdate)
  const snackBar = useSnackbar()
  const { t } = useTranslation()

  const handleAction = (type: ModalOpenType, data?: UserResData) => {
    setSelectedUser(['update', 'delete'].includes(type) ? data : undefined)
    setIsOpen(type)
  }

  const handleToggleUserActive = async (data: UserResData) => {
    setIsUserActiveUpdate(true)
    if (!Object.keys(data).length) return
    await usersResponse.update.mutateAsync(
      {
        data: {
          ...data,
        },
        userId: data.id.toString(),
      },
      {
        onSuccess: () => {
          snackBar.info({
            title: t(toggleActiveSuccessI18nKey(data.active), {
              type: t('People'),
            }),
          })
        },
        onError: () => {
          snackBar.error({
            title: t(toggleActiveErrorI18nKey(data.active), {
              type: t('People'),
            }),
          })
        },
      },
    )
  }
  const onSubmit = async (data: FormDataType) => {
    setIsUserActiveUpdate(false)
    if (!data.email) {
      delete data.email
    }

    if (data.photo?.length === 0) {
      delete data.photo
    }

    if (isOpen === 'create') {
      await usersResponse.create.mutateAsync({
        data: {
          ...data,
          groupId: 1,
          roleId: Number(data.roleId),
        },
      })
    } else if (isOpen === 'update' && selectedUser) {
      await usersResponse.update.mutateAsync({
        data: {
          ...data,
          roleId: Number(data.roleId),
        },
        userId: selectedUser?.id.toString(),
      })
    }
  }
  const column = userTableColumns({
    handleAction,
    roleList,
    handleToggleUserActive,
  })
  const handleSearch = (value: string) => {
    usersResponse.setFilter(value)
  }
  const handleDeleteUser = async () => {
    if (!selectedUser) return
    usersResponse.delete.mutateAsync({ userId: selectedUser?.id })
    setIsOpen('')
  }
  return (
    <Box className="m-4 h-[calc(100vh_-_90px)] rounded-md bg-white shadow">
      <Box
        className="flex flex-row gap-[0.75rem]"
        p="4"
        justifyContent="flex-end"
        alignItems="center"
      >
        <SearchInput
          onChange={handleSearch}
          value={usersResponse.filterValue}
          placeholder={t('Search', {
            what: t('user.Name') + t('Or') + t('user.Account'),
          })}
          name="search"
          type="text"
          className="border-none outline-none placeholder:text-gray-500 "
        />
        <Button
          colorScheme="blue"
          h="38px"
          leftIcon={<Icon as={MdAdd} fontSize={20} />}
          onClick={() => handleAction('create')}
        >
          {t('New', { what: '' })}
        </Button>
      </Box>
      <Box p="4">
        {usersResponse.query.data && (
          <BaseTable
            columns={column}
            data={usersResponse.query.data}
            pageSize={isLargeThan1280 ? 10 : 8}
          />
        )}
      </Box>
      <UserUpdateModal
        isOpen={isOpen === 'create' || isOpen === 'update'}
        userData={selectedUser}
        type={isOpen}
        onSubmit={onSubmit}
        onClose={() => setIsOpen('')}
      />
      {selectedUser && (
        <DeleteModal
          isOpen={isOpen === 'delete'}
          onClose={() => setIsOpen('')}
          onConfirm={handleDeleteUser}
          name={selectedUser.name}
          type={t('People')}
        />
      )}
    </Box>
  )
}
