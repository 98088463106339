import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { SearchInput } from '@/components/atoms/interactive/SearchInput'

import { useProductStationBase } from '@/hooks'

import { CardTypes } from './CardItem'

export interface DraggableDashboardProps {
  containHeight: number
  productStations?: ProductStationResponse[]
}

const hasDestColumn = (
  result: Record<string, any>,
): result is MoveItemReturnType & {
  destColumn: TaskStatusType
  destItems: TaskType[]
} => {
  return result.destColumn !== undefined && result.destItems !== undefined
}
const moveItem = (
  columns: Record<string, TaskStatusType>,
  source: DraggableLocation,
  destination: DraggableLocation,
) => {
  const isSameColumn = source.droppableId === destination.droppableId
  const sourceColumn = columns[source.droppableId]
  const sourceItems = [...sourceColumn.items]
  const destColumn = columns[destination.droppableId]
  const destItems = [...destColumn.items]
  const [removed] = sourceItems.splice(source.index, 1)
  if (isSameColumn) {
    sourceItems.splice(destination.index, 0, removed)

    return {
      sourceColumn,
      sourceItems,
    }
  } else {
    const clone = JSON.parse(JSON.stringify(removed))
    clone.id = `${destination.droppableId}-${Date.now()}`
    destItems.splice(destination.index, 0, clone)
    return {
      sourceColumn,
      sourceItems,
      destColumn,
      destItems,
    }
  }
}

const _DraggableDashboard: ForwardRefRenderFunction<
  ColumnHandle,
  DraggableDashboardProps
> = ({ containHeight, productStations }, ref) => {
  const [draggingType, setDraggingType] = useState('')

  const ProductStationBaseModel = useProductStationBase()
  const productStationBaseList = useMemo(
    () => ProductStationBaseModel.query.data,
    [ProductStationBaseModel.query],
  )

  const sortColumn = (sourceType, sourceColumn, sourceItems) => {
    setColumns({
      ...columns,
      [sourceType]: {
        ...sourceColumn,
        items: sourceItems,
      },
    })
  }

  const changeColumn = (
    sourceType,
    destinationType,
    sourceColumn,
    sourceItems,
    destColumn,
    destItems,
  ) => {
    setColumns({
      ...columns,
      [sourceType]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destinationType]: {
        ...destColumn,
        items: destItems,
      },
    })
  }

  const [columns, setColumns] = useState<Record<string, TaskStatusType>>({})
  const [filter, setFilter] = useState({
    unAssign: '',
    assign: '',
  })

  const displayColumns = useMemo(() => {
    return {
      unAssign: {
        name: 'unAssign',
        items:
          columns.unAssign?.items.filter((item) => {
            const columnToCheck = ['code', 'nameVi', 'nameZhTW']
            return columnToCheck.some((key) =>
              filter.unAssign === ''
                ? true
                : (item?.data as Record<string, any>)[key]?.includes(
                    filter.unAssign,
                  ),
            )
          }) ?? [],
        filter: '',
      },
      assign: {
        name: 'assign',
        items:
          columns.assign?.items.filter((item) => {
            const columnToCheck = ['code', 'nameVi', 'nameZhTW']
            return columnToCheck.some((key) =>
              filter.assign === ''
                ? true
                : (item?.data as StationSectionProductBase)[key]?.includes(
                    filter.assign,
                  ),
            )
          }) ?? [],
        filter: '',
      },
    }
  }, [columns, filter])

  const { t, i18n } = useTranslation()
  const createInitialColumns = () => {
    return {
      unAssign: {
        name: 'unAssign',
        items:
          productStationBaseList?.map((item) => ({
            id: `unAssign-${item.id}`,
            content: i18n.language === 'vi' ? item.nameVi : item.nameZhTW,
            data: item,
          })) ?? [],
        filter: '',
      },
      assign: {
        name: 'assign',
        items:
          productStations?.map((item) => ({
            id: `assign-${item.sequence}`,
            content: i18n.language === 'vi' ? item.nameVi : item.nameZhTW,
            data: item,
          })) ?? [],
        filter: '',
      },
    }
  }

  const onDragEnd = async (
    result: DropResult,
    displayColumns: Record<string, TaskStatusType>,
    sortColumn: (
      sourceType: string,
      sourceColumn: TaskStatusType,
      sourceItems: TaskType[],
    ) => void,
    changeColumn: (
      sourceType: string,
      destinationType: string,
      sourceColumn: TaskStatusType,
      sourceItems: TaskType[],
      destColumn: TaskStatusType,
      destItems: TaskType[],
    ) => void,
  ) => {
    if (!result.destination) return

    const { source, destination } = result

    //moveItem is to change column card destination before add to store
    const results = moveItem(displayColumns, source, destination)

    if (
      source.droppableId === 'unAssign' &&
      destination.droppableId === 'unAssign'
    ) {
      return // do not sort unAssign column
    } else if (!hasDestColumn(results)) {
      // switch station order
      sortColumn(source.droppableId, results.sourceColumn, results.sourceItems)
    } else if (
      source.droppableId === 'assign' &&
      destination.droppableId === 'unAssign'
    ) {
      // remove from assign column
      const unAssignedItems = [...columns.unAssign.items]
      changeColumn(
        source.droppableId,
        destination.droppableId,
        results.sourceColumn,
        results.sourceItems,
        results.destColumn,
        unAssignedItems,
      )
      return
    } else {
      // add to assign column
      const unAssignedItems = [...displayColumns[source.droppableId].items]
      changeColumn(
        source.droppableId,
        destination.droppableId,
        results.sourceColumn,
        unAssignedItems,
        results.destColumn,
        results.destItems,
      )
    }
  }

  const handleSearchInput = ({
    value,
    name,
  }: {
    value: string
    name: ColumnType
  }) => {
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  useEffect(() => {
    setColumns(createInitialColumns())
  }, [productStations, productStationBaseList, i18n.language])

  const isDropDisabled = (columnId) => {
    return columnId === 'unAssign' && draggingType === 'unAssign'
  }

  useImperativeHandle(ref, () => {
    return {
      getColumns: () =>
        columns.assign.items as (TaskType & { data: ProductStationResponse })[],
    }
  })

  return (
    <div className={`relative grid w-full grid-cols-10 gap-[1.5rem] `}>
      <DragDropContext
        onDragStart={(result) => setDraggingType(result.source.droppableId)}
        onDragEnd={(result) => {
          onDragEnd(result, displayColumns, sortColumn, changeColumn)
          setDraggingType('')
        }}
      >
        {Object.entries(displayColumns).map(([columnId, column]) => {
          const isUnAssignColumn = column.name === 'unAssign'
          return (
            <div
              style={{
                height: containHeight,
              }}
              className="col-span-5  rounded-[0.375rem] bg-white  shadow-md"
              key={columnId}
            >
              <Droppable
                isDropDisabled={isDropDisabled(columnId)}
                droppableId={columnId}
                key={columnId}
              >
                {(provided) => {
                  const isEmpty = column.items.length === 0
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`
                      ${isUnAssignColumn ? 'bg-white' : 'bg-[#F7FAFC]'}  
                       relative  h-full overflow-scroll rounded-[0.375rem] p-[0.875rem]`}
                    >
                      <div
                        className={`sticky top-0 z-[9999] mb-[1rem] border-b border-gray-300 bg-inherit pb-[1rem]`}
                        style={{
                          boxShadow: `0 -20px 0 0px ${
                            isUnAssignColumn ? 'white' : '#F7FAFC'
                          }`,
                        }}
                      >
                        <div className="flex flex-wrap items-center justify-between gap-[1rem]">
                          <p className="text-[1.125rem] font-[600]">
                            {t('_Station', {
                              _: t(isUnAssignColumn ? 'Total' : 'Current'),
                            })}
                          </p>
                          {isUnAssignColumn && (
                            <SearchInput
                              value={''}
                              onChange={(value) =>
                                handleSearchInput({
                                  value,
                                  name: column.name as ColumnType,
                                })
                              }
                              placeholder={`${t('input.SearchThe', {
                                name: '',
                              })}`}
                              className="border-none outline-none"
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className={`flex flex-col gap-[1rem]  ${
                          isUnAssignColumn &&
                          'rounded-[0.25rem] border p-[1rem]'
                        }`}
                      >
                        {isEmpty ? (
                          <p className="text-center text-gray-400">
                            {t('NoData', { type: t('Station') })}
                          </p>
                        ) : (
                          <>
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    const Card =
                                      CardTypes[columnId as ColumnType]
                                    return (
                                      <Card
                                        index={index}
                                        id={item.id}
                                        provided={provided}
                                        snapshot={snapshot}
                                        data={
                                          item.data as ProductStationResponse
                                        }
                                        columnItem={item}
                                      />
                                    )
                                  }}
                                </Draggable>
                              )
                            })}
                          </>
                        )}
                        {provided.placeholder}
                      </div>
                    </div>
                  )
                }}
              </Droppable>
            </div>
          )
        })}
      </DragDropContext>
    </div>
  )
}

export const DraggableDashboard = forwardRef(_DraggableDashboard)
