import { z } from 'zod'

import i18n from '@/locale/i18n'

export const productFormSchema = z.object({
  photo: z
    .custom<FileList>()
    .transform((file) => {
      if (!file.length) return
      return file.item(0)
    })
    .refine((file) => !file || file.size <= 10 * 1024 * 1024, {
      message: i18n.t('error.shouldBeSmallerThen', { size: '10MB' }),
    })
    .refine((file) => !file || file.type.startsWith('image'), {
      message: i18n.t('error.WrongFileType'),
    })
    .or(z.string())
    .optional()
    .nullable(),
  code: z.string().min(1, {
    message: i18n.t('error.isRequired', {
      name: i18n.t('Product_', { _: i18n.t('ID') }),
    }),
  }),
  name: z.string().min(1, {
    message: i18n.t('error.isRequired', {
      name: i18n.t('Product_', { _: i18n.t('Name') }),
    }),
  }),
  defaultBindQuantity: z
    .number({
      invalid_type_error: i18n.t('error.isRequired', {
        name: i18n.t('product.Assemble'),
      }),
    })
    .gte(1, {
      message: i18n.t('error.ShouldBeLargerThen') + '1',
    }),
  customerName: z.string().min(1, {
    message: i18n.t('error.isRequired', {
      name: i18n.t('Customer_', { _: i18n.t('ID') }),
    }),
  }),
  version: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('product.CP_version') }),
  }),
  remark: z.string().optional(),
})
export type ProductFormSchema = z.infer<typeof productFormSchema>
