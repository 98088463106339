import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

import { API } from '@/utils/API'

import { autoStatusKeys } from '@/constants/queryKeyFactory'

import { useAPI } from './useAPI'

const apiRoute = API.routes.productStationAutoStatus

export const useAutoStatusStations = (
  params: GetProductStationAutoStatusParams,
) => {
  const { request } = useAPI()
  const { i18n } = useTranslation()

  const getProductStationsAutoStatus = async () => {
    const { data } = await request<GetProductStationsAutoStatusResponse[]>(
      'get',
      apiRoute.stations,
      { params },
    )
    return data
  }

  const productStationAutoStatusQuery = useQuery({
    queryKey: autoStatusKeys.stations(params),
    queryFn: getProductStationsAutoStatus,
    enabled: !!params.productStationId,
    refetchOnWindowFocus: false,
  })

  const autoStatusOptions = useMemo(() => {
    if (!productStationAutoStatusQuery.data) return []
    return productStationAutoStatusQuery.data.map((item) => ({
      label: i18n.language === 'vi' ? item.nameVi : item.nameZhTW,
      value: item.id,
    }))
  }, [productStationAutoStatusQuery.data])

  return {
    query: productStationAutoStatusQuery,
    options: autoStatusOptions,
  }
}
