import React, { ChangeEvent } from 'react'
import {
  FieldError,
  RegisterOptions,
  UseFormRegisterReturn,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdImage } from 'react-icons/md'
import { Box, Text } from '@chakra-ui/react'

interface ImageUploadProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  error?: FieldError
  register: (name: 'photo', options?: RegisterOptions) => UseFormRegisterReturn
}
export const ImageUpload = ({
  onChange,
  register,
  error,
}: ImageUploadProps) => {
  const { t } = useTranslation()
  return (
    <label style={{ width: 150, cursor: 'pointer' }}>
      <Box
        className="flex flex-col items-center justify-center rounded border-2 border-dashed"
        boxSize={150}
      >
        <MdImage color="#ddd" size={26} />
        <Text fontSize="sm" color="#ddd">
          {t('UploadThe', { what: t('user.Avatar', '') })}
        </Text>
        <input
          {...register('photo')}
          style={{ display: 'none' }}
          type="file"
          // accept="image/*"
          multiple
          onChange={onChange}
        />
      </Box>
      {error && (
        <div role="alert" className="text-red-500">
          {error.message}
        </div>
      )}
    </label>
  )
}
