import { useEffect, useState } from 'react'

export interface TablePaginationProps {
  getPageCount: () => number
  pageIndex: number
  setPageIndex: (pageIndex: number) => void
}

export const TablePagination = ({
  getPageCount,
  pageIndex,
  setPageIndex,
}: TablePaginationProps) => {
  const [visiblePages, setVisiblePages] = useState<number[]>([])

  useEffect(() => {
    updateVisiblePages()
  }, [pageIndex, getPageCount()])

  const updateVisiblePages = () => {
    if (getPageCount() <= 5) {
      // 如果頁數小於等於 5，顯示全部頁碼
      setVisiblePages(Array.from({ length: getPageCount() }, (_, i) => i + 1))
    } else {
      // 頁數大於 5，根據當前頁碼顯示頁碼
      if (pageIndex <= 3) {
        // 顯示第 1 到 5 頁
        setVisiblePages([1, 2, 3, 4, 5])
      } else if (pageIndex >= getPageCount() - 2) {
        // 顯示倒數第 5 到最後一頁
        setVisiblePages(
          Array.from({ length: 5 }, (_, i) => getPageCount() - 4 + i),
        )
      } else {
        // 顯示當前頁碼的前後各 2 頁
        setVisiblePages([
          pageIndex - 2,
          pageIndex - 1,
          pageIndex,
          pageIndex + 1,
          pageIndex + 2,
        ])
      }
    }
  }
  if (!visiblePages.length) return null
  return (
    <>
      <button
        className="rounded-l-md border-y border-l px-3 py-1"
        onClick={() => (pageIndex > 0 ? setPageIndex(pageIndex - 1) : 0)}
      >
        {'＜'}
      </button>
      {visiblePages.length ? (
        <>
          {visiblePages[0] !== 1 && (
            <>
              <button
                className={`border px-3 py-1 ${
                  pageIndex === 0 ? 'bg-blue-100' : ''
                }`}
                onClick={() => setPageIndex(0)}
              >
                1
              </button>
              <button className="border-y border-r px-3 py-1">...</button>
            </>
          )}
          {visiblePages.map((page) => (
            <button
              key={page}
              className={`border-y border-r px-3 py-1 ${
                page === pageIndex + 1 ? 'bg-blue-100' : ''
              }`}
              onClick={() => setPageIndex(page - 1)}
            >
              {page}
            </button>
          ))}
          {visiblePages[visiblePages.length - 1] !== getPageCount() && (
            <>
              <button className="border-y px-3 py-1">...</button>
              <button
                className={`border px-3 py-1 ${
                  pageIndex === getPageCount() - 1 ? 'bg-blue-100' : ''
                }`}
                onClick={() => setPageIndex(getPageCount() - 1)}
              >
                {getPageCount()}
              </button>
            </>
          )}
        </>
      ) : (
        <button
          className={`border px-3 py-1 ${pageIndex === 0 ? 'bg-blue-100' : ''}`}
          onClick={() => setPageIndex(0)}
        >
          1
        </button>
      )}
      <button
        className="rounded-r-md border-y border-r px-3 py-1"
        onClick={() =>
          pageIndex < getPageCount() - 1
            ? setPageIndex(pageIndex + 1)
            : getPageCount() - 1
        }
      >
        {'＞'}
      </button>
    </>
  )
}
