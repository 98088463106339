import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { API } from '@/utils/API'

import {
  kanbanCastingGroupRecordKeys,
  kanbanRecordKeys,
  workOrderStationKeys,
} from '@/constants/queryKeyFactory'

import { TranslationKeys } from '@/_types_/i18next'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useKanbanRecord = ({
  workOderStationId,
}: {
  workOderStationId: string
}) => {
  const { request } = useAPI()
  const { t } = useTranslation()
  const snackBar = useSnackbar()
  const queryClient = useQueryClient()
  const apiRoute = API.routes.kanbanRecord

  const updateKanbanRecordData: MutationFunction<
    void,
    { kanbanRecordId: string; data: UpdateKanbanRecordRequest }
  > = async ({ kanbanRecordId, data }) => {
    await request('patch', apiRoute.update(kanbanRecordId), { data })
  }

  const updateKanbanRecordQuery = useMutation({
    mutationKey: kanbanRecordKeys.update(workOderStationId),
    mutationFn: updateKanbanRecordData,
    onSuccess: async () => {
      snackBar.success({
        title: t('toast.edit.success', { type: t('Kanban') }),
      })
    },
    onError: async (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.edit.error', { type: t('Kanban') }) +
            t(`api.errors.${error.response?.data.message}` as TranslationKeys),
        })
      }
    },
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: workOrderStationKeys.inOut(workOderStationId),
      })
    },
  })

  return {
    update: updateKanbanRecordQuery,
  }
}

export const useKanbanCastingGroupRecord = ({
  id,
  workOderStationId,
}: {
  id: string
  workOderStationId: string
}) => {
  const { request } = useAPI()
  const { t } = useTranslation()
  const snackBar = useSnackbar()
  const queryClient = useQueryClient()
  const apiRoute = API.routes.kanbanCastingGroupRecord

  const updateKanbanRecordData: MutationFunction<
    void,
    { data: UpdateKanbanCastingGroupRecordRequest }
  > = async ({ data }) => {
    await request('put', apiRoute.update(id), { data })
  }

  const updateKanbanCastingGroupRecordQuery = useMutation({
    mutationKey: kanbanCastingGroupRecordKeys.update(id),
    mutationFn: updateKanbanRecordData,
    onSuccess: async () => {
      snackBar.success({
        title: t('toast.edit.success', { type: t('CastingInfo') }),
      })
    },
    onError: async (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.edit.error', { type: t('CastingInfo') }) +
            t(`api.errors.${error.response?.data.message}` as TranslationKeys),
        })
      }
    },
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: workOrderStationKeys.inOut(workOderStationId),
      })
    },
  })

  return {
    update: updateKanbanCastingGroupRecordQuery,
  }
}
