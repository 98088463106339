import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { Card, chakra, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { NoDataWarning } from '@/components/atoms'
import { CircleIcon } from '@/components/atoms/Icon/CircleIcon'
import { TablePagination } from '@/components/molecules'

import { useContainerDimensions } from '@/hooks'

import { workerStatus } from '@/constants/workOrder'

import { TranslationKeys } from '@/_types_/i18next'

import { BaseTable } from './BaseTable'

interface ClassCategory {
  columnClass?: string
  headClass?: string
  columnChildClass?: string
  headChildClass?: string
  columnThirdClass?: string
  headThirdClass?: string
  containerClass?: string
}
export interface UserCollapsibleTableProps<TData, TCData, TTData>
  extends DebugTableType,
    ClassCategory {
  columns: ColumnDef<TData, any>[]
  childColumns: ColumnDef<TCData, any>[]
  thirdColumns?: ColumnDef<TTData, any>[]
  columnIdThatDoNotCollapse?: ColumnDef<TData, any>['id'][]
  data: TData[]
  contextHeight?: number | string
  pageSize?: number
  needOrder?: boolean
  showStatusList?: boolean
  showPagination?: boolean
  onExpand?: (data: TData | null) => void
}

export const CollapsibleTable = <TData, TCData, TTData>({
  columns,
  childColumns,
  thirdColumns,
  data,
  pageSize = 10,
  contextHeight = '70vh',
  columnIdThatDoNotCollapse = [],
  debugTable = false,
  debugHeaders = false,
  debugColumns = false,
  showStatusList = true,
  showPagination = true,
  needOrder = true,
  columnClass = '',
  headClass = '',
  headChildClass = '',
  columnChildClass = '',
  columnThirdClass = '',
  headThirdClass = '',
  containerClass = '',
  onExpand = () => {},
}: UserCollapsibleTableProps<TData, TCData, TTData>) => {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()
  const [sortInfo, setSortInfo] = useState<{
    id: string
    sortType: string | boolean
  }>({
    id: '',
    sortType: '',
  })

  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const {
    getHeaderGroups,
    getRowModel,
    getPageCount,
    previousPage,
    getCanPreviousPage,
    setPageIndex,
    setPageSize,
    getCanNextPage,
    getState,
    nextPage,
  } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: needOrder ? getSortedRowModel() : undefined,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    sortDescFirst: false,
    state: {
      sorting,
      columnFilters,
    },
    autoResetPageIndex: false,
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),

    debugTable,
    debugHeaders,
    debugColumns,
    initialState: {
      pagination: {
        pageSize,
      },
    },
  })

  const count = data.length

  const headClasses = twMerge('border-b-2', headClass)
  const containerClasses = twMerge(``, containerClass)

  useEffect(() => {
    setPageIndex(0)
  }, [count])

  return (
    <Card
      overflow="hidden"
      shadow={'none'}
      borderRadius="none"
      position={'relative'}
    >
      <div
        ref={parentRef}
        className={containerClasses}
        style={{
          height: contextHeight,
          width: '100%',
          overflowY: 'auto',
          contain: 'paint',
        }}
      >
        <div className="relative">
          <Table
            variant="simple"
            style={{ borderCollapse: 'separate', borderSpacing: 0 }}
          >
            <Thead className={headClasses}>
              {getHeaderGroups().map((headerGroup, index) => {
                return (
                  <Tr key={headerGroup.id} background="white">
                    {headerGroup.headers.map((header) => {
                      const { column } = header
                      const handler: undefined | ((event: unknown) => void) =
                        column.getToggleSortingHandler()
                      return (
                        <>
                          <Th
                            key={header.id}
                            data-testid="user_table_th"
                            isNumeric={column.columnDef.meta?.isNumeric}
                            userSelect="none"
                            cursor="pointer"
                          >
                            <div
                              onClick={(e) => {
                                if (handler == undefined) return
                                handler(e)
                                setSortInfo({
                                  sortType: column.getIsSorted(),
                                  id: column.id,
                                })
                              }}
                            >
                              <div className=" flex text-sm font-extrabold">
                                {flexRender(
                                  column.columnDef.header,
                                  header.getContext(),
                                )}
                                {needOrder && (
                                  <chakra.span fontSize="1rem">
                                    {column.getIsSorted() ? (
                                      column.getIsSorted() === 'desc' ? (
                                        <ArrowDownIcon data-testid="user_table_arrow_down" />
                                      ) : (
                                        <ArrowUpIcon data-testid="user_table_arrow_up" />
                                      )
                                    ) : null}
                                  </chakra.span>
                                )}
                              </div>
                            </div>
                          </Th>
                        </>
                      )
                    })}
                  </Tr>
                )
              })}
            </Thead>
            <Tbody>
              {getRowModel().rows.length ? (
                getRowModel().rows.map((row) => {
                  const cellData = row.original as TData & {
                    childData: unknown[]
                  }
                  return (
                    <TableColumn
                      row={row}
                      sortInfo={sortInfo}
                      key={row.id}
                      childColumns={childColumns}
                      thirdColumns={thirdColumns}
                      columnClass={columnClass}
                      headChildClass={headChildClass}
                      columnChildClass={columnChildClass}
                      columnThirdClass={columnThirdClass}
                      headThirdClass={headThirdClass}
                      data={cellData}
                      columnIdThatDoNotCollapse={columnIdThatDoNotCollapse}
                      onExpand={onExpand}
                    />
                  )
                })
              ) : (
                <Tr>
                  <Td colSpan={columns.length}>
                    <NoDataWarning />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </div>
      </div>
      <div>
        {/* workOrder status */}
        {showStatusList && (
          <div className="flex flex-wrap items-center justify-center gap-[0.5rem] py-2 md:justify-end md:gap-[2rem]">
            {Object.values(workerStatus).map((item) => (
              <div className="flex items-center gap-[0.5rem]" key={item.i18key}>
                <CircleIcon color={item.color} boxSize={5} />
                <p>{t(`${item.i18key}` as TranslationKeys)}</p>
              </div>
            ))}
          </div>
        )}
        {showPagination && (
          <div className=" flex items-center justify-end pt-5">
            <TablePagination
              getPageCount={getPageCount}
              pageIndex={getState().pagination.pageIndex}
              setPageIndex={setPageIndex}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

interface TableColumnProps<TData, TCData, TTData> {
  row: Row<TData>
  childColumns?: ColumnDef<TCData, any>[]
  thirdColumns?: ColumnDef<TTData, any>[]
  columnClass?: string
  headChildClass?: string
  columnChildClass?: string
  headThirdClass?: string
  columnThirdClass?: string
  columnIdThatDoNotCollapse?: ColumnDef<TData, any>['id'][]
  sortInfo: { id: string; sortType: string | boolean }
  data: TData & { childData: unknown[] }
  onExpand?: (data: TData | null) => void
}

const TableColumn = <Tdata, TCData, TTData>({
  row,
  sortInfo,
  columnIdThatDoNotCollapse,
  childColumns,
  thirdColumns,
  columnClass = '',
  headChildClass = '',
  columnChildClass = '',
  headThirdClass = '',
  columnThirdClass = '',
  data,
  onExpand = () => {},
}: TableColumnProps<Tdata, TCData, TTData>) => {
  const trRef = useRef<HTMLTableRowElement | null>(null)
  const { width } = useContainerDimensions(trRef)
  const { getVisibleCells } = row
  const [isOpen, setIsOpen] = useState(false)

  const childData = data.childData

  const toggleOpen = () => {
    const value = !isOpen
    setIsOpen(value)
    if (value === true) {
      onExpand(data)
    }
  }
  const setClose = () => {
    setIsOpen(false)
    onExpand(null)
  }
  useEffect(() => {
    if (isOpen !== true) return
    setClose()
  }, [sortInfo.id, sortInfo.sortType])

  // first column style
  const columnClasses = twMerge(
    'text-[0.875rem] [text-wrap:pretty]',
    columnClass,
  )
  // second column style
  const headChildClasses = twMerge(
    'font-bold text-[0.875rem]  [text-wrap:pretty]',
    headChildClass,
  )
  const columnChildClasses = twMerge(
    'text-[0.875rem] [text-wrap:pretty]',
    columnChildClass,
  )

  // third column style
  const headThirdClasses = twMerge('font-bold text-[0.875rem]', headThirdClass)
  const columnThirdClasses = twMerge('text-[0.875rem]', columnThirdClass)

  // inherit bg color

  return (
    <>
      <tr key={row.id} className="cursor-pointer border-b-2 " ref={trRef}>
        {getVisibleCells().map((cell) => {
          const { column, getContext } = cell
          const hasContextMeta =
            getContext().cell.column.columnDef.meta?.getCellContext
          return (
            <Td
              key={cell.id}
              onClick={
                columnIdThatDoNotCollapse &&
                columnIdThatDoNotCollapse.includes(cell.id.split('_')[1])
                  ? () => {}
                  : toggleOpen
              }
              isNumeric={column.columnDef.meta?.isNumeric}
              {...(hasContextMeta && {
                ...hasContextMeta(cell.getContext()),
              })}
              className={columnClasses}
            >
              {flexRender(column.columnDef.cell, cell.getContext())}
            </Td>
          )
        })}
      </tr>
      {childData && (
        <tr>
          <td
            colSpan={99999}
            className={`p-0 ${thirdColumns ? '' : 'bg-gray-50'}`}
          >
            <AnimatePresence initial={false}>
              {isOpen && (
                <motion.div
                  key="content"
                  animate="open"
                  exit="collapsed"
                  initial="collapsed"
                  variants={{
                    open: {
                      opacity: 1,
                      height: 'auto',
                      filter: 'blur(0px)',
                    },
                    collapsed: {
                      opacity: 0,
                      height: 0,
                      filter: 'blur(20px)',
                    },
                  }}
                  transition={{ duration: 0.2, ease: 'easeInOut' }}
                >
                  <div
                    className={`w-full overflow-hidden border-b-2 p-[1rem]`}
                    style={{ width: width }}
                  >
                    {thirdColumns ? (
                      <CollapsibleTable
                        needOrder={false}
                        columns={childColumns!}
                        childColumns={thirdColumns}
                        columnClass={columnChildClasses}
                        columnChildClass={columnThirdClasses}
                        headChildClass={headThirdClasses}
                        headClass={headChildClasses}
                        data={childData as TCData[]}
                        showStatusList={false}
                        showPagination={false}
                        contextHeight={'auto'}
                        pageSize={9999999}
                      />
                    ) : (
                      <BaseTable
                        needOrder={false}
                        contextHeight={'auto'}
                        tableType="unstyled"
                        showPagination={false}
                        columnClass={columnChildClasses}
                        headClass={headChildClasses}
                        columns={childColumns as ColumnDef<unknown, any>[]}
                        data={childData}
                        pageSize={9999999}
                      />
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </td>
        </tr>
      )}
    </>
  )
}
TableColumn.displayName = 'TableColumn'
