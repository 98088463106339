import { useTranslation } from 'react-i18next'
import { MutationFunction, useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { API } from '@/utils/API'

import { autoStatusKeys } from '@/constants/queryKeyFactory'

import { TranslationKeys } from '@/_types_/i18next'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

const apiRoute = API.routes.productStationAutoStatus

export const useAutoStatus = (
  params?: GetProductStationAutoStatusListParams,
) => {
  const { request } = useAPI()
  const { t } = useTranslation()
  const snackBar = useSnackbar()

  // GET /products/stations/autoStatus/{id}
  const getAutoStatusList = async () => {
    if (!params?.productId) return []
    const { data } = await request<GetProductStationAutoStatusListResponse[]>(
      'get',
      apiRoute.list(params.productId),
    )
    return data
  }

  const autoStatusListQuery = useQuery({
    queryKey: autoStatusKeys.list(params?.productId),
    queryFn: getAutoStatusList,
    enabled: !!params?.productId,
  })

  const onError = async (error: any) => {
    if (error instanceof AxiosError) {
      snackBar.error({
        title:
          t('Failed', { do: t('AutoStatusSetting') }) +
          ' ' +
          t(`api.errors.${error.response?.data.message}` as TranslationKeys),
      })
      return
    }
    snackBar.error({ title: t('Error') })
  }

  // POST /products/stations/autoStatus
  const createProductStationsAutoStatus: MutationFunction<
    void,
    PostProductStationAutoStatusRequest[]
  > = async (data) => {
    await request('post', apiRoute.create, { data })
  }

  const createProductStationsAutoStatusQuery = useMutation({
    mutationKey: autoStatusKeys.create(),
    mutationFn: createProductStationsAutoStatus,
    onError: (err) => onError(err),
  })

  // DELETE /products/stations/autoStatus/${id}
  const deleteProductStationsAutoStatus: MutationFunction<
    void,
    number
  > = async (id) => {
    await request('delete', apiRoute.delete(id))
  }

  const deleteProductStationsAutoStatusQuery = useMutation({
    mutationKey: autoStatusKeys.delete(),
    mutationFn: deleteProductStationsAutoStatus,
    onError: (err) => onError(err),
  })

  return {
    query: autoStatusListQuery,
    create: createProductStationsAutoStatusQuery,
    delete: deleteProductStationsAutoStatusQuery,
  }
}
