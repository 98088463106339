import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import BallotIcon from '@mui/icons-material/Ballot'
import ConstructionIcon from '@mui/icons-material/Construction'
import DashboardIcon from '@mui/icons-material/Dashboard'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import InventoryIcon from '@mui/icons-material/Inventory'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import ListOutlinedIcon from '@mui/icons-material/ListOutlined'
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import WarningIcon from '@mui/icons-material/Warning'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

import { SideBarElement } from '@/components/templates/sideBar/SideBar'

import { PATHS } from './path'

export const slideLists: SideBarElement[] = [
  {
    featureIcon: DashboardIcon,
    titleKey: 'ProductionExecution',
    open: true,
    lists: [
      {
        to: PATHS.workOrder,
        iconType: ListAltOutlinedIcon,
        titleKey: 'OrderManagement',
      },
      {
        to: PATHS.completeWorkerOrder,
        iconType: AssignmentTurnedInOutlinedIcon,
        titleKey: 'FinishOrder',
      },
      {
        to: PATHS.inAndExit,
        iconType: SwapHorizOutlinedIcon,
        titleKey: 'InAndExitManagement',
      },
    ],
  },
  {
    featureIcon: ListOutlinedIcon,
    titleKey: 'ProductionManagement',
    lists: [
      {
        to: PATHS.product,
        iconType: FolderOutlinedIcon,
        titleKey: 'ProductManagement',
      },
      {
        to: PATHS.defective,
        iconType: WarningAmberOutlinedIcon,
        titleKey: 'DefectiveManagement',
      },
      {
        to: PATHS.defectiveType,
        iconType: WarningIcon,
        titleKey: 'DefectiveListManagement',
      },
      {
        to: PATHS.workTime,
        iconType: AccessAlarmOutlinedIcon,
        titleKey: 'WorktimeManagement',
      },
      {
        to: PATHS.user,
        iconType: Person2OutlinedIcon,
        titleKey: 'UserManagement',
      },
      {
        to: PATHS.station,
        iconType: ConstructionIcon,
        titleKey: 'StationManagement',
      },
      {
        to: PATHS.castingInfoManagement,
        iconType: BallotIcon,
        titleKey: 'CastingInfoManagement',
      },
      {
        to: PATHS.inventory,
        iconType: InventoryIcon,
        titleKey: 'Inventory',
      },
    ],
  },
]
