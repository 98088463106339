import { forwardRef, ReactNode, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

type LabelType = {
  default: string
  expanded: string
}

export interface CubeButtonProps {
  iconComponent: ReactNode
  label?: string | LabelType
  onClick: () => void
  expandable?: boolean
  value?: string
  onChange?: (value: string) => void
}

export const CubeButton = forwardRef<HTMLDivElement, CubeButtonProps>(
  (
    { iconComponent, label, onClick, expandable = true, value, onChange },
    ref,
  ) => {
    const [expand, setExpand] = useState(false)

    const displayLabel = !!label
      ? typeof label === 'string'
        ? label
        : expandable && expand
        ? label.expanded
        : label.default
      : ''

    const onButtonClick = () => {
      if (expandable) setExpand((prev) => !prev)
      onClick()
    }

    return (
      <div
        className={`flex place-content-center items-center overflow-hidden rounded-lg border bg-slate-600 text-slate-300`}
      >
        <div
          ref={ref}
          className={`flex cursor-pointer items-center p-3 ${
            !!label ? 'py-2' : ''
          }`}
          onClick={onButtonClick}
          data-testid="cube_button_button_container"
        >
          {iconComponent}
          {displayLabel !== '' && (
            <span className="ml-3" data-testid="cube_button_label">
              {displayLabel}
            </span>
          )}
        </div>
        {expandable && (
          <AnimatePresence>
            {expand && (
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: 'auto' }}
                exit={{ width: 0 }}
                data-testid="cube_button_animated_input_container"
              >
                <input
                  type="text"
                  className="h-full bg-transparent outline-none"
                  data-testid="cube_button_expanded_input"
                  value={value}
                  onChange={(e) => {
                    if (!!onChange) onChange(e.target.value)
                  }}
                  autoFocus
                />
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </div>
    )
  },
)
CubeButton.displayName = 'CudeButton'
