export const IsZh = (language: string) => {
  return /^zh(-tw)?$/.test(language.toLowerCase())
}
export const IsEN = (language: string) => {
  return /^en(.*)?$/.test(language.toLowerCase())
}
export const IsVI = (language: string) => {
  return /^vi$/.test(language.toLowerCase())
}

export const getStationNameWithLanguage = <
  Tdata extends { nameZhTW: string; nameVi: string },
>(
  data: Tdata,
  language: string,
) => {
  const { nameZhTW, nameVi } = data
  if (IsEN(language)) {
    return nameZhTW
  }
  if (IsZh(language)) {
    return nameZhTW
  }
  if (IsVI(language)) {
    return nameVi
  }
  return nameZhTW
}

export const getWorkOrderStationName = <
  Tdata extends {
    workOrderStationNameZhTW: string
    workOrderStationNameVi: string
  },
>(
  data: Tdata,
  language: string,
) => {
  const { workOrderStationNameVi, workOrderStationNameZhTW } = data
  if (IsEN(language)) {
    return workOrderStationNameZhTW
  }
  if (IsZh(language)) {
    return workOrderStationNameZhTW
  }
  if (IsVI(language)) {
    return workOrderStationNameVi
  }
  return workOrderStationNameZhTW
}
