import { QueryFunction, useQuery } from '@tanstack/react-query'

import { API } from '@/utils/API'

import { kanbanDefectiveRecordKeys } from '@/constants/queryKeyFactory'

import { useAPI } from './useAPI'

export const useDefectiveRecord = (
  defectiveRecordQuery: DefectiveRecordQuery,
) => {
  const { request } = useAPI()

  const apiRoute = API.routes.kanbanDefectiveRecord

  const getDefectiveListData: QueryFunction<DefectiveData[]> = async () => {
    const { data } = await request<DefectiveData[]>(
      'get',
      apiRoute.list(defectiveRecordQuery),
    )
    return data ?? []
  }

  const defectiveListQuery = useQuery({
    queryKey: kanbanDefectiveRecordKeys.list(defectiveRecordQuery.date),
    queryFn: getDefectiveListData,
    initialData: [],
  })

  return {
    query: defectiveListQuery,
  }
}
