import { useEffect } from 'react'

import { useAuth, useNavigation } from '@/hooks'

import { PATHS } from '@/constants/path'

export const RedirectRoute = () => {
  const { isAuthenticated } = useAuth()
  const navigation = useNavigation()

  useEffect(() => {
    if (isAuthenticated) {
      navigation.goTo(PATHS.workOrder)
    } else {
      navigation.goTo(PATHS.login)
    }
  }, [isAuthenticated])

  return (
    <div className="flex flex-col">
      <div />
    </div>
  )
}
RedirectRoute.displayName = 'RedirectRoute'
