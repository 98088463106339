import { ChangeEventHandler, FocusEventHandler, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'

export interface CheckboxProps {
  name: string
  label?: string
  isChecked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: ChangeEventHandler<HTMLInputElement>
  required?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  error?: FieldError
  labelPosition?: 'left' | 'right'
}

const getLabelPosition = (labelPosition: CheckboxProps['labelPosition']) => {
  return labelPosition === 'left'
    ? 'flex-row'
    : 'flex-row-reverse place-content-start'
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const { label, labelPosition = 'right', error, ...inputProps } = props
    const { name, required } = inputProps

    return (
      <div className={`flex gap-2 ${getLabelPosition(labelPosition)}`}>
        {!!label && (
          <label
            className="text-start text-base"
            data-testid={`${name}_checkbox_${labelPosition}_label`}
            htmlFor={name}
          >
            {label}
            {required && (
              <span
                className="text-red-200"
                data-testid={`${name}_checkbox_${labelPosition}_required`}
              >
                *
              </span>
            )}
          </label>
        )}
        <ChakraCheckbox
          ref={ref}
          {...inputProps}
          borderColor="slate.600"
          colorScheme="slate"
          data-testid={name}
        />
      </div>
    )
  },
)
Checkbox.displayName = 'Checkbox'
