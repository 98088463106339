import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import { Box, Button, Icon } from '@chakra-ui/react'

import { useDefective } from '@/hooks'

import { DefectiveFormSchemaType } from '@/helpers/validate/Defective'

import { useDefectiveListColumns } from '@/utils/tableColumn'

import { PATHS } from '@/constants/path'

import { BaseTable } from '../organisms'
import { PageWrapper } from '../organisms/PageWrapper'
import { DefectiveFormModal } from '../templates'
import { DeleteModal } from '../templates/modal/DeleteModal'

export const DefectiveListPage = () => {
  const { t, i18n } = useTranslation()
  const {
    defectReasons,
    postDefectReason,
    patchDefectReason,
    deleteDefectReason,
  } = useDefective()
  const [modal, setModal] = useState<ModalOpenType>('')
  const [selectedDefective, setSelectedDefective] = useState<any>({})

  const handleAction = (
    type: ModalOpenType,
    data?: DefectiveData & { id: number },
  ) => {
    setSelectedDefective(
      ['create', 'update', 'delete'].includes(type) ? data : undefined,
    )
    setModal(type)
  }

  const onClose = () => {
    setModal('')
    setSelectedDefective({})
  }

  const handleSubmit = async (formValues: DefectiveFormSchemaType) => {
    try {
      const body = {
        code: formValues.code!,
        vi: formValues.vi,
        zhTW: formValues.zhTW,
      }
      if (modal === 'create') {
        await postDefectReason.mutateAsync(body)
      } else if (modal === 'update') {
        await patchDefectReason.mutateAsync({
          code: formValues.reasonCode!,
          data: body,
        })
      } else if (modal === 'delete') {
        await deleteDefectReason.mutateAsync({ code: formValues.reasonCode! })
      }
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const columns = useDefectiveListColumns(i18n.language, handleAction)

  const TABS = [
    {
      label: t('/defective_type'),
      path: PATHS.defectiveType,
    },
    {
      label: t('/defective_list'),
      path: PATHS.defectiveList,
    },
  ]

  return (
    <PageWrapper tabs={TABS}>
      <Box className="m-4 h-[calc(100vh_-_90px)] rounded-md bg-white p-4">
        <div className="mb-5 flex  flex-wrap items-stretch justify-start gap-[0.75rem] md:justify-end">
          <Button
            colorScheme="blue"
            variant="outline"
            h="38px"
            style={{ borderRadius: 4 }}
            rightIcon={<Icon as={MdAdd} fontSize={20} />}
            onClick={() => handleAction('create')}
          >
            {t('New', { what: '' })}
          </Button>
        </div>
        <Box>
          <BaseTable columns={columns} data={defectReasons!} />
        </Box>
      </Box>
      <DefectiveFormModal
        isOpen={modal === 'create' || modal === 'update'}
        formId="defectiveForm"
        action={modal}
        data={selectedDefective}
        onConfirm={handleSubmit}
        onClose={onClose}
      />
      <DeleteModal
        isOpen={modal === 'delete'}
        onClose={onClose}
        onConfirm={() => handleSubmit(selectedDefective)}
        name={selectedDefective?.code}
        type={t('DefectiveReason')}
      />
    </PageWrapper>
  )
}
