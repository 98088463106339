import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { Button, Input } from '@/components/atoms'

import { useToken } from '@/hooks/useToken'

import { API } from '@/utils/API'

import { APP_API_BONUS, APP_API_SFT } from '@/constants/environment'

import i18n from '@/locale/i18n'

const UserLoginFormSchema = z.object({
  account: z
    .string()
    .min(1, { message: i18n.t('Form.Require', { field: i18n.t('Account') }) }),
  email: z.string().email().optional(),
  password: z
    .string()
    .min(1, { message: i18n.t('Form.Require', { field: i18n.t('Password') }) }),
  system: z.enum([APP_API_SFT, APP_API_BONUS]),
})

export type UserLoginFormDataType = z.infer<typeof UserLoginFormSchema>
interface UserLoginFormProps {
  onSubmit: (formData: UserLoginFormDataType) => void
}

const systemOption = API.systems.map((s) => ({
  label: s.name,
  value: s.value,
}))

export const UserLoginForm = ({ onSubmit }: UserLoginFormProps) => {
  const { t } = useTranslation()
  const { updateBaseUrl, baseurl } = useToken()
  const { register, formState, control, handleSubmit, getValues, watch } =
    useForm<UserLoginFormDataType>({
      resolver: zodResolver(UserLoginFormSchema),
      defaultValues: {
        system: systemOption[0].value,
      },
    })

  useEffect(() => {
    const sub = watch((value) => {
      updateBaseUrl(value.system as string)
    })
    return () => {
      sub.unsubscribe()
    }
  }, [watch])

  return (
    <div>
      <form
        onSubmit={handleSubmit(
          (credential) => {
            onSubmit(credential)
          },
          (error) => {
            console.log(error)
          },
        )}
        className="flex flex-col gap-6 "
      >
        <Controller
          name="system"
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <Select
              options={systemOption}
              onChange={(option) => {
                onChange(option?.value)
              }}
              placeholder="132"
              styles={{
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  'background': state.isSelected ? 'none' : '',
                  'color': state.isSelected ? '#000' : '',
                  '&:hover': {
                    background: '#E1EDFF',
                  },
                }),
              }}
              defaultValue={systemOption[0]}
            />
          )}
          rules={{ required: true }}
        />
        <Input
          {...register('account')}
          type="text"
          disabled={formState.isSubmitting}
          error={formState.errors.account}
          outerClassName="lg:w-[27rem] w-auto"
          label={t('Account')}
          placeholder={t('Account') + '*'}
          required
        />
        <Input
          {...register('password')}
          type="password"
          disabled={formState.isSubmitting}
          outerClassName="lg:w-[27rem] w-auto"
          error={formState.errors.password}
          label={t('Password')}
          placeholder={t('Password') + '*'}
          required
        />
        <Button
          disabled={formState.isSubmitting}
          className="hover:text-border-slate-800 bg-[#3F51B5] text-base text-slate-200 "
        >
          {t('Login')}
        </Button>
      </form>
    </div>
  )
}
