import { useEffect, useState } from 'react'

export const useDebounce = <T>({
  value,
  delay = 500,
}: {
  value: T
  delay?: number
}) => {
  const [debounceValue, setDebounceValue] = useState<T>()
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceValue(value)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])
  return debounceValue
}
