import { FC } from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { twMerge } from 'tailwind-merge'

interface CardItemProps<Data> {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  index: number
  id: string
  data: Data
  columnItem: TaskType
  className?: string
  onClick?: () => void
  isSelectedCard?: boolean
}
export const UnAssignCard: FC<CardItemProps<StationListBaseData>> = ({
  provided,
  columnItem,
  className,
}) => {
  const cn = twMerge(
    `flex min-h-[50px] justify-between p-[0.75rem] shadow-sm border rounded-[0.375rem]`,
    className,
  )
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={cn}
      style={{
        userSelect: 'none',
        ...provided.draggableProps.style,
      }}
    >
      <p className="text-[0.875rem]">
        {(columnItem.data as ProductStationResponse).code}
      </p>
      <p className="text-gray-800] text-[0.875rem] font-[600]">
        {columnItem.content}
      </p>
    </div>
  )
}
export const AssignCard: FC<CardItemProps<ProductStationResponse>> = ({
  provided,
  snapshot,
  columnItem,
  className,
  index,
  onClick,
  isSelectedCard,
}) => {
  const cn = twMerge(
    ` flex justify-between  p-[0.75rem] shadow-md  rounded-[0.375rem]
       ${snapshot.isDragging ? 'bg-gray-300 ' : ''}
       ${isSelectedCard ? 'border-[#3F51B5] border-[1.5px]' : ''}
      `,
    className,
  )

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={cn}
      style={{
        userSelect: 'none',
        ...provided.draggableProps.style,
      }}
      onClick={() => typeof onClick === 'function' && onClick()}
    >
      <div className="flex items-center gap-[1rem] text-[0.875rem] ">
        <p className="font-[400] text-gray-600">#{index + 1}</p>
        {(columnItem.data as ProductStationResponse).code}
      </div>
      <div>
        <p className="text-[0.875rem] font-[600] text-gray-800">
          {columnItem.content}
        </p>
      </div>
    </div>
  )
}

export const CardTypes = {
  unAssign: UnAssignCard,
  assign: AssignCard,
}
