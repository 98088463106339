import { ButtonHTMLAttributes } from 'react'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

export const Button = ({ children, className = '', ...rest }: ButtonProps) => {
  return (
    <button
      className={`${className} flex items-center justify-center rounded-md p-2`}
      {...rest}
    >
      {children}
    </button>
  )
}
