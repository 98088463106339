import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { API } from '@/utils/API'

import { castingManagementKeys } from '@/constants/queryKeyFactory'

import { TranslationKeys } from '@/_types_/i18next'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useCastingManagement = (castingManagementName?: string) => {
  const [filterText, setFilterText] = useState<string>('')
  const { request } = useAPI()
  const { t } = useTranslation()
  const snackBar = useSnackbar()
  const queryClient = useQueryClient()
  const apiRoute = API.routes.castingGroup

  const filterCastingManagementData = (data: CastingGroupResponse[]) => {
    return data.filter((casting) =>
      casting.name.toLowerCase().includes(filterText.toLowerCase()),
    )
  }
  const getCastingManagementData: QueryFunction<
    CastingGroupResponse[]
  > = async () => {
    const { data } = await request<CastingGroupResponse[], never, never>(
      'get',
      apiRoute.list({ name: castingManagementName ?? '' }),
    )

    return data
  }

  const deleteCastingManagementsData: MutationFunction<
    void,
    { id: string }
  > = async ({ id }) => {
    await request('delete', apiRoute.delete(id))
  }
  const castingManagementQuery = useQuery({
    queryKey: castingManagementKeys.lists(castingManagementName),
    queryFn: getCastingManagementData,
    select: filterCastingManagementData,
  })
  const deleteCastingManagementQuery = useMutation({
    mutationKey: castingManagementKeys.delete(),
    mutationFn: deleteCastingManagementsData,
    onSuccess: () => {
      snackBar.success({
        title: t('toast.delete.success', {
          type: t('user.GroupName'),
        }),
      })
    },
    onError: () => {
      snackBar.error({
        title: t('toast.delete.error', {
          type: t('user.GroupName'),
        }),
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(castingManagementKeys.lists())
    },
  })

  const createCastingManagementsData: MutationFunction<
    void,
    { data: CastingGroupRequestBody }
  > = async ({ data }) => {
    await request('post', apiRoute.create, { data })
  }
  const createCastingManagementQuery = useMutation({
    mutationKey: castingManagementKeys.create(),
    mutationFn: createCastingManagementsData,
    onSuccess: () => {
      snackBar.success({
        title: t('toast.add.success', {
          type: t('user.GroupName'),
        }),
      })
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        snackBar.error({
          title: t(
            `api.errors.${err.response?.data.message}` as TranslationKeys,
          ),
        })
      } else {
        snackBar.error({
          title: t('toast.add.error', {
            type: t('user.GroupName'),
          }),
        })
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(castingManagementKeys.lists())
    },
  })
  const updateCastingManagementData: MutationFunction<
    void,
    { data: CastingGroupRequestBody; id: string }
  > = async ({ data, id }) => {
    await request('put', apiRoute.update(id), { data })
  }
  const updateCastingManagementQuery = useMutation({
    mutationKey: castingManagementKeys.update(),
    mutationFn: updateCastingManagementData,
    onSuccess: () => {
      snackBar.success({
        title: t('toast.edit.success', {
          type: t('user.GroupName'),
        }),
      })
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        snackBar.error({
          title: t(
            `api.errors.${err.response?.data.message}` as TranslationKeys,
          ),
        })
      } else {
        snackBar.error({
          title: t('toast.edit.error', {
            type: t('user.GroupName'),
          }),
        })
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(castingManagementKeys.lists())
    },
  })
  return {
    query: castingManagementQuery,
    create: createCastingManagementQuery,
    update: updateCastingManagementQuery,
    delete: deleteCastingManagementQuery,
    filterText,
    setFilterText,
  }
}

export const useCastingManagementQuery = <Tdata>(
  select: (data: CastingGroupResponse[]) => Tdata,
) => {
  const apiRoute = API.routes.castingGroup
  const { request } = useAPI()
  const getCastingManagementData: QueryFunction<
    CastingGroupResponse[]
  > = async () => {
    const { data } = await request<CastingGroupResponse[], never, never>(
      'get',
      apiRoute.list(),
    )

    return data
  }

  return useQuery({
    queryKey: castingManagementKeys.lists(),
    queryFn: getCastingManagementData,
    select,
  })
}
