import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { API } from '@/utils/API'

import { defectReasonKeys, defectTypeKeys } from '@/constants/queryKeyFactory'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

const typeRoute = API.routes.defectType
const reasonRoute = API.routes.defectReason

export const useDefective = () => {
  const { t, i18n } = useTranslation()
  const { request } = useAPI()
  const queryClient = useQueryClient()
  const snackBar = useSnackbar()

  /* type */
  const getDefectiveTypes: MutationFunction<
    GetDefectTypeResponse[],
    unknown
  > = async () => {
    const res = await request<GetDefectTypeResponse[], unknown>(
      'get',
      typeRoute.list,
    )
    return res.data
  }

  const defectTypeDataQuery = useQuery({
    queryKey: defectTypeKeys.list,
    queryFn: getDefectiveTypes,
    initialData: [],
  })

  const defectTypes = useMemo(() => {
    return defectTypeDataQuery.data?.map((item) => ({
      label: item.type,
      value: item.code,
    }))
  }, [defectTypeDataQuery.data])

  const createDefectType: MutationFunction<
    PostDefectTypeResponse,
    PostDefectTypeRequest
  > = async (data) => {
    const { data: response } = await request<
      PostDefectTypeResponse,
      PostDefectTypeRequest
    >('post', typeRoute.create, { data })
    return response
  }

  const createDefectTypeQuery = useMutation({
    mutationKey: defectTypeKeys.create,
    mutationFn: createDefectType,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.add.success', {
          type: t('DefectiveType'),
        }),
      })
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.add.error', {
              type: t('DefectiveType'),
            }) +
            ' ' +
            error.response?.data?.error[0].message,
        })
        return
      }
      snackBar.error({ title: t('Error') })
    },
    onSettled: () => {
      return queryClient.invalidateQueries(defectTypeKeys.list)
    },
  })

  const updateDefectType: MutationFunction<
    PatchDefectTypeResponse,
    { data: PatchDefectTypeRequest; code: PatchDefectTypeParams['code'] }
  > = async ({ data, code }) => {
    const { data: response } = await request<
      PatchDefectTypeResponse,
      PatchDefectTypeRequest
    >('patch', typeRoute.update(code), { data })
    return response
  }

  const updateDefectTypeQuery = useMutation({
    mutationKey: defectTypeKeys.update,
    mutationFn: updateDefectType,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.edit.success', {
          type: t('DefectiveType'),
        }),
      })
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.add.error', {
              type: t('DefectiveType'),
            }) +
            ' ' +
            error.response?.data?.error[0].message,
        })
        return
      }
      snackBar.error({ title: t('Error') })
    },
    onSettled: () => {
      return queryClient.invalidateQueries(defectTypeKeys.list)
    },
  })

  const deleteDefectType: MutationFunction<
    never,
    DeleteDefectTypeParams
  > = async ({ code }) => {
    const { data: response } = await request<never>(
      'patch',
      typeRoute.disable(code),
    )

    return response
  }

  const deleteDefectTypeQuery = useMutation({
    mutationKey: defectTypeKeys.delete,
    mutationFn: deleteDefectType,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.delete.success', {
          type: t('DefectiveType'),
        }),
      })
    },
    onError: () => {
      snackBar.error({
        title: t('toast.delete.error', {
          type: t('DefectiveType'),
        }),
      })
    },
    onSettled: () => {
      return queryClient.invalidateQueries(defectTypeKeys.list)
    },
  })

  /* reason */
  const getDefectiveReasons: MutationFunction<
    GetDefectReasonResponse[],
    unknown
  > = async () => {
    const res = await request<GetDefectReasonResponse[], unknown>(
      'get',
      reasonRoute.list,
    )
    return res.data
  }

  const defectReasonDataQuery = useQuery({
    queryKey: defectReasonKeys.list,
    queryFn: getDefectiveReasons,
    initialData: [],
  })

  const createDefectReason: MutationFunction<
    PostDefectReasonResponse,
    PostDefectReasonRequest
  > = async (data) => {
    const { data: response } = await request<
      PostDefectReasonResponse,
      PostDefectReasonRequest
    >('post', reasonRoute.create, { data })
    return response
  }

  const createDefectReasonQuery = useMutation({
    mutationKey: defectReasonKeys.create,
    mutationFn: createDefectReason,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.add.success', {
          type: t('DefectiveReason'),
        }),
      })
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.add.error', {
              type: t('DefectiveReason'),
            }) +
            ' ' +
            error.response?.data?.error[0].message,
        })
        return
      }
      snackBar.error({ title: t('Error') })
    },
    onSettled: () => {
      return queryClient.invalidateQueries(defectReasonKeys.list)
    },
  })

  const updateDefectReason: MutationFunction<
    PatchDefectReasonResponse,
    { data: PatchDefectReasonRequest; code: PatchDefectReasonParams['code'] }
  > = async ({ data, code }) => {
    const { data: response } = await request<
      PatchDefectReasonResponse,
      PatchDefectReasonRequest
    >('patch', reasonRoute.update(code), { data })
    return response
  }

  const updateDefectReasonQuery = useMutation({
    mutationKey: defectReasonKeys.update,
    mutationFn: updateDefectReason,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.edit.success', {
          type: t('DefectiveReason'),
        }),
      })
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.add.error', {
              type: t('DefectiveReason'),
            }) +
            ' ' +
            error.response?.data?.error[0].message,
        })
        return
      }
      snackBar.error({ title: t('Error') })
    },
    onSettled: () => {
      return queryClient.invalidateQueries(defectReasonKeys.list)
    },
  })

  const deleteDefectReason: MutationFunction<
    never,
    DeleteDefectReasonParams
  > = async ({ code }) => {
    const { data: response } = await request<never>(
      'patch',
      reasonRoute.disable(code),
    )

    return response
  }

  const deleteDefectReasonQuery = useMutation({
    mutationKey: defectReasonKeys.delete,
    mutationFn: deleteDefectReason,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.delete.success', {
          type: t('DefectiveReason'),
        }),
      })
    },
    onError: () => {
      snackBar.error({
        title: t('toast.delete.error', {
          type: t('DefectiveReason'),
        }),
      })
    },
    onSettled: () => {
      return queryClient.invalidateQueries(defectReasonKeys.list)
    },
  })

  return {
    typesOptions: defectTypes,
    defectTypes: defectTypeDataQuery.data,
    defectReasons: defectReasonDataQuery.data,
    postDefectType: createDefectTypeQuery,
    patchDefectType: updateDefectTypeQuery,
    deleteDefectType: deleteDefectTypeQuery,
    postDefectReason: createDefectReasonQuery,
    patchDefectReason: updateDefectReasonQuery,
    deleteDefectReason: deleteDefectReasonQuery,
  }
}
