import { z } from 'zod'

import i18n from '@/locale/i18n'

export const workOrderCreateSchema = z.object({
  code: z
    .string()
    .min(1, { message: i18n.t('error.isRequired', { name: '' }) })
    .max(30),
})

export type WorkOrderCreateSchemaType = z.infer<typeof workOrderCreateSchema>
