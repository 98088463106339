import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import { Box, Button, Icon } from '@chakra-ui/react'

import { useDefective } from '@/hooks'

import { DefectiveTypeFormSchemaType } from '@/helpers/validate/Defective'

import { useDefectiveTypeColumns } from '@/utils/tableColumn'

import { PATHS } from '@/constants/path'

import { BaseTable } from '../organisms'
import { PageWrapper } from '../organisms/PageWrapper'
import { DefectiveTypeFormModal } from '../templates'
import { DeleteModal } from '../templates/modal/DeleteModal'

export const DefectiveTypePage = () => {
  const { t, i18n } = useTranslation()
  const { defectTypes, postDefectType, patchDefectType, deleteDefectType } =
    useDefective()
  const [modal, setModal] = useState<ModalOpenType>('')
  const [selectedType, setSelectedType] = useState<any>({})

  const handleAction = (
    type: ModalOpenType,
    data?: DefectiveData & { id: number },
  ) => {
    setSelectedType(
      ['create', 'update', 'delete'].includes(type) ? data : undefined,
    )
    setModal(type)
  }

  const onClose = () => {
    setModal('')
    setSelectedType({})
  }

  const handleSubmit = async (data: DefectiveTypeFormSchemaType) => {
    try {
      if (modal === 'create') {
        await postDefectType.mutateAsync({ type: `報廢原因-${data.type}` })
      } else if (modal === 'update') {
        await patchDefectType.mutateAsync({
          code: data.code!,
          data: {
            type: data.type.startsWith('報廢原因-')
              ? data.type
              : `報廢原因-${data.type}`,
          },
        })
      } else if (modal === 'delete') {
        await deleteDefectType.mutateAsync({ code: data.code! })
      }
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const columns = useDefectiveTypeColumns(i18n.language, handleAction)

  const TABS = [
    {
      label: t('/defective_type'),
      path: PATHS.defectiveType,
    },
    {
      label: t('/defective_list'),
      path: PATHS.defectiveList,
    },
  ]

  return (
    <PageWrapper tabs={TABS}>
      <Box className="m-4 h-[calc(100vh_-_90px)] rounded-md bg-white p-4">
        <div className="mb-5 flex  flex-wrap items-stretch justify-start gap-[0.75rem] md:justify-end">
          <Button
            colorScheme="blue"
            variant="outline"
            h="38px"
            style={{ borderRadius: 4 }}
            rightIcon={<Icon as={MdAdd} fontSize={20} />}
            onClick={() => handleAction('create')}
          >
            {t('New', { what: '' })}
          </Button>
        </div>
        <Box>
          <BaseTable columns={columns} data={defectTypes!} />
        </Box>
      </Box>
      <DefectiveTypeFormModal
        isOpen={modal === 'create' || modal === 'update'}
        formId="defectiveTypeForm"
        action={modal}
        data={selectedType}
        onConfirm={handleSubmit}
        onClose={onClose}
      />
      <DeleteModal
        isOpen={modal === 'delete'}
        onClose={onClose}
        onConfirm={() => handleSubmit(selectedType)}
        name={selectedType?.type}
        type={t('DefectiveType')}
      />
    </PageWrapper>
  )
}
