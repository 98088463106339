export const getFlexDirectionClass = (direction: TextAlign | undefined) => {
  switch (direction) {
    case 'center':
      return 'justify-center'
    case 'right':
      return 'justify-end'
    case 'left':
      return 'justify-start'
    default:
      return 'justify-start'
  }
}
