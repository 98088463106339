import { z } from 'zod'

import i18n from '@/locale/i18n'

export const workStationSectionFormSchema = z.object({
  code: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('WorkOrder') }),
  }),
})
export type WorkStationSectionFormSchema = z.infer<
  typeof workStationSectionFormSchema
>
