import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react'

import { UserUpdateForm, UserUpdateFormProps } from '@/components/organisms'

export interface UserUpdateModalProps
  extends UseModalProps,
    Pick<UserUpdateFormProps, 'userData' | 'onSubmit'> {
  type: ModalOpenType
}

export const UserUpdateModal = ({
  userData,
  isOpen,
  type,
  onClose,
  onSubmit,
}: UserUpdateModalProps) => {
  const { t } = useTranslation()

  const handleSubmit: UserUpdateFormProps['onSubmit'] = (formData) => {
    onSubmit(formData)
    onClose()
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay data-testid="user_update_modal_overlay" />
      <ModalContent data-testid="user_update_modal_content">
        <ModalHeader>
          {t(type === 'update' ? 'Edit' : 'New', { what: t('User') })}
        </ModalHeader>
        <ModalBody>
          <UserUpdateForm
            userData={userData}
            onSubmit={handleSubmit}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
