import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'

import { useDefectiveRecord } from '@/hooks/useDefectiveRecord'

import { formatDate } from '@/utils/Date'
import { DefectiveDataColumns } from '@/utils/tableColumn'

import i18n from '@/locale/i18n'

import { DatePicker } from '../atoms/interactive/Date'
import { SearchInput } from '../atoms/interactive/SearchInput'
import { BaseSelect } from '../atoms/select'
import { BaseTable } from '../organisms'

const DATE_FORMAT = 'YYYY-MM-DD'

export const DefectiveManagementPage = () => {
  const { t } = useTranslation()
  const [date, setDate] = useState(
    formatDate(new Date().toString(), DATE_FORMAT),
  )
  const [filter, setFilter] = useState({
    searchString: '',
    categoryType: 'All',
  })
  const defectiveRecordQuery = useDefectiveRecord({ date })
  const originData = defectiveRecordQuery.query.data

  const getFilteredData = (): DefectiveData[] => {
    const categoryFilteredData = originData.filter((item) => {
      return filter.categoryType === 'All'
        ? true
        : item.defectiveCategoryType === filter.categoryType
    })
    return handleSearch(categoryFilteredData, filter.searchString)
  }

  const handleSearch = (data: DefectiveData[], value: string) => {
    const checkColumn: (keyof DefectiveData)[] = [
      'kanbanCode',
      'station',
      'workOrderCode',
    ]
    return data.filter((item) =>
      checkColumn.some((k) =>
        item[k]
          .toString()
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()),
      ),
    )
  }

  const handleSelect = (option: unknown) => {
    const { value } = option as Option
    setFilter({
      ...filter,
      categoryType: value,
    })
  }

  const filterData = getFilteredData()

  const selectOption = [
    {
      label: i18n.t('All'),
      value: 'All',
    },
    {
      label: 'Phoi',
      value: 'Phoi',
    },
    {
      label: 'GC',
      value: 'GC',
    },
  ]

  return (
    <Box className="m-4 h-[calc(100vh_-_90px)] rounded-md bg-white p-4 shadow">
      <div className="mb-5 flex  flex-wrap items-stretch justify-start gap-[0.75rem] md:justify-end">
        <div className="[&_.react-datepicker-popper]:z-10">
          <DatePicker
            date={new Date()}
            dateChange={(value) => {
              if (value) {
                const d = formatDate(value.toString(), DATE_FORMAT)
                setDate(d)
              }
            }}
          />
        </div>
        <SearchInput
          value={filter.searchString}
          onChange={(value) =>
            setFilter({
              ...filter,
              searchString: value,
            })
          }
          placeholder={`${i18n.t('input.SearchThe', {
            name:
              i18n.t('WorkOrderNumber') +
              '/' +
              i18n.t('Station') +
              '/' +
              i18n.t('Kanban_', { _: i18n.t('Code') }),
            interpolation: {
              escapeValue: false,
            },
          })}`}
          className="border-none outline-none placeholder:text-gray-500"
        />
        <div className="[&_.custom-select\_\_menu]:z-10">
          <BaseSelect
            key={selectOption[0].label}
            menuAlign="right"
            isSearchable={false}
            onChange={handleSelect}
            defaultValue={selectOption[0]}
            options={selectOption}
            placeholder="please select ..."
          />
        </div>
      </div>
      <Box>
        <BaseTable columns={DefectiveDataColumns} data={filterData} />
      </Box>
    </Box>
  )
}
