import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import { Box, Button, Icon, useMediaQuery } from '@chakra-ui/react'

import { useProductStationBase } from '@/hooks/useProductStationBase'

import { WorkStationFormSchema } from '@/helpers/validate/WorkerState'

import { WorkerStateColumns } from '@/utils/tableColumn'

import { PATHS } from '@/constants/path'

import { SearchInput } from '../atoms/interactive/SearchInput'
import { BaseTable } from '../organisms'
import { PageWrapper } from '../organisms/PageWrapper'
import { DeleteModal } from '../templates/modal/DeleteModal'
import { WorkerStateCreateModal } from '../templates/modal/WorkerStateCreateModal'
import { WorkerStateUpdateModal } from '../templates/modal/WorkerStateUpdateModal'

export const StationManagementPage = () => {
  const { t, i18n } = useTranslation()

  const [modal, setModal] = useState<ModalOpenType | ''>('')
  const [select, setSelect] = useState<StationListData>()
  const [isLargeThan1280] = useMediaQuery('(min-width: 1920px)')
  /**
   * @description all ProductStation api
   */
  const ProductStation = useProductStationBase(select)
  const productStationData = useMemo(() => {
    return ProductStation.query.data
  }, [ProductStation.query])

  const closeModal = () => {
    setModal('')
  }
  const handleSearch = (value: string) => {
    ProductStation.setFilter(value)
  }
  const handleCreateConfirm = async (data: WorkStationFormSchema) => {
    await ProductStation.create.mutateAsync({ data })
  }
  const handleUpdateConfirm = async (data: WorkStationFormSchema) => {
    if (!select) return
    await ProductStation.update.mutateAsync({ data, stationsId: select?.id })
  }
  const showModal = (data: StationListData, modal: ModalOpenType) => {
    setModal(modal)
    setSelect(data)
  }
  const handleDelete = async () => {
    if (!select) return
    await ProductStation.delete.mutateAsync(select.id)
    closeModal()
  }
  const columns = WorkerStateColumns({ showModal })

  const TABS = [
    {
      label: t('StationManagement'),
      path: PATHS.station,
    },
    {
      label: t('StationSectionManagement'),
      path: PATHS.stationSection,
    },
  ]

  return (
    <PageWrapper tabs={TABS}>
      <Box
        className="flex flex-row gap-[0.75rem]"
        p="4"
        justifyContent="flex-end"
        alignItems="center"
      >
        <SearchInput
          value={''}
          onChange={handleSearch}
          placeholder={`${t('input.SearchThe', {
            name: t('Code') + t('Or') + t('WorkerState'),
          })}`}
          className="border-none outline-none placeholder:text-gray-500"
        />
        <Button
          colorScheme="blue"
          h="38px"
          leftIcon={<Icon as={MdAdd} fontSize={20} />}
          onClick={() => setModal('create')}
        >
          {t('New', { what: '' })}
        </Button>
      </Box>
      {productStationData && (
        <Box p="4">
          <BaseTable
            columns={columns}
            data={productStationData}
            headClass="text-red-500"
            tableLayout="auto"
            pageSize={isLargeThan1280 ? 10 : 8}
          />
        </Box>
      )}

      {/* create modal */}
      <WorkerStateCreateModal
        formId="station-create"
        onClose={closeModal}
        isOpen={modal === 'create'}
        onConfirm={handleCreateConfirm}
      />
      {/* update modal */}
      {select && (
        <WorkerStateUpdateModal
          formId="station-update"
          onClose={closeModal}
          isOpen={modal === 'update'}
          onConfirm={handleUpdateConfirm}
          selectData={select}
        />
      )}
      {/* delete modal */}
      {select && (
        <DeleteModal
          name={t('WorkerState')}
          onClose={closeModal}
          isOpen={modal === 'delete'}
          type={i18n.language === 'vi' ? select.nameVi : select.nameZhTW}
          onConfirm={handleDelete}
        />
      )}
    </PageWrapper>
  )
}
