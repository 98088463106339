import { FC, InputHTMLAttributes, useRef } from 'react'
import { CloseIcon } from '@chakra-ui/icons'

import { DebounceInput } from '@/components/atoms/select/DebounceInput'

interface SearchInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange: (value: string) => void
  value: string
}

export const SearchInput: FC<SearchInputProps> = ({
  onChange,
  value,
  ...rest
}) => {
  const inputRef = useRef<InputRefImperativeHandleProps | null>(null)
  const handleClose = () => {
    inputRef.current?.clearInput()
  }
  const hasInputValue = !['', undefined, null].includes(
    inputRef.current?.inputValue as string,
  )

  return (
    <div className="border-[rgb(226, 232, 240)] flex h-[38px] items-center justify-between gap-[1rem] rounded-[0.375rem] border bg-white p-1 px-[1rem]">
      <div className="flex-1">
        <DebounceInput
          ref={inputRef}
          onChange={onChange}
          value={value}
          type="text"
          className="w-full border-none outline-none "
          {...rest}
          data-testid="search-element"
        />
      </div>
      {hasInputValue && (
        <CloseIcon
          boxSize={3}
          className="cursor-pointer"
          color={'gray.300'}
          _hover={{ color: 'gray.500' }}
          onClick={handleClose}
          data-testid="close-element"
        />
      )}
    </div>
  )
}
