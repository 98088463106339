import { useState } from 'react'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import {
  Card,
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'

import { NoDataWarning } from '@/components/atoms'
import { TablePagination } from '@/components/molecules'

import { getFlexDirectionClass } from '@/helpers/style'

export interface BaseTableProps<TData> {
  columns: ColumnDef<TData, any>[]
  data: TData[]
  tableType?: 'simple' | 'striped' | 'unstyled'
  pageSize?: number
  showPagination?: boolean
  headClass?: string
  tableLayout?: 'fixed' | 'auto'
  columnClass?: string
  needOrder?: boolean
  contextHeight?: string | number
  onRowClick?: (row) => void
}

export const BaseTable = <TData, _>({
  columns,
  data,
  tableType = 'simple',
  pageSize = 10,
  showPagination = true,
  needOrder = true,
  tableLayout = 'fixed',
  contextHeight = '70vh',
  headClass,
  columnClass,
  onRowClick,
}: BaseTableProps<TData>) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const {
    setPageSize,
    getPageOptions,
    getHeaderGroups,
    getRowModel,
    getPageCount,
    getState,
    setPageIndex,
  } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: needOrder ? getSortedRowModel() : undefined,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    sortDescFirst: false,
    state: { sorting },
    initialState: {
      pagination: {
        pageSize,
      },
    },
  })

  const hasOnRowClick = typeof onRowClick === 'function'

  return (
    <Card overflow="scroll" borderRadius={'unset'} boxShadow="none">
      <TableContainer style={{ height: contextHeight }} overflowY="scroll">
        <Table
          variant={tableType}
          style={{
            tableLayout,
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
          }}
        >
          <Thead className={headClass}>
            {getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const { column } = header
                  const textAlign = getFlexDirectionClass(
                    column.columnDef.meta?.headerTextAlign,
                  )
                  return (
                    <Th
                      key={header.id}
                      data-testid="user_table_th"
                      onClick={column.getToggleSortingHandler()}
                      isNumeric={column.columnDef.meta?.isNumeric}
                      userSelect="none"
                      cursor="pointer"
                      style={{
                        width:
                          header.getSize() !== 120 ? header.getSize() : 120,
                        whiteSpace: 'break-spaces',
                        textAlign: 'center',
                      }}
                      background="white"
                    >
                      <div className={`flex ${textAlign}`}>
                        <p className=" text-sm font-extrabold">
                          {flexRender(
                            column.columnDef.header,
                            header.getContext(),
                          )}
                        </p>
                        {needOrder && (
                          <chakra.span fontSize="1rem">
                            {column.getIsSorted() ? (
                              column.getIsSorted() === 'desc' ? (
                                <ArrowDownIcon data-testid="user_table_arrow_down" />
                              ) : (
                                <ArrowUpIcon data-testid="user_table_arrow_up" />
                              )
                            ) : null}
                          </chakra.span>
                        )}
                      </div>
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {getRowModel().rows.length ? (
              getRowModel().rows.map((row) => (
                <Tr
                  onClick={() => {
                    if (hasOnRowClick) {
                      onRowClick(row)
                    }
                  }}
                  key={row.id}
                  data-testid="user_table_body_tr"
                  className={hasOnRowClick ? 'cursor-pointer' : ''}
                >
                  {row.getVisibleCells().map((cell) => {
                    const { column } = cell
                    return (
                      <Td
                        key={cell.id}
                        isNumeric={column.columnDef.meta?.isNumeric}
                        fontSize="sm"
                        className={columnClass}
                      >
                        <div className="cursor-auto">
                          {flexRender(column.columnDef.cell, cell.getContext())}
                        </div>
                      </Td>
                    )
                  })}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={columns.length}>
                  <NoDataWarning />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      {showPagination && (
        <div className=" flex items-center justify-end pt-5">
          <TablePagination
            getPageCount={getPageCount}
            pageIndex={getState().pagination.pageIndex}
            setPageIndex={setPageIndex}
          />
        </div>
      )}
    </Card>
  )
}
