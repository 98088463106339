import { QueryFunction, useQuery } from '@tanstack/react-query'

import { API } from '@/utils/API'

import { useAPI } from './useAPI'

export const useRole = () => {
  const { request } = useAPI()
  const apiRoute = API.routes.role

  /**
   * @description   API `GET` request for get ProductStation data from the list.
   */
  const getRoles: QueryFunction<RoleData[]> = async () => {
    const { data } = await request<RoleData[], never, never>(
      'get',
      apiRoute.list,
    )

    return data
  }
  const rolesQuery = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  return {
    query: rolesQuery,
  }
}

export const useRoleList: () => Record<string, RoleType> = () => {
  const { query } = useRole()

  return (
    query.data?.reduce((acc, item) => {
      if (!acc[item.id]) {
        acc[item.id.toString()] = item.name
      }
      return acc
    }, {}) ?? {}
  )
}
