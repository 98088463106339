import React, { forwardRef, useCallback, useState } from 'react'
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker'
import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ClassNames, ClassNamesContent } from '@emotion/react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ModeIcon from '@mui/icons-material/Mode'

import { useDatePickerStyles } from '@/utils/style'

import 'react-datepicker/dist/react-datepicker.css'

interface CustomInputProps {
  toggleModal: () => void
  isTableEdit: boolean
}
const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ toggleModal, isTableEdit, ...rest }, ref) => {
    return (
      <InputGroup onClick={toggleModal}>
        {!isTableEdit ? (
          <InputLeftElement userSelect="none" pointerEvents="none">
            <Icon
              as={DateRangeIcon}
              fontSize={18}
              cursor="pointer"
              color={'gray.500'}
            />
          </InputLeftElement>
        ) : (
          <InputRightElement userSelect="none" pointerEvents="none">
            <Icon as={ModeIcon} fontSize={22} cursor="pointer" opacity={0.54} />
          </InputRightElement>
        )}

        <Input
          ref={ref}
          h="38px"
          borderWidth={isTableEdit ? '0px' : '1.5px'}
          _focusVisible={isTableEdit ? { border: 'none' } : {}}
          {...rest}
        />
      </InputGroup>
    )
  },
)

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <Stack pb={1} isInline alignItems="center" textAlign="left" pl={4} pr={2}>
      <IconButton
        borderRadius="full"
        size="sm"
        variant="ghost"
        aria-label="Previous Month"
        icon={<Icon as={ArrowBackIcon} fontSize={18} cursor="pointer" />}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      />
      <Text
        color="gray.700"
        flex={1}
        fontSize="sm"
        fontWeight="bold"
        textAlign={'center'}
      >
        {new Intl.DateTimeFormat('en-AU', {
          year: 'numeric',
          month: 'long',
        }).format(date)}
      </Text>
      <IconButton
        borderRadius="full"
        size="sm"
        variant="ghost"
        aria-label="Next Month"
        icon={<Icon as={ArrowForwardIcon} fontSize={18} cursor="pointer" />}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </Stack>
  )
}

export interface DatePickerProps {
  date: Date
  defaultIsOpen?: boolean
  showTimeInput?: boolean
  dateFormat?: string
  isTableEdit?: boolean
  dateChange: (date: Date | null) => void
}

export const DatePicker = ({
  date,
  dateChange,
  dateFormat,
  defaultIsOpen = false,
  isTableEdit = false,
  showTimeInput = false,
}: DatePickerProps) => {
  const [open, setOpen] = useState<boolean>(defaultIsOpen)
  const [value, setValue] = useState<Date>(date)

  const toggleModal = () => {
    setOpen((pre) => !pre)
  }
  const closeModal = () => {
    setOpen(false)
  }
  const handleDataChange = (date: Date) => {
    setValue(date)
    dateChange(date)
    closeModal()
  }
  // chakra - input react - datepicker - ignore - onclickoutside css - 11f20mz
  const styles = useDatePickerStyles()
  const render = useCallback(
    ({ css }: ClassNamesContent) => {
      return (
        <ReactDatePicker
          open={open}
          dateFormat={dateFormat ?? 'yyyy/MM/dd '}
          formatWeekDay={(day: string) => day.charAt(0)}
          showPopperArrow={false}
          popperClassName={css({ marginTop: '4px!important' })}
          calendarClassName={css(styles)}
          selected={value}
          onChange={handleDataChange}
          customInput={
            <CustomInput toggleModal={toggleModal} isTableEdit={isTableEdit} />
          }
          renderCustomHeader={CustomHeader}
        />
      )
    },
    [styles, value, open],
  )

  return <ClassNames>{render}</ClassNames>
}
