import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { API } from '@/utils/API'

import { workOrderKeys } from '@/constants/queryKeyFactory'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

export const useWorkOrder = (
  language: string,
  workOrderQuery?: WorkerOrderListQuery,
) => {
  const snackBar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { request } = useAPI()
  const [filterValue, setFilter] = useState<string>('')
  const apiRoute = API.routes.workOrder

  const filterworkOrderData = useCallback(
    (workOrderData: WorkerOrderData[]) => {
      const checkColumns: (keyof WorkerOrderData)[] = ['code', 'id']
      const result = workOrderData.map((item) => ({
        ...item,
      }))
      return result.filter((item) =>
        checkColumns.some((c) =>
          String(item[c]).toLowerCase().includes(filterValue.toLowerCase()),
        ),
      )
    },
    [filterValue],
  )

  const getWorkOrderData: QueryFunction<WorkerOrderData[]> = async () => {
    const { data } = await request<WorkerOrderData[], never, never>(
      'get',
      apiRoute.list(workOrderQuery),
    )
    return data
  }

  const workOrderDataQuery = useQuery({
    queryKey: workOrderKeys.list(workOrderQuery?.status ?? 'all'),
    queryFn: getWorkOrderData,
    select: filterworkOrderData,
    cacheTime: API.settings.cacheTime.workOrderData,
    initialData: [],
  })

  // post /workorder
  const createWorkOrder: MutationFunction<
    CreateWorkOrderResponse,
    { data: CreateWorkOrderRequest }
  > = async ({ data }) => {
    const { data: response } = await request<CreateWorkOrderResponse>(
      'post',
      apiRoute.create,
      { data },
    )
    return response
  }

  const createWorkOrderQuery = useMutation({
    mutationKey: workOrderKeys.create(),
    mutationFn: createWorkOrder,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.add.success', {
          type: t('Workorder'),
        }),
      })
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.add.error', {
              type: t('Workorder'),
            }) +
            ' ' +
            t(`api.errors.${error.response?.data?.error[0].message}`, ''),
        })
        return
      }
      snackBar.error({ title: t('Error') })
    },
    onSettled: () => {
      queryClient.invalidateQueries(workOrderKeys.lists())
    },
  })

  // delete /workorder/{params}
  const deleteWorkOrder: MutationFunction<
    DeleteWorkOrderResponse,
    { id: number }
  > = async ({ id }) => {
    const { data: response } = await request<DeleteWorkOrderResponse>(
      'delete',
      apiRoute.delete(id),
    )

    return response
  }

  const deleteWorkOrderQuery = useMutation({
    mutationKey: workOrderKeys.delete(),
    mutationFn: deleteWorkOrder,
    onSuccess: () => {
      snackBar.info({
        title: t('toast.delete.success', {
          type: t('Workorder'),
        }),
      })
    },
    onError: () => {
      snackBar.error({
        title: t('toast.delete.error', {
          type: t('Workorder'),
        }),
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(workOrderKeys.lists())
    },
  })

  return {
    query: workOrderDataQuery,
    create: createWorkOrderQuery,
    delete: deleteWorkOrderQuery,
    setFilter,
    filterValue,
  }
}

export const useWorkOrdersStationInfo = (
  language: string,
  { workOrderId }: WorkerOrderStationInfoQuery,
) => {
  const { request } = useAPI()
  const [filterValue, setFilter] = useState<string>('')
  const apiRoute = API.routes.workOrder

  const getWorkOrderStationInfoData: QueryFunction<
    WorkOrderStationsInfoData[],
    ReturnType<typeof workOrderKeys.stationInfo>
  > = async ({ queryKey }) => {
    const [feature, category, workOrderId] = queryKey
    if (!workOrderId) return []
    const { data } = await request<WorkOrderStationsInfoData[], never, never>(
      'get',
      apiRoute.stationInfo({ workOrderId }),
    )
    return data
  }

  const filterworkOrderData = useCallback(
    (data: WorkOrderStationsInfoData[]) => {
      const result = data.map((item) => ({
        ...item.workOrderInfo,
        childData: item.workOrderStationInfo,
      })) as WorkOrderStationsInfoQuerySelectResponseData[]
      const checkStationColumns: (keyof WorkOrderStationInfo)[] = [
        'nameVi',
        'nameZhTW',
      ]
      return result.filter(
        (item) =>
          item.code.toLowerCase().includes(filterValue.toLowerCase()) ||
          item.childData?.some((cd) =>
            checkStationColumns.some((k) =>
              cd[k]
                ?.toString()
                .toLowerCase()
                .includes(filterValue.toLowerCase()),
            ),
          ),
      )
    },
    [filterValue],
  )
  const workOrderDataQuery = useQuery({
    queryKey: workOrderKeys.stationInfo(workOrderId),
    queryFn: getWorkOrderStationInfoData,
    select: filterworkOrderData,
    cacheTime: API.settings.cacheTime.workOrderStationInfoData,
  })
  return {
    query: workOrderDataQuery,
    setFilter,
    filterValue,
  }
}
