import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UseModalProps } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'

import { Input } from '@/components/atoms'

import {
  defectiveTypeFormSchema,
  DefectiveTypeFormSchemaType,
} from '@/helpers/validate/Defective'

import { BasicModal } from './BaseModal'

export interface DefectiveTypeFormModalProps extends UseModalProps {
  formId: string
  action: ModalOpenType
  data?: DefectiveTypeFormSchemaType | null
  onConfirm: (data: DefectiveTypeFormSchemaType) => void
}

export const DefectiveTypeFormModal = ({
  isOpen,
  formId,
  action,
  data = null,
  onConfirm,
  onClose,
  ...rest
}: DefectiveTypeFormModalProps) => {
  const { t } = useTranslation()
  const { register, handleSubmit, formState, reset } =
    useForm<DefectiveTypeFormSchemaType>({
      resolver: zodResolver(defectiveTypeFormSchema),
      mode: 'onChange',
    })

  useEffect(() => {
    if (!isOpen) return
    reset(
      action === 'create'
        ? {}
        : { ...data, type: data?.type?.replace('報廢原因-', '') },
    )
  }, [isOpen])

  const onSubmit: SubmitHandler<DefectiveTypeFormSchemaType> = (data) => {
    onConfirm(data)
  }

  return (
    <BasicModal
      isOpen={isOpen}
      title={t(action === 'create' ? 'New' : 'Edit', {
        what: t('DefectiveType'),
      })}
      onClose={onClose}
      {...rest}
      formId={formId}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-3"
      >
        {action === 'update' && (
          <Input
            {...register('code')}
            isUnderline
            required={action === 'update'}
            type="text"
            label={t('defectiveCategoryCode')}
            placeholder={t('defectiveCategoryCode') + '*'}
            style={{ backgroundColor: '#f5f5f5' }}
            disabled={true}
            error={formState.errors.code}
          />
        )}
        <Input
          {...register('type')}
          isUnderline
          required
          type="text"
          label={t('defectiveCategoryName')}
          prefixComponent={
            <span style={{ color: 'gray', fontSize: '0.875rem' }}>
              報廢原因-
            </span>
          }
          placeholder={t('defectiveCategoryName') + '*'}
          disabled={formState.isSubmitting}
          error={formState.errors.type}
        />
      </form>
    </BasicModal>
  )
}
