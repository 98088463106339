import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import { TranslationKeys } from '@/_types_/i18next'

interface I18nTextProps {
  id: TranslationKeys
  className?: string

  /**
   * Domain: `rating.domain.replace(/^www\./, "")`
   */
  extraKey?: Record<string, string>
  /**
   * implement i18n fallback key
   * @link https://www.i18next.com/principles/fallback#missing-values-for-existing-keys
   * @example
   * const error = '404';
   * const fallbackKey = 'fallbackKey';
   * i18next.t([`error.${error}`, fallbackKey]); // -> "The page was not found"
   * const error = '502'
   * i18next.t([`error.${error}`, fallbackKey]); // -> "Something went wrong"
   */
  fallbackKey?: string
}

export const I18nText = ({
  id,
  className,
  extraKey = {},
  fallbackKey,
}: I18nTextProps) => {
  const { t } = useTranslation()
  const cn = twMerge(className)
  return (
    <p className={cn}>{fallbackKey ? t([id, fallbackKey]) : t(id, extraKey)}</p>
  )
}
