import { z } from 'zod'

import i18n from '@/locale/i18n'

export const castingFormSchema = z.object({
  casting_group_name: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('casting_group_name') }),
  }), // 爐號
  group_name: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('user.GroupName') }),
  }), // 組別名稱
  furnace_man: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('furnace_man') }),
  }), // 爐工
  released_man: z
    .object({
      value: z.string().min(1, {
        message: i18n.t('error.isRequired', { name: i18n.t('released_man') }),
      }),
    })
    .array(), // 叉工
  covered_man: z.string(), // 蓋鍋
})

export type CastingFormSchema = z.infer<typeof castingFormSchema>

export const castingGroupFormSchema = z.object({
  group_name: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('user.GroupName') }),
  }), // 組別名稱
  furnace_man: z.string().min(1, {
    message: i18n.t('error.isRequired', { name: i18n.t('furnace_man') }),
  }), // 爐工
  released_man: z
    .object({
      value: z.string().min(1, {
        message: i18n.t('error.isRequired', { name: i18n.t('released_man') }),
      }),
    })
    .array(), // 叉工
  covered_man: z.string().nullable().optional(), // 蓋鍋
  remark: z.string().optional(), //備註
})

export type CastingGroupFormSchema = z.infer<typeof castingGroupFormSchema>
