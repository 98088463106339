import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Select, UseModalProps } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'

import { Input } from '@/components/atoms'

import { useDefective } from '@/hooks'

import {
  defectiveFormSchema,
  DefectiveFormSchemaType,
} from '@/helpers/validate/Defective'

import { BasicModal } from './BaseModal'

export interface DefectiveFormModalProps extends UseModalProps {
  formId: string
  action: ModalOpenType
  data?: GetDefectReasonResponse | null
  onConfirm: (data: DefectiveFormSchemaType) => void
}

export const DefectiveFormModal = ({
  isOpen,
  formId,
  action,
  data = null,
  onConfirm,
  onClose,
  ...rest
}: DefectiveFormModalProps) => {
  const isEdit = action === 'update'
  const { t } = useTranslation()
  const { typesOptions } = useDefective()
  const { register, handleSubmit, formState, reset } =
    useForm<DefectiveFormSchemaType>({
      resolver: zodResolver(defectiveFormSchema),
      mode: 'onChange',
    })

  useEffect(() => {
    if (!isOpen) return
    reset(
      action === 'create'
        ? {}
        : { ...data, type: `${data?.typeCode}-${data?.type}` },
    )
  }, [isOpen])

  const onSubmit: SubmitHandler<DefectiveFormSchemaType> = (data) => {
    onConfirm(data)
  }

  return (
    <BasicModal
      isOpen={isOpen}
      title={t(action === 'create' ? 'New' : 'Edit', {
        what: t('DefectiveReason'),
      })}
      onClose={onClose}
      {...rest}
      formId={formId}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-3"
      >
        {isEdit ? (
          <Input
            {...register('type')}
            isUnderline
            type="text"
            label={t('defectiveCategory')}
            placeholder={t('defectiveCategory') + '*'}
            style={{ backgroundColor: '#f5f5f5' }}
            disabled={true}
          />
        ) : (
          <Select
            {...register('code')}
            border="none"
            borderBottom="1px"
            borderColor={formState.errors.code ? 'red.500' : 'gray.200'}
            placeholder={`${t('defectiveCategory')}*`}
            disabled={formState.isSubmitting}
            required={true}
            rounded="none"
            mb="3"
            fontSize="0.875rem"
            style={{ paddingInlineStart: 0 }}
          >
            {typesOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
        {isEdit && (
          <Input
            {...register('reasonCode')}
            isUnderline
            type="text"
            label={t('defectiveCode')}
            placeholder={t('defectiveCode') + '*'}
            style={{ backgroundColor: '#f5f5f5' }}
            disabled={true}
            error={formState.errors.reasonCode}
          />
        )}
        <Input
          {...register('zhTW')}
          isUnderline
          required
          type="text"
          label={t('defectiveNameZhTw')}
          placeholder={t('defectiveNameZhTw') + '*'}
          disabled={formState.isSubmitting}
          error={formState.errors.zhTW}
        />
        <Input
          {...register('vi')}
          isUnderline
          required
          type="text"
          label={t('defectiveNameVi')}
          placeholder={t('defectiveNameVi', { lng: 'vi' }) + '*'}
          disabled={formState.isSubmitting}
          error={formState.errors.vi}
        />
      </form>
    </BasicModal>
  )
}
