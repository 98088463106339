import { useState } from 'react'
import { MdAutoMode, MdDelete, MdModeEdit } from 'react-icons/md'
import { Icon } from '@chakra-ui/react'

import { I18nText } from '@/components/atoms/textual/I18nText'

import { ProductionTableColumnHelpers } from '@/helpers/tableColumn'

import i18n from '@/locale/i18n'

import { ProductionDataTableColumns } from '../UserData'

interface ProductionDataColumnsProps {
  handleAction: (type: ProductPageModalType, data?: ProductionData) => void
}

const ImageCell = ({ getValue }) => {
  const src = getValue()
  const [isError, setIsError] = useState(false)
  return (
    <div className="relative h-[9.375rem] w-[11.875rem] cursor-pointer overflow-hidden">
      <img
        className="absolute left-0 top-0 block h-full w-full object-cover object-center"
        src={isError ? '/frame.png' : src || '/frame.png'}
        alt=""
        onError={() => setIsError(true)}
      />
    </div>
  )
}

export const useProductionDataColumns = ({
  handleAction,
}: ProductionDataColumnsProps) =>
  [
    ProductionTableColumnHelpers.accessor('image', {
      header: () => <I18nText id={'Photo'} />,
      size: 200,
      cell: ImageCell,
    }),
    ProductionTableColumnHelpers.accessor('code', {
      header: () => <I18nText id={'Product_'} extraKey={{ _: i18n.t('ID') }} />,
    }),
    ProductionTableColumnHelpers.accessor('name', {
      header: () => (
        <I18nText id={'Product_'} extraKey={{ _: i18n.t('Name') }} />
      ),
    }),
    ProductionTableColumnHelpers.accessor('defaultBindQuantity', {
      header: () => <I18nText id={'product.Assemble'} />,
    }),
    ProductionTableColumnHelpers.accessor('customerName', {
      header: () => (
        <I18nText id={'Customer_'} extraKey={{ _: i18n.t('ID') }} />
      ),
    }),
    ProductionTableColumnHelpers.accessor('version', {
      header: () => <I18nText id={'product.CP_version'} />,
    }),
    ProductionTableColumnHelpers.display({
      id: 'product_edit',
      header: () => <I18nText id={'Edit'} extraKey={{ what: '' }} />,
      meta: {
        headerTextAlign: 'right',
      },
      size: 104,
      cell: (info) => {
        return (
          <div className="flex justify-end">
            <Icon
              as={MdAutoMode}
              fontSize={22}
              cursor="pointer"
              color={'gray.500'}
              onClick={(e) => {
                e.stopPropagation()
                handleAction('autoStatus', info.row.original)
              }}
            />
            <Icon
              as={MdModeEdit}
              fontSize={22}
              cursor="pointer"
              color={'gray.500'}
              onClick={(e) => {
                e.stopPropagation()
                handleAction('edit', info.row.original)
              }}
            />
            <Icon
              as={MdDelete}
              fontSize={22}
              color={'red.600'}
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation()
                handleAction('delete', info.row.original)
              }}
            />
          </div>
        )
      },
    }),
  ] as ProductionDataTableColumns
