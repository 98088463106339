import { MdDelete, MdModeEdit } from 'react-icons/md'
import { Icon } from '@chakra-ui/react'

import { I18nText } from '@/components/atoms/textual/I18nText'

import { WorkerstateTableColumnHelper } from '@/helpers/user'

import i18n from '@/locale/i18n'

import { WorkerstateDataTableColumns } from '../UserData'

export const WorkerStateColumns: ({
  showModal,
}: {
  showModal: (data: StationListData, modal: ModalOpenType) => void
}) => WorkerstateDataTableColumns = ({ showModal }) => [
  WorkerstateTableColumnHelper.accessor('code', {
    header: () => (
      <I18nText id={'_Code'} extraKey={{ _: i18n.t('WorkerState') }} />
    ),
  }),
  WorkerstateTableColumnHelper.accessor(
    i18n.language === 'vi' ? 'nameVi' : 'nameZhTW',
    {
      header: () => <I18nText id={'WorkerState'} />,
    },
  ),
  WorkerstateTableColumnHelper.display({
    id: 'edit',
    header: () => <I18nText id={'Edit'} extraKey={{ what: '' }} />,
    meta: {
      getCellContext: () => {},
      headerTextAlign: 'right',
    },
    cell: (info) => (
      <div className="flex items-center justify-end gap-[1rem]">
        <Icon
          as={MdModeEdit}
          fontSize={22}
          color={'gray.500'}
          cursor="pointer"
          onClick={() => {
            showModal(info.row.original, 'update')
          }}
        />
        <Icon
          as={MdDelete}
          fontSize={22}
          cursor="pointer"
          color={'red.600'}
          onClick={() => {
            showModal(info.row.original, 'delete')
          }}
        />
      </div>
    ),
  }),
]
