import { workerStatus, WorkerStatusInfo } from '@/constants/workOrder'

export const getWorkOrderStatusInfo: (
  status: WorkerOrderStatus,
) => WorkerStatusInfo | undefined = (status) => {
  switch (status) {
    case 'init':
      return workerStatus.init
    case 'checkIn':
    case 'checkOut':
      return workerStatus.running
  }
}
