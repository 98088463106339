import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import { Box, Button, Icon } from '@chakra-ui/react'
import DeleteIcon from '@mui/icons-material/Delete'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined'
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined'
import ReplyAllOutlinedIcon from '@mui/icons-material/ReplyAllOutlined'

import { DeleteModal } from '@/components/templates/modal/DeleteModal'
import { WorkorderCreateModal } from '@/components/templates/modal/WorkorderCreateModal'

import {
  useMediaQuery,
  useSFTWorkOrder,
  useSnackbar,
  useWorkOrder,
  useWorkOrdersStationInfo,
} from '@/hooks'

import { WorkOrderCreateSchemaType } from '@/helpers/validate/WorkOrder'

import {
  useWorkerOrderStatusTableColumns,
  useWorkerOrderTableColumns,
} from '@/utils/tableColumn'

import { workerStatusList } from '@/constants/workOrder'

import { TranslationKeys } from '@/_types_/i18next'

import { SearchInput } from '../atoms/interactive/SearchInput'
import { BaseSelect } from '../atoms/select/BaseSelect'
import { CollapsibleTable } from '../organisms'

export const WorkerOrderRoutePage = () => {
  const { t, i18n } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<WorkerOrderStatus>('')
  const [isOpen, setIsOpen] = useState<ModelOpenType>('')
  const [selectedWorkOrder, setSelectedWorkOrder] =
    useState<WorkOrderStationsInfoQuerySelectResponseData>()
  const [currentWorkOrderId, setCurrentWorkOrderId] = useState<number | null>(
    null,
  )
  const workOrderResponse = useWorkOrder(
    i18n.language,
    selectedOption ? { status: selectedOption } : {},
  )
  const SFTWorkOrderResponse = useSFTWorkOrder()
  const matchesMedia = useMediaQuery('(max-width: 1600px)')

  const selectOption = Object.entries(workerStatusList).map(([key, value]) => ({
    label: t(`workOrder.statue.${value.i18key}` as TranslationKeys),
    value: key,
  }))

  selectOption.unshift({
    label: t('All' as TranslationKeys),
    value: 'All',
  })

  const { query: workOrderQuery } = useWorkOrdersStationInfo(i18n.language, {
    workOrderId: currentWorkOrderId,
  })

  const handleAction = (
    type: ModelOpenType,
    data?: WorkOrderStationsInfoQuerySelectResponseData,
  ) => {
    if (data) {
      setSelectedWorkOrder(data)
    }
    setIsOpen(type)
  }

  const WorkOrderColumns = useWorkerOrderTableColumns({ handleAction })

  const closeModal = () => {
    setIsOpen('')
  }

  const handleSearchOrder = (value: string) => {
    workOrderResponse.setFilter(value)
  }

  const handleSelectOption = (option: unknown) => {
    const { value } = option as Option

    if (value === 'All') {
      setSelectedOption('')
    } else {
      setSelectedOption(value as WorkerOrderStatus)
    }
  }

  const handleSubmit = async (formData: WorkOrderCreateSchemaType) => {
    const workOrderData = await SFTWorkOrderResponse.query.mutateAsync({
      workOrderCode: formData.code,
    })
    if (!workOrderData) return

    const res = await workOrderResponse.create.mutateAsync({
      data: workOrderData,
    })

    if (!!res) {
      closeModal()
      workOrderResponse.query.refetch()
    }
  }

  const handleDeleteWorkOrder = async () => {
    if (!selectedWorkOrder) return
    await workOrderResponse.delete.mutateAsync({ id: selectedWorkOrder?.id })
    closeModal()
  }

  const onExpand = (
    row: WorkOrderStationsInfoQuerySelectResponseData | null,
  ) => {
    setCurrentWorkOrderId(!!row ? row.id : null)
  }

  const data = useMemo(() => {
    const currentIndex = workOrderResponse.query.data?.findIndex(
      (workOrder) => workOrder.id === currentWorkOrderId,
    )

    if (currentIndex !== -1) {
      const newData = workOrderResponse.query.data[
        currentIndex
      ] as WorkOrderStationsInfoQuerySelectResponseData
      newData.childData = workOrderQuery.data?.[0]?.childData || []
    }

    return workOrderResponse.query.data.filter(
      (workOrder) => workOrder.status !== 'stock',
    )
  }, [workOrderResponse.query.data, workOrderQuery.data])

  const childColumns = useWorkerOrderStatusTableColumns(i18n.language)

  return (
    <Box className="m-4 h-[calc(100vh_-_90px)] rounded-[0.375rem] border bg-white p-4 @container">
      <div className="mb-5 flex flex-wrap items-stretch justify-start gap-[0.5rem] md:justify-end md:gap-[1.5rem]">
        <SearchInput
          value={''}
          onChange={handleSearchOrder}
          placeholder={`${t('input.SearchThe', {
            name: t('WorkorderOrWorkerState'),
          })}`}
          className="border-none outline-none"
        />
        <BaseSelect
          key={selectOption[0].label}
          menuAlign="right"
          isSearchable={false}
          onChange={handleSelectOption}
          defaultValue={selectOption[0]}
          options={selectOption}
          placeholder="please select ..."
        />
        <Button
          colorScheme="blue"
          variant="outline"
          h="38px"
          style={{ borderRadius: 4 }}
          rightIcon={<Icon as={MdAdd} fontSize={20} />}
          onClick={() => handleAction('add')}
        >
          {t('New', { what: '' })}
        </Button>
      </div>
      {!!data.length && (
        <CollapsibleTable
          columns={WorkOrderColumns}
          childColumns={childColumns}
          data={data}
          columnIdThatDoNotCollapse={['storage', 'disable', 'pause']}
          columnChildClass="bg-gray-100 border-b-2 border-gray-300"
          headChildClass="bg-gray-200"
          contextHeight={matchesMedia ? '68vh' : '70vh'}
          onExpand={onExpand}
        />
      )}
      <WorkorderCreateModal
        isOpen={['add', 'edit'].includes(isOpen)}
        formId={isOpen + 'workorder'}
        onConfirm={handleSubmit}
        onClose={closeModal}
      />
      {!!selectedWorkOrder && (
        <DeleteModal
          isOpen={isOpen === 'delete'}
          name={selectedWorkOrder.code}
          type={t('Workorder')}
          onClose={closeModal}
          onConfirm={handleDeleteWorkOrder}
        />
      )}
    </Box>
  )
}
interface PlayBTNProps {
  pause: number
}

export const PlayBTN = ({ pause }: PlayBTNProps) => {
  const [isPause, setPause] = useState<boolean>(pause ? false : true)
  const snackBar = useSnackbar()
  const { t } = useTranslation()
  return (
    <div
      className="flex items-center"
      onClick={() => {
        setPause((pre) => !pre)
      }}
    >
      {isPause ? (
        <Icon
          as={PlayCircleFilledOutlinedIcon}
          boxSize={6}
          color={'green.500'}
          onClick={() =>
            snackBar.info({
              title: t('toast.workOrder.success', {
                type: t('workOrder.kanbans.Resume'),
              }),
            })
          }
        />
      ) : (
        <Icon
          as={PauseCircleFilledOutlinedIcon}
          boxSize={6}
          color={'gray.500'}
          onClick={() =>
            snackBar.info({
              title: t('toast.workOrder.success', {
                type: t('workOrder.kanbans.Pause'),
              }),
            })
          }
        />
      )}
    </div>
  )
}

interface LockBTNProps {
  enable: number
}
export const LockBTN = ({ enable }: LockBTNProps) => {
  const [lock, setLock] = useState<boolean>(enable ? false : true)
  const snackBar = useSnackbar()
  const { t } = useTranslation()
  return (
    <div
      className="flex items-center justify-center"
      onClick={() => {
        setLock((pre) => !pre)
      }}
    >
      {lock ? (
        <Icon
          as={LockOutlinedIcon}
          boxSize={6}
          color={'red.500'}
          onClick={() =>
            snackBar.info({
              title: t('toast.workOrder.success', {
                type: t('workOrder.kanbans.Reuse'),
              }),
            })
          }
        />
      ) : (
        <Icon
          as={LockOpenOutlinedIcon}
          boxSize={6}
          color={'gray.500'}
          onClick={() =>
            snackBar.info({
              title: t('toast.workOrder.success', {
                type: t('workOrder.kanbans.Disable'),
              }),
            })
          }
        />
      )}
    </div>
  )
}

interface StorageBTNProps {
  stock: number
}
export const StorageBTN = ({ stock }: StorageBTNProps) => {
  const snackBar = useSnackbar()
  const { t } = useTranslation()
  return (
    <div
      className="flex items-center"
      onClick={
        stock
          ? () => {
              snackBar.info({
                title: t('toast.workOrder.success', {
                  type: t('workOrder.kanbans.Storage'),
                }),
              })
            }
          : () => {
              snackBar.error({
                title: t('toast.workOrder.error', {
                  type: t('workOrder.kanbans.Storage'),
                }),
              })
            }
      }
    >
      <Icon
        as={ReplyAllOutlinedIcon}
        boxSize={6}
        color={stock ? 'gray.500' : 'gray.300'}
      />
    </div>
  )
}

interface ActionBTNProps {
  onClick: (type: ModelOpenType) => void
}

export const ActionBTN = ({ onClick }: ActionBTNProps) => {
  return (
    <div className="flex items-center">
      {/* <Icon
        as={EditIcon}
        boxSize={6}
        color="gray.500"
        onClick={(e) => {
          e.stopPropagation()
          onClick('edit')
        }}
      /> */}
      <Icon
        as={DeleteIcon}
        boxSize={6}
        ml="2"
        color="red.600"
        onClick={(e) => {
          e.stopPropagation()
          onClick('delete')
        }}
      />
    </div>
  )
}
